import { useCurrentBreakpoint } from '../../contexts/BreakpointProvider';

export const useEmpTableUtils = () => {
  const { isMobile, isDesktop } = useCurrentBreakpoint();

  const getWidth = (column) => {
    if (typeof column.width === 'string') {
      return column.width;
    }

    if (typeof column.width === 'object') {
      const { mobile, desktop } = column.width;

      if (isMobile() && mobile) {
        return mobile;
      }

      if (isDesktop() && desktop) {
        return desktop;
      }
    }

    return undefined;
  };

  const getHeader = (column) => {
    if (typeof column.header === 'string') {
      return column.header;
    }

    if (typeof column.width === 'object') {
      const { mobile, desktop } = column.header;

      if (isMobile() && mobile) {
        return mobile;
      }

      if (isDesktop() && desktop) {
        return desktop;
      }
    }

    return '';
  };

  return {
    getHeader,
    getWidth
  }
};
