import { useTranslation } from 'react-i18next';
import { FilterForm, FilterItem } from '../../../shared/components';

const defaultValues = {
  id: {
    operator: 'equals',
    value: ''
  },
  name: {
    operator: 'contains',
    value: ''
  },
  sku: {
    operator: 'contains',
    value: ''
  },
  ean: {
    operator: 'contains',
    value: ''
  },
  manufacturer: {
    operator: 'contains',
    value: ''
  },
  productCategory: {
    operator: 'contains',
    value: ''
  },
  productUnit: {
    operator: 'contains',
    value: ''
  }
};

export const ProductFilterForm = ({ filters, onFilterChange }) => {
  const { t } = useTranslation('stockManager');

  filters = {
    ...defaultValues,
    ...filters
  };

  const handleOperatorChange = ({ target: { name, value } }) => {
    onFilterChange(name, { ...filters[name], operator: value });
  };

  const handleValueChange = ({ target: { name, value } }) => {
    onFilterChange(name, { ...filters[name], value: value });
  };

  return (
    <FilterForm>
      {/* ID */}
      <FilterItem
        type="number"
        name="id"
        label={t('product.list.filter.fields.id')}
        filter={filters.id}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 0] }}
      />

      {/* Name */}
      <FilterItem
        type="string"
        name="name"
        label={t('product.list.filter.fields.name')}
        filter={filters.name}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 1] }}
      />

      {/* SKU */}
      <FilterItem
        type="string"
        name="sku"
        label={t('product.list.filter.fields.sku')}
        filter={filters.sku}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 0] }}
      />

      {/* EAN */}
      <FilterItem
        type="string"
        name="ean"
        label={t('product.list.filter.fields.ean')}
        filter={filters.ean}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 1] }}
      />

      {/* Manufacturer */}
      <FilterItem
        type="string"
        name="manufacturer"
        label={t('product.list.filter.fields.manufacturer')}
        filter={filters.manufacturer}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 0] }}
      />

      {/* Product category */}
      <FilterItem
        type="string"
        name="productCategory"
        label={t('product.list.filter.fields.product-category')}
        filter={filters.productCategory}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 1] }}
      />

      {/* Product unit */}
      <FilterItem
        type="string"
        name="productUnit"
        label={t('product.list.filter.fields.product-unit')}
        filter={filters.productUnit}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 0] }}
      />
    </FilterForm>
  );
};
