import { useEffect } from 'react';
import { useParams } from 'react-router';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useEmpDropdownAdapter } from '../../../shared/components';
import { useApiNotification } from '../../../shared/hooks/useApiNotification';
import { ShopService } from './ShopService';

const schema = yup.object().shape({
  name:
    yup.string().required().trim().max(64),

  address:
    yup.string().nullable().max(200).emptyStringToNull(),

  canRedeemPoints:
    yup.boolean().required(),

  currencyId:
    yup.number().integer().required(),

  warehouseId:
    yup.number().integer().required()
});

export const useShopEditor = () => {
  const { shopId } = useParams();

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const {
    showCreateError,
    showCreateSuccess,
    showUpdateError,
    showUpdateSuccess
  } = useApiNotification({
    successNavigationUrl: '/stock-manager/shop'
  });

  const [currenciesLoading, currencyOptions] = useEmpDropdownAdapter({
    url: '/stock-manager/currencies'
  });

  const [warehouseLoading, warehouseOptions] = useEmpDropdownAdapter({
    url: '/stock-manager/warehouses'
  });

  const { handleSubmit, setValue, reset } = form;

  useEffect(() => {
    if (shopId) {
      ShopService.getShopById(shopId)
        .then(shop => {
          reset({
            ...shop,
            currencyId: shop.currency?.id,
            warehouseId: shop.warehouse?.id
          });
        });
    }
  }, [shopId, setValue, reset]);

  const handleSubmitInternal = async (data) => {
    if (shopId) {
      await updateShop(data);
    }
    else {
      await createShop(data);
    }
  };

  const createShop = async (shop) => {
    return ShopService.createShop(shop)
      .then(() => {
        showCreateSuccess();
      })
      .catch(error => {
        showCreateError(error);
      });
  };

  const updateShop = async (shop) => {
    return ShopService.updateShop(shop)
      .then(() => {
        showUpdateSuccess();
      })
      .catch(error => {
        showUpdateError(error);
      });
  };

  const currencyDropdownProps = {
    loading: currenciesLoading,
    options: currencyOptions
  };

  const warehouseDropdownProps = {
    loading: warehouseLoading,
    options: warehouseOptions
  };

  return {
    shopId,
    form,
    currencyDropdownProps,
    warehouseDropdownProps,
    handleSubmit: handleSubmit(handleSubmitInternal)
  };
};
