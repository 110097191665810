import { useTranslation } from 'react-i18next';
import { Editor, EmpTextField, Form, FormItem } from '../../../shared/components';
import { useWarehouseEditor } from './useWarehouseEditor';

export const WarehouseEditor = () => {
  const { t } = useTranslation('stockManager');

  const {
    warehouseId,
    form,
    handleSubmit
  } = useWarehouseEditor();

  return (
    <Editor
      width="sm"
      headerProps={{
        title: t('warehouse.editor.title'),
        backLinkTo: '/stock-manager/warehouses'
      }}
      submitProps={{
        loading: form.formState.isSubmitting,
        onSubmit: handleSubmit,
        children: t(`warehouse.actions.${warehouseId ? 'update' : 'create'}.label`)
      }}
    >
      <Form onSubmit={handleSubmit} columns={16} spacing={2}>
        <FormItem xs={[1, 14, 1]} sm={[2, 8, 6]}>
          <EmpTextField
            controllerProps={{
              name: 'name',
              control: form.control
            }}
            textFieldProps={{
              label: t('warehouse.editor.fields.name')
            }}
          />
        </FormItem>


        <FormItem xs={[1, 14, 1]} sm={[2, 12, 2]}>
          <EmpTextField
            controllerProps={{
              name: 'address',
              control: form.control
            }}
            textFieldProps={{
              label: t('warehouse.editor.fields.address')
            }}
          />
        </FormItem>
      </Form>
    </Editor>
  );
};
