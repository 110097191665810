import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { EmployeeService } from './EmployeeService';

export const useEmployeeViewer = () => {
  const { employeeId } = useParams();
  const [employee, setEmployee] = useState(null);

  useEffect(() => {
    EmployeeService.getEmployeeById(employeeId)
      .then(employee => {
        setEmployee(employee);
      });
  }, [employeeId]);

  return {
    employee
  };
};
