import { TableView } from '../../../shared/components';
import { EmpTableV2 } from '../../../shared/components/table/EmpTableV2';
import { ChargingDetailModal } from './ChargingDetailModal';
import { useGuestChargings } from './useGuestChargings';

export const GuestChargings = () => {
  const { tableProps, detailModal, handleClose } = useGuestChargings();

  return (
    <>
      <TableView width="md">
        <EmpTableV2 {...tableProps} />
      </TableView>

      <ChargingDetailModal
        {...detailModal }
        onClose={handleClose}
      />
    </>
  );
};
