import { parseAccessToken } from "../hooks/useCurrentUser";

const forbiddenPath = [
  '/login',
  '/signin-callback',
  '/signout-callback'
];

const saveRedirectUrl = () => {
  const pathname = window.location.pathname;

  if (!forbiddenPath.includes(pathname)) {
    const redirectUrl = { pathname };

    if (window.location.search) {
      redirectUrl['search'] = window.location.search;
    }

    localStorage.setItem('redirectUrl', JSON.stringify(redirectUrl));
  }
};

const loadRedirectUrl = (user) => {
  const token = parseAccessToken(user.access_token);
  const roles = Array.isArray(token.role) ? token.role : [token.role];

  if (roles.includes('emp-admin')) {
    return '/dashboard';
  }

  if (roles.includes('stm-admin') || roles.includes('stm-shop-manager') || roles.includes('stm-shopkeeper')) {
    return '/stock-manager/sale';
  }

  return '/dashboard';

  // let redirectUrlObject = localStorage.getItem('redirectUrl');

  // if (redirectUrlObject) {
  //   let redirectUrl = JSON.parse(redirectUrlObject);

  //   if (redirectUrl.search) {
  //     return `${redirectUrl.pathname}${redirectUrl.search}`;
  //   }

  //   return redirectUrl.pathname;
  // }

  // return '/dashboard';
};

export const RedirectHelper = {
  saveRedirectUrl,
  loadRedirectUrl
};
