import { EmpLoadingButton } from '../../../shared/components';

const styles = {
  button: {
    borderRadius: '20px'
  }
};

export const EditorSubmit = ({ loading, onSubmit, children }) => {
  return (
    <EmpLoadingButton
      type="submit"
      sx={styles.button}
      loading={loading}
      onClick={onSubmit}
    >
      {children}
    </EmpLoadingButton>
  );
};
