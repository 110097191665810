import { useTranslation } from 'react-i18next';
import { FilterItem, FilterForm } from '../../../shared/components/';

const defaultValues = {
  id: {
    operator: 'equals',
    value: ''
  },
  name: {
    operator: 'contains',
    value: ''
  },
  shop: {
    operator: 'contains',
    value: ''
  },
  discountPercentage: {
    operator: 'equals',
    value: ''
  },
  collectedPoints: {
    operator: 'equals',
    value: ''
  }
};

export const CustomerFilterForm = ({ filters, onFilterChange }) => {
  const { t } = useTranslation('stockManager');

  filters = {
    ...defaultValues,
    ...filters
  };

  const handleOperatorChange = ({ target: { name, value } }) => {
    onFilterChange(name, { ...filters[name], operator: value });
  };

  const handleValueChange = ({ target: { name, value } }) => {
    onFilterChange(name, { ...filters[name], value: value });
  };

  return (
    <FilterForm>
      <FilterItem
        type="number"
        name="id"
        label={t('customer.list.filter.fields.id')}
        filter={filters.id}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 0] }}
      />

      <FilterItem
        type="string"
        name="name"
        label={t('customer.list.filter.fields.name')}
        filter={filters.name}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 1] }}
      />

      <FilterItem
        type="string"
        nullable
        name="shop"
        label={t('customer.list.filter.fields.shop')}
        filter={filters.shop}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 0] }}
      />

      <FilterItem
        type="number"
        name="discountPercentage"
        label={t('customer.list.filter.fields.discount')}
        filter={filters.discountPercentage}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 1] }}
      />

      <FilterItem
        type="number"
        name="collectedPoints"
        label={t('customer.list.filter.fields.points')}
        filter={filters.collectedPoints}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 0] }}
      />
    </FilterForm>
  );
};
