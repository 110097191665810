import { InputAdornment } from '@mui/material';
import { EmpNumberField } from '../../../../../shared/components';

export const PaymentInput = (props) => {
  const { startLabel, currency, value, onChange } = props;

  const handleChange = ({ target }) => {
    let value = null;

    if (target.value) {
      value = parseFloat(target.value);
    }

    onChange(value);
  };

  return (
    <EmpNumberField
      numberFieldProps={{
        value: value || '',
        onChange: handleChange,
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              {startLabel}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {currency}
            </InputAdornment>
          )
        }
      }}
    />
  );
};
