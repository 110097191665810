import { useStockManagerMenu } from './Menu';
import { StockManagerRoutes } from './Routes';

const StockManagerModule = {
  name: 'stock-manager',
  abbreviation: 'stm',
  menu: useStockManagerMenu,
  baseRoute: '/stock-manager',
  routes: StockManagerRoutes
};

export default StockManagerModule;
