const parseFilters = (rawFilters) => {
  if (!rawFilters) {
    return null;
  }

  const parsedFilters = {};
  const filters = decodeURIComponent(rawFilters).split(';');

  for (const filter of filters) {
    if (filter) {
      const parts = filter.split('|');

      parsedFilters[parts[0]] = {
        operator: parts[1],
        value: parts[2]
      };
    }
  }

  return parsedFilters;
};

const buildFilter = (route, filters) => {
  if (!filters) return route;
  return `&filters=${buildFilterV2(filters)}`;
};

const buildFilterV2 = (filters) => {
  if (!filters || !Object.keys(filters).length) return null;

  let stringified = '';
  let queryFilters = [];

  for (const key of Object.keys(filters)) {
    const operator = filters[key].operator;
    const value = filters[key].value;

    if (operator === 'isEmpty' || operator === 'isNotEmpty') {
      queryFilters.push(`${key}|${operator}`);
    }
    else if (filters[key].value) {
      queryFilters.push(`${key}|${operator}|${value}`);
    }
  }

  if (queryFilters.length > 0) {
    for (const queryFilter of queryFilters) {
      stringified += encodeURIComponent(`${queryFilter};`);
    }
  }

  return stringified;
};

// Deprecated
const buildFilters = (filters) => {
  let queryFilters = [];

  for (const key of Object.keys(filters)) {
    const operator = filters[key].operator;
    const value = filters[key].value;

    if (operator === 'isEmpty' || operator === 'isNotEmpty') {
      queryFilters.push(`${key}|${operator}`);
    }
    else if (value !== null && value !== undefined && value !== '') {
      queryFilters.push(`${key}|${operator}|${value}`);
    }
  }

  let serialized = '';

  if (queryFilters.length > 0) {
    for (const queryFilter of queryFilters) {
      serialized += `${queryFilter};`;
    }

    return serialized;
  }

  return null;
};

const mergeFilters = (filters, defaultValues) => {
  if (!filters) {
    return defaultValues;
  }

  for (const [key, value] of Object.entries(defaultValues)) {
    if (!(key in filters)) {
      filters[key] = {
        operator: value.operator,
        value: value.value
      };
    }
  }

  return filters;
};

const sameFilters = (prev, next) => {
  const prevInitialFilters = prev.initialFilters || {};
  const nextInitialFilters = next.initialFilters || {};

  for (const [key, value] of Object.entries(prevInitialFilters)) {
    if (nextInitialFilters[key] !== value) {
      return false;
    }
  }

  return true;
};

export const filterUtils = {
  parseFilters,
  buildFilters,
  mergeFilters,
  sameFilters,

  buildFilter,
  buildFilterV2
};

export const useFilterUtils = () => {
  return { ...filterUtils };
};
