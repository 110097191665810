import { useTranslation } from 'react-i18next';
import { Group as GroupIcon } from '@mui/icons-material';
import { useModules } from '../../shared/hooks/useModules';
import { useCurrentUser } from '../../shared/hooks/useCurrentUser';

export const useWebsiteUserMenu = () => {
  const { t } = useTranslation('websiteUser');

  const { hasAnyRole } = useCurrentUser();
  const { currentModule } = useModules();

  return {
    label: t('menu.base'),
    icon: <GroupIcon />,
    active: currentModule === 'website-user',
    visible: hasAnyRole('emp-admin', 'acm-admin', 'stm-admin'),
    to: '/website-user',
    children: []
  }
};
