export const IDENTITY_CONFIG = {
  // (string): The URL of the OIDC provider.
  authority: process.env.REACT_APP_SSO_URL,

  // (string): Your client application's identifier as registered with the OIDC provider.
  client_id: process.env.REACT_APP_CLIENT_ID,

  // (string, default: 'openid'): The scope being requested from the OIDC provider.
  scope: 'openid profile emp-webapi offline_access',

  // (string, default: 'id_token'): The type of response desired from the OIDC provider.
  response_type: 'code',

  // The URI of your client application to receive a response from the OIDC provider.
  redirect_uri: `${process.env.REACT_APP_BASE_URL}/signin-callback`,

  //(string): The URL for the page containing the code handling the silent renew.
  silent_redirect_uri: `${process.env.REACT_APP_BASE_URL}/signin-callback`,

  // (string): The OIDC post-logout redirect URI.
  post_logout_redirect_uri: `${process.env.REACT_APP_BASE_URL}/signout-callback`
};
