import { TableView } from '../../../shared/components';
import { EmpTableV2 } from '../../../shared/components/table/EmpTableV2';
import { useStockChangesList } from './useStockChangesList';

export const StockChangesList = () => {
  const { tableProps } = useStockChangesList();

  return (
    <TableView width="xl">
      <EmpTableV2 {...tableProps} />
    </TableView>
  );
};
