export const priceFormatter = (value) => {
  if (!value || isNaN(value)) {
    return 0;
  }

  let newValue = isNumeric(value) ?
    parseFloat(value).toFixed(2) :
    value.toString();

  if (newValue.endsWith('.00')) {
    newValue = parseInt(value).toString();
  }

  let fullPart = newValue;
  let remainPart = '';

  const contains = newValue.indexOf('.')

  if (contains > -1) {
    fullPart = newValue.substring(0, contains);
    remainPart = newValue.substring(contains);
  }

  return `${formatter(fullPart)}${remainPart}`;
};

const formatter = (input) => {
  let counter = 0;
  let newInput = '';

  for (let i = input.length - 1; i >= 0; i--) {
    if (counter === 3) {
      counter = 0;
      newInput += ' ';
    }
    
    newInput += input[i]; 
    counter++;
  }

  let newNewInput = '';
  
  for (let i = newInput.length - 1; i >= 0; i--) {
    newNewInput += newInput[i]; 
  }

  return newNewInput;
};

const isNumeric = (value) => {
  return !isNaN(value - parseFloat(value));
};
