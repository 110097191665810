import { ServiceBase } from '../../../shared';

const BASE_URL = '/stock-manager/currencies';

export const CurrencyService = {
  async getCurrenciesPaginated(parameters) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}?${ServiceBase.makeQueryString(parameters)}`))
      .json();
  },

  async getCurrencyById(currencyId) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/${currencyId}`))
      .json();
  },

  async createCurrency(currency) {
    return await (await ServiceBase
      .postAsync(BASE_URL, currency))
      .json();
  },

  async updateCurrency(currency) {
    return await (await ServiceBase
      .putAsync(`${BASE_URL}/${currency.id}`, currency))
      .json();
  },

  async getEuroExchangeRate() {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/euro-exchange-rate`))
      .json();
  }
};
