import { ServiceBase } from '..';

const BASE_URL = '/core/tenants';

export const TenantService = {
  async getTenants() {
    return await (await ServiceBase
      .getAsync(BASE_URL))
      .json();
  },

  async updateCurrentTenantAdditionalData(additionalData) {
    return await (await ServiceBase
      .postAsync(`${BASE_URL}/current/additional-data`, additionalData));
  }
};
