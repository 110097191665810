import { Divider, Grid, Typography } from '@mui/material';
import { useCurrentBreakpoint } from '../../contexts/BreakpointProvider';

const styles = {
  offsetItem: {
    padding: '0px !important'
  }
};

export const FormSection = ({ title, align ="left", children, ...rest }) => {
  return (
    <FormItem {...rest}>
      <Divider textAlign={align}>
        <Typography variant="h5">
          {title}
        </Typography>
      </Divider>
      {children}
    </FormItem>
  );
};

export const FormItem = (props) => {
  const {
    xs = [],
    sm = [],
    md = [],
    lg = [],
    xl = [],
    children,
    ...rest
  } = props;

  const { currentBreakpoint } = useCurrentBreakpoint();

  const findBreakpoint = (breakpoints, currentBreakpoint) => {
    let exact = null;
    let near = null;

    const entries = Object.entries(breakpoints);

    for (const [key, value] of entries) {
      if (value?.length > 0) {
        near = value;
      }

      if (key === currentBreakpoint) {
        if (value?.length > 0) {
          exact = value;
        }

        break;
      }
    }

    if (exact) {
      return exact.map(t => ({ [currentBreakpoint]: t }));
    }

    if (near) {
      return near.map(t => ({ [currentBreakpoint]: t }));
    }

    return {
      [currentBreakpoint]: 0,
      [currentBreakpoint]: 'auto',
      [currentBreakpoint]: 0
    };
  }

  const [
    startOffsetProps,
    contentProps,
    endOffsetProps
  ] = findBreakpoint({ xs, sm, md, lg, xl }, currentBreakpoint);

  return (
    <>
      <Grid item sx={styles.offsetItem} {...startOffsetProps} />
      <Grid item {...rest} {...contentProps}>
        {children}
      </Grid>
      <Grid item sx={styles.offsetItem} {...endOffsetProps} />
    </>
  );
};
