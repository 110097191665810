import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '../../shared/hooks/useCurrentUser';
import { NotAuthorizedPage } from '../pages/NotAuthorizedPage';

export const PrivateRoute = (props) => {
  const { component: Component, roles = [] } = props;

  const navigate = useNavigate();
  const { user, hasAnyRole } = useCurrentUser();

  useEffect(() => {
    if (!user || user.expired) {
      navigate('/login');
    }
  }, [navigate, user]);

  if (!user || user.expired) {
    return <></>;
  }

  const authorized = roles.find(role => role === 'all') || hasAnyRole(...roles);

  if (authorized) {
    return <Component />;
  }
  else {
    return <NotAuthorizedPage />;
  }
};
