import React from 'react';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Description as DescriptionIcon
} from '@mui/icons-material';

import {
  EmpLinkIconButton,
  EmpLoadingIconButton
} from '..';

export const EmpTableActions = (props) => {
  const {
    viewerProps = null,
    editProps = null,
    deleteProps = null,
    other = null
  } = props;

  return (
    <React.Fragment>
      {viewerProps && (
        <EmpLinkIconButton {...viewerProps}>
          <DescriptionIcon />
        </EmpLinkIconButton>
      )}
      {editProps && (
        <EmpLinkIconButton {...editProps}>
          <EditIcon />
        </EmpLinkIconButton>
      )}
      {deleteProps && (
        <EmpLoadingIconButton color="error" {...deleteProps}>
          <DeleteIcon />
        </EmpLoadingIconButton>
      )}
      {other && other.map(({ component: Component, componentProps, icon }, index) => (
        <Component {...componentProps} />
      ))}
    </React.Fragment>
  );
};
