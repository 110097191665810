import { Roles } from '../../core';
import { useEmpAuth } from '../../core/authentication/AuthProvider';

export const parseAccessToken = (accessToken) => {
  const base64Url = accessToken.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const useCurrentUser = () => {
  const { user } = useEmpAuth();

  let roles = [];
  let token = null;
  let username = '';
  let name = '';
  let isEmpAdmin = false;

  if (user) {
    token = parseAccessToken(user.access_token); //JSON.parse(window.atob(base64));

    username = user?.profile?.name;
    name = `${user?.profile?.family_name ?? ''} ${user?.profile?.given_name ?? ''}`;

    roles = Array.isArray(token.role) ? token.role : [token.role];
    isEmpAdmin = roles.includes('emp-admin');
  }

  const hasAnyRole = (...roleList) => {
    if (!user || !roles?.length) {
      return false;
    }

    if (roles.includes(Roles.EMP_Admin)) {
      return true;
    }

    for (let i = 0; i < roleList.length; i++) {
      if (roles.includes(roleList[i])) {
        return true;
      }
    }

    return false;
  };

  const hasAllRoles = (...roleList) => {
    if (!user || !roles?.length) {
      return false;
    }

    if (roles.includes(Roles.EMP_Admin)) {
      return true;
    }

    for (let i = 0; i < roleList.length; i++) {
      if (!roles.includes(roleList[i])) {
        return false;
      }
    }

    return true;
  };

  return {
    user,
    isEmpAdmin,
    roles,
    token,
    username,
    name,
    hasAnyRole,
    hasAllRoles
  };
};
