import { ServiceBase } from '../../../shared';

const BASE_URL = '/stock-manager/warehouses';

export const WarehouseService = {
  async getWarehousesPaginated(parameters) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}?${ServiceBase.makeQueryString(parameters)}`))
      .json();
  },

  async getWarehouseById(warehouseId) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/${warehouseId}`))
      .json();
  },

  async createWarehouse(warehouse) {
    return await (await ServiceBase
      .postAsync(BASE_URL, warehouse))
      .json();
  },

  async updateWarehouse(warehouse) {
    return await (await ServiceBase
      .putAsync(`${BASE_URL}/${warehouse.id}`, warehouse))
      .json();
  }
};
