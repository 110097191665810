import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Sell as SellIcon } from '@mui/icons-material';
import { useTableV2 } from '../../../shared/components/table/hooks/useTableV2';
import { Roles } from '../../website-user/Roles';
import { EmployeeService } from './EmployeeService';
import { EmployeeActionsMenu } from './EmployeeActionsMenu';
import { EmployeeFilterForm } from './EmployeeFilterForm';

const columnsFactory = (t) => [{
  header: t('stockManager:employee.list.columns.id'),
  field: 'id',
  width: '15%'
}, {
  header: t('stockManager:employee.list.columns.name'),
  field: 'name',
  render: ({ user }) => user?.name || '-',
  width: '30%'
}, {
  header: t('stockManager:employee.list.columns.shop'),
  field: 'shop',
  render: ({ shop }) => shop?.name || '-',
  width: '30%'
}, {
  header: t('stockManager:employee.list.columns.role'),
  field: 'user',
  render: ({ user }) => {
    const module = user.modules.find(module => module.abbreviation === 'STM');
    return Roles.STM.find(role => role.id === module?.role)?.name || '-'
  },
  width: '20%'
}, {
  width: '5%',
  render: (row, { refresh }) => (
    <EmployeeActionsMenu
      employee={row}
      onDelete={refresh}
    />
  )
}];

export const useEmployeeList = () => {
  const { t } = useTranslation('stockManager');
  const columns = useMemo(() => columnsFactory(t), [t]);

  const tableProps = useTableV2({
    columns,
    title: t('employee.list.title'),
    icon: <SellIcon />,
    dataSource: EmployeeService.getEmployeesPaginated,
    components: {
      toolbar: {
        createButtonProps: {
          to: '/stock-manager/employee/editor',
          children: t('employee.list.actions.create.label')
        },
        filterModalProps: {
          maxWidth: 'sm',
          title: t('employee.list.filter.title'),
          children: EmployeeFilterForm
        }
      }
    }
  });

  return {
    tableProps
  };
};
