import { Outlet } from 'react-router-dom';
import { DefaultRoute, NotFoundPage, PrivateRoute } from '../../core';
import { UserList } from './UserList';
import { UserEditor } from './UserEditor';
import { UserViewer } from './UserViewer';

export const WebsiteUserRoutes = () => ({
  path: '/website-user',
  element: (
    <PrivateRoute
      component={Outlet}
      roles={['acm-admin', 'stm-admin']}
    />
  ),
  children: [
    {
      path: '',
      element: (
        <PrivateRoute
          component={UserList}
          roles={['acm-admin', 'stm-admin']}
        />
      )
    },
    {
      path: 'editor',
      element: (
        <PrivateRoute
          component={UserEditor}
          roles={['acm-admin', 'stm-admin']}
        />
      )
    },
    {
      path: 'editor/:userId',
      element: (
        <PrivateRoute
          component={UserEditor}
          roles={['acm-admin', 'stm-admin']}
        />
      )
    },
    {
      path: 'viewer/:userId',
      element: (
        <PrivateRoute
          component={UserViewer}
          roles={['acm-admin', 'stm-admin']}
        />
      )
    },
    {
      path: '*',
      element: (
        <DefaultRoute
          component={NotFoundPage}
        />
      )
    }
  ]
});
