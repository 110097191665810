import { useTranslation } from 'react-i18next';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { DoubleArrow as DoubleArrowIcon } from '@mui/icons-material';
import { useEmpAuth } from '../../authentication/AuthProvider';

const styles = {
  icon: {
    transform: 'rotateY(3.142rad)'
  }
};

export const LogoutMenuItem = () => {
  const { t } = useTranslation('core');
  const { signout } = useEmpAuth();

  return (
    <ListItem button onClick={() => signout()}>
      <ListItemIcon>
        <DoubleArrowIcon sx={styles.icon} />
      </ListItemIcon>
      <ListItemText primary={t('side-menu.logout')} />
    </ListItem>
  );
};
