import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Store as StoreIcon } from '@mui/icons-material';
import { ShopActionsMenu } from './ShopActionsMenu';
import { ShopFilterForm } from './ShopFilterForm';
import { EmpText } from '../../../shared/components';
import { useTableV2 } from '../../../shared/components/table/hooks/useTableV2';
import { ShopService } from './ShopService';

const columnsFactory = (t) => [{
  header: t('shop.list.columns.id'),
  field: 'id',
  width: '10%'
}, {
  header: t('shop.list.columns.name'),
  field: 'name',
  width: '20%',
  render: ({ name }) => (
    <EmpText ellipsis={15}>
      {name}
    </EmpText>
  )
}, {
  header: t('shop.list.columns.currency'),
  field: 'currency',
  width: '10%',
  render: ({ currency }) => (
    <Tooltip title={currency.name} arrow>
      <Box>{currency.shortName}</Box>
    </Tooltip>
  )
}, {
  header: t('shop.list.columns.warehouse'),
  field: 'warehouse',
  width: '15%',
  render: ({ warehouse }) => warehouse?.name || '-',
}, {
  header: t('shop.list.columns.address'),
  field: 'address',
  width: '30%',
  render: ({ address }) => (
    <EmpText ellipsis={40}>
      {address}
    </EmpText>
  )
}, {
  header: t('shop.list.columns.can-redeem-points'),
  field: 'canRedeemPoints',
  width: '10%',
  align: 'center',
  sortable: false,
  render: ({ canRedeemPoints }) => (
    canRedeemPoints ? <CheckCircleIcon color="success" />: <CancelIcon color="error" />
  ),
}, {
  width: '5%',
  sortable: false,
  render: (row, { refresh }) => (
    <ShopActionsMenu
      shop={row}
      onDelete={refresh}
    />
  )
}];

export const useShopList = () => {
  const { t } = useTranslation('stockManager');
  const columns = useMemo(() => columnsFactory(t), [t]);

  const tableProps = useTableV2({
    columns,
    title: t('shop.list.title'),
    icon: <StoreIcon />,
    dataSource: ShopService.getShopsPaginated,
    components: {
      toolbar: {
        createButtonProps: {
          to: '/stock-manager/shop/editor',
          children: t('shop.list.actions.create.label')
        },
        filterModalProps: {
          maxWidth: 'md',
          title: t('shop.list.filter.title'),
          children: ShopFilterForm
        }
      }
    }
  });

  return {
    tableProps
  };
};
