import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Divider,
  Grid,
  Typography
} from '@mui/material';

import {
  Clear as ClearIcon,
  CallMade as CallMadeIcon,
  ControlPointDuplicate as ControlPointDuplicateIcon
} from '@mui/icons-material';

import { EmpNumberField } from '../../../../shared/components';
import { usePoints } from '../hooks/usePoints';
import { SalePanel } from './SalePanel';

const styles = {
  actionsRoot: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonsRoot: {
    display: 'flex',
    '& > *': {
      alignSelf: 'flex-end'
    }
  },
  emptyRoot: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    marginLeft: 2
  }
};

export const PointRedeemPanel = () => {
  const { t } = useTranslation('stockManager');

  return (
    <SalePanel
      title={t('sale.points-panel.title')}
      icon={<ControlPointDuplicateIcon />}
    >
      <PointRedeem />
    </SalePanel>
  );
};

export const PointRedeem = () => {
  const { t } = useTranslation('stockManager');

  const {
    redeemedRounded,
    hasCustomer,
    shopCanRedeemPoints,
    customerCanRedeemPoints,
    current,
    todayUsed,
    received,
    newTotal,
    redeemAll,
    redeemNothing,
    redeem
  } = usePoints();

  if (!shopCanRedeemPoints()) {
    return (
      <Box sx={styles.emptyRoot}>
        <Typography variant="h5">
          {t('sale.points-panel.shop-not-available')}
        </Typography>
      </Box>
    );
  }

  if (!customerCanRedeemPoints()) {
    return (
      <Box sx={styles.emptyRoot}>
        <Typography variant="h5">
          {t('sale.points-panel.not-available')}
        </Typography>
      </Box>
    );
  }

  if (todayUsed() >= 5000) {
    return (
      <Box sx={styles.emptyRoot}>
        <Typography variant="h5">
          {t('sale.points-panel.maximum-points-reached')}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Grid container>
        <Grid item xs={3}>
          <Typography variant="h6" align="center">
            {t('sale.points-panel.fields.current')}
          </Typography>
          <Typography align="center">
            {current() || 'N/A'}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="h6" align="center">
            {t('sale.points-panel.fields.today-redeemed')}
          </Typography>
          <Typography align="center">
            {todayUsed()}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="h6" align="center">
            {t('sale.points-panel.fields.received')}
          </Typography>
          <Typography align="center">
            {received() || 'N/A'}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="h6" align="center">
            {t('sale.points-panel.fields.new-total')}
          </Typography>
          <Typography align="center">
            {newTotal() || 'N/A'}
          </Typography>
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }} />

      <Grid container sx={styles.actionsRoot}>
        <Grid item xs={3}>
          <EmpNumberField
            numberFieldProps={{
              label: t('sale.points-panel.fields.points-to-redeem'),
              sx: styles.textField,
              disabled: !hasCustomer,
              value: redeemedRounded || '',
              onChange: ({ target }) => redeem(target.value || 0)
            }}
          />
        </Grid>

        <Grid item xs={4} sx={styles.buttonsRoot}>
          <Button
            size="small"
            sx={styles.button}
            startIcon={<CallMadeIcon />}
            disabled={!hasCustomer}
            onClick={() => redeemAll()}
          >
            {t('sale.points-panel.actions.all')}
          </Button>
          <Button
            color="error"
            size="small"
            sx={styles.button}
            startIcon={<ClearIcon />}
            disabled={!hasCustomer}
            onClick={() => redeemNothing()}
          >
            {t('sale.points-panel.actions.nothing')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
