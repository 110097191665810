import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Editor, EmpDropdown, EmpTextField, Form, FormItem } from '../../../shared/components';
import { AttachmentUploader } from '../attachments/AttachmentUploader'
import { ProductAmountEditor } from '../products/ProductAmountEditor'
import { useStockChangeEditor } from './useStockChangeEditor';

/*
This screen makes possible to add products to the stock, or remove from stock.
Practically it creates a Document, that filles the StockChange table.
The table that is filled by this: Document->StockChange
 - fields:  * warehouse
            * stock change type
            * products
            ** product_id, 
            ** amount

ProductAmountEditor            
*/

export const StockChangeEditor = () => {
  const { t } = useTranslation('stockManager');

  const {
    form,
    warehouseDropdownProps,
    documentTypeDropdownProps,
    productDropdownProps,
    isIncreasingStock,
    handleSubmit
  } = useStockChangeEditor();

  // megoldandó:
  /*
   - docu list oldal létrehozása
   - mentés után menjünk vissza a listára
   - az előjel isIncreasingStoc nem jó az editorban
   - UI részen a fieldeket helyre tenni
   - feltöltés és attachment rész megoldása
   - egylőre csak a goods-in irány működjön, legyen disabled a mező
   - hint rész az oldalakra - ezen az oldalon tudsz bevételezni a raktárba
   - docu viewer 
   - javítani a invoice serial-t
   - invoice esetében a raktár hiányzik
   - counter recordokat létrehozni amikor létrejön egy raktár vagy document type
  */

  return (
    <Editor
      width="md"
      headerProps={{
        title: t('in-out-goods.editor.title'),
        backLinkTo: '/stock-manager/documents'
      }}
      submitProps={{
        loading: form.formState.isSubmitting,
        onSubmit: handleSubmit,
        children: t('in-out-goods.editor.actions.create.label')
      }}
    >
      <Form onSubmit={handleSubmit} columns={16} spacing={2}>

        {/* fields
            - document serial - readonly
            - stock change type dropdown - this can be controlled from outside, parameter preselects (in or out)
            - warehouse dropdown
            - user name - readonly
            - associated documents
              [standalone component -> document type: szallito, beveteli jegy, szamla, barmi]

            before saving -> are you sure that tha information is correct? you can't change this later.
        */}

        <FormItem xs={[1, 14, 1]} sm={[1, 7, 0]}>
          <EmpDropdown
            controllerProps={{
              name: 'warehouseId',
              control: form.control
            }}
            dropdownProps={{
              label: t('in-out-goods.editor.fields.warehouse'),
              ...warehouseDropdownProps
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 7, 1]}>
          <EmpDropdown
            controllerProps={{
              name: 'documentTypeId',
              defaultValue: 1,
              control: form.control,
            }}
            dropdownProps={{
              label: t('in-out-goods.editor.fields.document-type'),
              disabled: true,
              ...documentTypeDropdownProps
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[1, 7, 0]}>
          <EmpTextField
            controllerProps={{
              name: 'supplierName',
              control: form.control
            }}
            textFieldProps={{
              label: t('in-out-goods.editor.fields.supplier')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 7, 1]}>
          <EmpTextField
            controllerProps={{
              name: 'remarks',
              control: form.control
            }}
            textFieldProps={{
              label: t('in-out-goods.editor.fields.remarks')
            }}
          />
        </FormItem>

        {/* product selection part - the user can add here the products with their amount */}
        <FormItem xs={[1, 15, 0]} sm={[1, 14, 1]}>
          <Typography variant="h5">
            {t('in-out-goods.editor.fields.products.title')}
          </Typography>
        </FormItem>
        <FormItem xs={[1, 14, 1]} sm={[1, 14, 1]}>
          <ProductAmountEditor
            form={form}
            productDropdownProps={productDropdownProps}
            isIncreasingStock={isIncreasingStock}
          />
        </FormItem>

        <FormItem xs={[1, 15, 0]} sm={[1, 14, 1]}>
          <Typography variant="h5">
            {t('in-out-goods.editor.fields.documents.title')}
          </Typography>
        </FormItem>
        <FormItem xs={[1, 14, 1]} sm={[1, 14, 1]}>
          <AttachmentUploader
            controllerProps={{
              name: 'files',
              control: form.control,
              defaultValue: []
            }}
            fileUploadProps={{
              title: t('in-out-goods.editor.fields.documents.text'),
              buttonText: t('in-out-goods.editor.fields.documents.browse'),
              buttonProps: {
                variant: 'text',
                color: 'inherit',
                startIcon: <AttachFileIcon />
              },
              sx: {
                border: 0,
                '&:focus-within': null
              }
            }}
          />
        </FormItem>
      </Form>
    </Editor>
  );
};
