import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Paper, Typography } from '@mui/material';
import { Add as AddIcon, FilterList as FilterListIcon } from '@mui/icons-material';

import { EmpLinkButton } from '../../EmpLinkButton';
import { FilterModal } from '../filtering';

const styles = {
  tableToolbar: {
    padding: 1,
    display: 'flex',
    justifyContent: 'space-between',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    backgroundColor: 'secondary.main'
  }
};

export const EmpTableToolbar = ({
  title,
  icon,
  filters,
  components
}) => {
  return (
    <Paper sx={styles.tableToolbar}>
      <Box sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
        {icon}
        <Typography variant="h5" sx={{ pl: 2 }}>
          {title}
        </Typography>
      </Box>
      <Box>
        {(components) && (
          <EmpTableToolbarInternal
            filters={filters}
            {...components}
          />
        )}
      </Box>
    </Paper>
  );
};

export const EmpTableToolbarInternal = ({
  filters,
  createButtonProps,
  filterModalProps,
  other
}) => {
  const { t } = useTranslation('core');
  const [open, setOpen] = useState(false);

  const onClose = (delay = 0) => {
    setTimeout(() => setOpen(false), delay);
  };

  return (
    <>
      {(filters && filterModalProps) && (
        <>
          <Button
            variant="text"
            sx={{ mr: 2, borderRadius: '10px' }}
            startIcon={<FilterListIcon />}
            onClick={() => setOpen(true)}
          >
            {t('filter.title')}
          </Button>
          <FilterModal
            {...filterModalProps}
            open={open}
            onClose={onClose}
            filters={filters}
          />
        </>
      )}
      {createButtonProps && (
        <EmpLinkButton
          variant="text"
          startIcon={<AddIcon />}
          sx={{ borderRadius: '10px' }}
          {...createButtonProps}
        />
      )}
      {other}
    </>
  );
};
