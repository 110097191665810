import { useTranslation } from 'react-i18next';
import EvStationIcon from '@mui/icons-material/EvStation';
import ChargingStationIcon from '@mui/icons-material/ChargingStation';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import { Roles } from './Roles';
import { useModules } from '../../shared/hooks/useModules';
import { useCurrentUser } from '../../shared/hooks/useCurrentUser';

export const useEvChargerMenu = () => {
  const { t } = useTranslation('evCharger');

  const { hasAnyRole } = useCurrentUser();
  const { currentModule, currentView } = useModules();

  return {
    label: t('menu.base'),
    icon: <ChargingStationIcon />,
    active: currentModule === 'ev-charger',
    visible: hasAnyRole(Roles.EV_Admin, Roles.EV_User),
    children: [{
      label: t('menu.guest-chargings'),
      icon: <ElectricalServicesIcon />,
      active: currentView === 'guest-chargings',
      visible: hasAnyRole(Roles.EV_Admin, Roles.EV_User),
      to: '/ev-charger/guest-chargings'
    }, {
      label: t('menu.charging-sessions'),
      icon: <EvStationIcon />,
      active: currentView === 'charging-sessions',
      visible: hasAnyRole(Roles.EV_Admin),
      to: '/ev-charger/charging-sessions'
    }]
  }
};
