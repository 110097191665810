import i18n from 'i18next';
import * as yup from 'yup';

yup.addMethod(yup.string, 'emptyStringToNull', function () {
  return this.transform(value => value === '' ? null : value);
});

yup.addMethod(yup.number, 'emptyNumberToNull', function () {
  return this.transform(value => value === Number(value) ? value : null);
});

yup.setLocale({
  mixed: {
    required: () => i18n.t('validation:required'),
    notType: () => i18n.t('validation:invalid-format')
  },
  string: {
    max: ({ max }) => i18n.t('validation:string.max', { max })
  }
});
