import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { Editor, FormItem, EmpTextField } from '../../../shared/components';
import { useEmployeeViewer } from './useEmployeeViewer';
import { EmployeeActionsMenu } from './EmployeeActionsMenu';

export const EmployeeViewer = () => {
  const { t } = useTranslation('stockManager');
  const { employee } = useEmployeeViewer();

  const module = employee?.user?.modules
    .find(module => module.abbreviation.toUpperCase() === 'STM');

  return (
    <Editor
      width="md"
      headerProps={{
        title: t('employee.viewer.title'),
        backLinkTo: '/stock-manager/employee',
        actions: employee && (
          <EmployeeActionsMenu
            employee={employee}
            disabledActions={['datasheet']}
          />
        )
      }}
    >
      <Grid container columns={16} spacing={2}>
        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: employee?.id || '-',
              label: t('employee.viewer.fields.id')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: employee?.user?.name || '-',
              label: t('employee.viewer.fields.user')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: employee?.shop?.name || '-',
              label: t('employee.viewer.fields.shop')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: module ? t(`roles.${module?.role}`) : '-',
              label: t('employee.viewer.fields.role')
            }}
          />
        </FormItem>
      </Grid>
    </Editor>
  );
};
