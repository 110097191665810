import { ServiceBase } from '../../../shared';

const BASE_URL = '/stock-manager/employees';

export const EmployeeService = {
  async getEmployeesPaginated(parameters) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}?${ServiceBase.makeQueryString(parameters)}`))
      .json();
  },

  async getEmployeeById(employeeId) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/${employeeId}`))
      .json();
  },

  async createEmployee(employee) {
    return await (await ServiceBase
      .postAsync(BASE_URL, employee))
      .json();
  },

  async updateEmployee(employee) {
    return await (await ServiceBase
      .putAsync(`${BASE_URL}/${employee.id}`, employee))
      .json();
  }
};
