export const saleActionTypes = Object.freeze({
  RESET: 'STOCK-MANAGER:SALE:RESET',

  NEXT_STEP: 'STOCK-MANAGER:SALE:NEXT_STEP',

  PREV_STEP: 'STOCK-MANAGER:SALE:PREV_STEP',

  SET_INITIAL: 'STOCK-MANAGER:SALE:SET_INITIAL',

  SET_SHOPS_LOADING: 'STOCK-MANAGER:SALE:SET_SHOPS_LOADING',

  SET_SHOPS_ERROR: 'STOCK-MANAGER:SALE:SET_SHOPS_ERROR',

  SET_SHOPS_SUCCESS: 'STOCK-MANAGER:SALE:SET_SHOPS_SUCCESS',

  SET_SHOP: 'STOCK-MANAGER:SALE:SET_SHOP',

  SET_PRODUCT_CATEGORIES_LOADING: 'STOCK-MANAGER:SALE:SET_PRODUCT_CATEGORIES_LOADING',

  SET_PRODUCT_CATEGORIES_ERROR: 'STOCK-MANAGER:SALE:SET_PRODUCT_CATEGORIES_ERROR',

  SET_PRODUCT_CATEGORIES_SUCCESS: 'STOCK-MANAGER:SALE:SET_PRODUCT_CATEGORIES_SUCCESS',

  SET_CUSTOMER: 'STOCK-MANAGER:SALE:SET_CUSTOMER',

  SET_PRODUCT_CATEGORY: 'STOCK-MANAGER:SALE:SET_PRODUCT_CATEGORY',

  RESET_PRODUCT_CATEGORY: 'STOCK-MANAGER:SALE:RESET_PRODUCT_CATEGORY',

  SET_PRODUCT: 'STOCK-MANAGER:SALE:SET_PRODUCT',

  SET_SHOW_OUT_OF_STOCK_PRODUCTS: 'STOCK-MANAGER:SALE:SET_SHOW_OUT_OF_STOCK_PRODUCTS',

  SET_QUANTITY: 'STOCK-MANAGER:SALE:SET_QUANTITY',

  SET_REDEEMED_POINTS: 'STOCK-MANAGER:SALE:SET_REDEEMED_POINTS',

  SET_COMMENT: 'STOCK-MANAGER:SALE:SET_COMMENT',

  SET_PAYMENT_METHOD: 'STOCK-MANAGER:SALE:SET_PAYMENT_METHOD',

  SET_PAYMENT_CASH: 'STOCK-MANAGER:SALE:SET_PAYMENT_CASH',

  SET_PAYMENT_CARD: 'STOCK-MANAGER:SALE:SET_PAYMENT_CARD',

  SET_PAYMENT_TRANSFER: 'STOCK-MANAGER:SALE:SET_PAYMENT_TRANSFER',

  SET_EXTRA_DISCOUNT: 'STOCK-MANAGER:SALE:SET_EXTRA_DISCOUNT',

  ADD_TO_BASKET: 'STOCK-MANAGER:SALE:ADD_TO_BASKET',

  REMOVE_FROM_BASKET: 'STOCK-MANAGER:SALE:REMOVE_FROM_BASKET',

  INCREMENT_QUANTITY: 'STOCK-MANAGER:SALE:INCREMENT_QUANTITY',

  DECREMENT_QUANTITY: 'STOCK-MANAGER:SALE:DECREMENT_QUANTITY',

  UPDATE_BASKET: 'STOCK-MANAGER:SALE:UPDATE_BASKET'
});

export const saleActions = {
  reset: () => ({
    type: saleActionTypes.RESET
  }),

  nextStep: () => ({
    type: saleActionTypes.NEXT_STEP
  }),

  prevStep: () => ({
    type: saleActionTypes.PREV_STEP
  }),

  setInitial: (initialValues) => ({
    type: saleActionTypes.SET_INITIAL,
    payload: initialValues
  }),

  setShopsLoading: (loading) => ({
    type: saleActionTypes.SET_SHOPS_LOADING,
    payload: loading
  }),

  setShopsError: (error) => ({
    type: saleActionTypes.SET_SHOPS_ERROR,
    payload: error
  }),

  setShopsSuccess: (shops) => ({
    type: saleActionTypes.SET_SHOPS_SUCCESS,
    payload: shops
  }),

  setShop: (selectedShop) => ({
    type: saleActionTypes.SET_SHOP,
    payload: selectedShop
  }),

  setProductCategoriesLoading: (loading) => ({
    type: saleActionTypes.SET_PRODUCT_CATEGORIES_LOADING,
    payload: loading
  }),

  setProductCategoriesError: (error) => ({
    type: saleActionTypes.SET_PRODUCT_CATEGORIES_ERROR,
    payload: error
  }),

  setProductCategoriesSuccess: (productCategories) => ({
    type: saleActionTypes.SET_PRODUCT_CATEGORIES_SUCCESS,
    payload: productCategories
  }),

  setProductCategory: (productCategory) => ({
    type: saleActionTypes.SET_PRODUCT_CATEGORY,
    payload: productCategory
  }),

  resetProductCategory: () => ({
    type: saleActionTypes.RESET_PRODUCT_CATEGORY
  }),

  setProduct: (product) => ({
    type: saleActionTypes.SET_PRODUCT,
    payload: product
  }),

  setShowOutOfStockProducts: (show) => ({
    type: saleActionTypes.SET_SHOW_OUT_OF_STOCK_PRODUCTS,
    payload: show
  }),

  setCustomer: (customer) => ({
    type: saleActionTypes.SET_CUSTOMER,
    payload: customer
  }),

  setQuantity: (quantity) => ({
    type: saleActionTypes.SET_QUANTITY,
    payload: quantity
  }),

  addToBasket: () => ({
    type: saleActionTypes.ADD_TO_BASKET
  }),

  removeFromBasket: (productId) => ({
    type: saleActionTypes.REMOVE_FROM_BASKET,
    payload: productId
  }),

  incrementQuantity: (productId) => ({
    type: saleActionTypes.INCREMENT_QUANTITY,
    payload: productId
  }),

  decrementQuantity: (productId) => ({
    type: saleActionTypes.DECREMENT_QUANTITY,
    payload: productId
  }),

  setPaymentMethod: (value) => ({
    type: saleActionTypes.SET_PAYMENT_METHOD,
    payload: value
  }),

  setPaymentCash: (value) => ({
    type: saleActionTypes.SET_PAYMENT_CASH,
    payload: value
  }),

  setPaymentCard: (value) => ({
    type: saleActionTypes.SET_PAYMENT_CARD,
    payload: value
  }),

  setPaymentTransfer: (value) => ({
    type: saleActionTypes.SET_PAYMENT_TRANSFER,
    payload: value
  }),

  setExtraDiscount: (value) => ({
    type: saleActionTypes.SET_EXTRA_DISCOUNT,
    payload: value
  }),

  setRedeemedPoints: (value) => ({
    type: saleActionTypes.SET_REDEEMED_POINTS,
    payload: value
  }),

  updateBasket: (value) => ({
    type: saleActionTypes.UPDATE_BASKET
  })
};
