import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

const styles = {
  outerContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

export const DashboardPage = () => {
  const { t } = useTranslation('core');

  return (
    <Box sx={styles.outerContainer}>
      <Typography variant="h5" align="center" sx={{ px: 1 }}>
        {t('login.title')}
      </Typography>
    </Box>
  );
};
