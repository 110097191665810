import { Checkbox, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';

const styles = {
  disabled: {
    '& span': theme => ({
      color: theme.palette.text.primary,
      WebkitTextFillColor: theme.palette.text.primary
    })
  }
};

export const EmpCheckbox = (props) => {
  if (props.controllerProps) {
    return <EmpCheckboxForm {...props} />
  }
  else {
    return <EmpCheckboxRegular {...props} />
  }
};

const EmpCheckboxRegular = ({ label, checkboxProps }) => {
  const { sx = {}, disabled = false } = checkboxProps;
  const mergedSx = !disabled ? sx : { ...sx, ...styles.disabled };

  return (
    <FormControlLabel
      sx={mergedSx}
      label={label}
      control={<Checkbox {...checkboxProps} />}
    />
  );
};

const EmpCheckboxForm = ({ label, controllerProps, checkboxProps }) => {
  return (
    <Controller
      {...controllerProps}
      render={({ field, fieldState }) => (
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              {...field}
              {...checkboxProps}
              checked={field.value || false}
              value={field.value || false}
            />
          }
        />
      )}
    />
  );
};
