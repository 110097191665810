import { useTranslation } from 'react-i18next';

import {
  Grid,
  IconButton,
  TextField
} from '@mui/material';

import {
  Search as SearchIcon,
  Person as PersonIcon,
  PersonOff as PersonOffIcon
} from '@mui/icons-material';

import { EmpLoadingIconButton, EmpNumberField } from '../../../../shared/components';
import { CustomerInfo } from './CustomerInfo';
import { useCustomerSelector } from '../hooks/useCustomerSelector';
import { SalePanel } from './SalePanel';

const styles = {
  root: {
    p: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textField: {
    whiteSpace: 'nowrap'
  }
};

export const CustomerSelectorPanel = () => {
  const { t } = useTranslation('stockManager');

  return (
    <SalePanel
      title={t('sale.customer-panel.title')}
      icon={<PersonIcon />}
    >
      <CustomerSelector />
      <CustomerInfo />
    </SalePanel>
  );
};

const CustomerSelector = () => {
  const { t } = useTranslation('stockManager');

  const {
    error,
    loading,
    queryCustomer,
    setAnonymCustomer,
    cardNumber,
    setCardNumber,
    customerName,
    setCustomerName
  } = useCustomerSelector();

  return (
    <Grid container spacing={2} sx={styles.root}>
      <Grid item xs={4}>
        <EmpNumberField
          numberFieldProps={{
            sx: styles.textField,
            label: t('sale.customer-panel.fields.card-number'),
            error: error !== ' ',
            helperText: error,
            value: cardNumber,
            onChange: ({ target }) => setCardNumber(target.value)
          }}
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          label={t('sale.customer-panel.fields.customer-name')}
          error={error !== ' '}
          helperText={' '}
          value={customerName}
          onChange={({ target }) => setCustomerName(target.value)}
        />
      </Grid>
      <Grid item xs={1}>
        <EmpLoadingIconButton loading={loading} onClick={queryCustomer}>
          <SearchIcon />
        </EmpLoadingIconButton>
      </Grid>
      <Grid item xs={1}>
        <IconButton color="error" onClick={setAnonymCustomer}>
          <PersonOffIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
