import { ServiceBase } from '../../../shared';

const BASE_URL = '/stock-manager/shops';

export const ShopService = {
  async getShopsPaginated(parameters) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}?${ServiceBase.makeQueryString(parameters)}`))
      .json();
  },

  async getShopById(shopId) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/${shopId}`))
      .json();
  },

  async createShop(shop) {
    return await (await ServiceBase
      .postAsync(BASE_URL, shop))
      .json();
  },

  async updateShop(shop) {
    return await (await ServiceBase
      .putAsync(`${BASE_URL}/${shop.id}`, shop))
      .json();
  }
};
