import { Box } from '@mui/material';
import { GuestSearch } from './GuestSearch';
import { PriceEditorModal } from './PriceEditorModal';
import { useCurrentUser } from '../../../shared/hooks/useCurrentUser';
import { Roles } from '../Roles';

export const GuestChargingsToolbar = ({ onChange }) => {
  const { hasAnyRole } = useCurrentUser();

  return (
    <Box display="flex">
      <Box>
        <GuestSearch onChange={onChange} />
      </Box>
      {
        hasAnyRole(Roles.EV_Admin) && (
          <Box>
            <PriceEditorModal />
          </Box>
        )
      }
    </Box>
  );
};
