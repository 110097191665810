import { Link as RouterLink } from 'react-router-dom';

import {
  IconButton,
  Link
} from '@mui/material';

export const EmpLinkIconButton = (props) => {
  const { to, children } = props;

  return (
    <Link to={to} component={RouterLink}>
      <IconButton>
        {children}
      </IconButton>
    </Link>
  );
};
