import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import EvStationIcon from '@mui/icons-material/EvStation';
import { useTableV2 } from '../../../shared/components/table/hooks/useTableV2';
import { ChargingSessionService } from '../ChargingSessionService';
import { CoreFormatters } from '../../../core';
import { EVChargerFormatters } from '../_common/Formatters';

const columnsFactory = (t) => [{
  header: t('charging-session.list.columns.id'),
  field: 'id',
  sortable: false
}, {
  header: t('charging-session.list.columns.charger-name'),
  field: 'chargerName',
  sortable: false
}, {
  header: t('charging-session.list.columns.initiator-name'),
  sortable: false,
  render: (row) => row.initiator.name
}, {
  header: t('charging-session.list.columns.initiator-pin'),
  sortable: false,
  render: (row) => row.initiator.pin
}, {
  header: t('charging-session.list.columns.date'),
  sortable: false,
  render: ({ payload }) => CoreFormatters.yearAndMonthAndDayUnix(payload.s_start)
}, {
  header: t('charging-session.list.columns.started'),
  sortable: false,
  render: ({ payload }) => CoreFormatters.hourAndMinuteUnix(payload.s_start)
}, {
  header: t('charging-session.list.columns.end'),
  sortable: false,
  render: ({ payload }) => payload?.s_end ? CoreFormatters.hourAndMinuteUnix(payload.s_end) : '-'
}, {
  header: t('charging-session.list.columns.duration'),
  sortable: false,
  render: ({ payload }) => payload?.s_end ? CoreFormatters.durationHourAndMinuteUnix(payload.s_start, payload.s_end) : '-'
}, {
  header: t('charging-session.list.columns.unit-price'),
  align: 'right',
  sortable: false,
  render: ({ unitPrice }) => EVChargerFormatters.cost(unitPrice)
}, {
  header: t('charging-session.list.columns.energy'),
  align: 'right',
  sortable: false,
  render: ({ payload }) => payload?.chg_energy ? EVChargerFormatters.energy(payload.chg_energy) : '-'
}, {
  header: t('charging-session.list.columns.cost'),
  align: 'right',
  sortable: false,
  render: ({ unitPrice, payload }) => payload?.chg_energy ? EVChargerFormatters.cost(payload.chg_energy * unitPrice) : '-'
}];

export const useChargingSessions = () => {
  const { t } = useTranslation('evCharger');

  const columns = useMemo(() => columnsFactory(t), [t]);

  const tableProps = useTableV2({
    columns,
    title: t('charging-session.list.title'),
    icon: <EvStationIcon />,
    dataSource: ChargingSessionService.getChargingSessionsPaginated
  });

  return {
    tableProps
  };
};
