import { useTranslation } from 'react-i18next';
import { Editor, EmpDropdown, EmpTextField, Form, FormItem } from '../../../shared/components';
import { AddressEditor } from '../shared';
import { useCustomerEditor } from './useCustomerEditor';

export const CustomerEditor = () => {
  const { t } = useTranslation('stockManager');

  const {
    customerId,
    form,
    shopDropdownProps,
    handleSubmit,
  } = useCustomerEditor();

  return (
    <Editor
      width="md"
      headerProps={{
        title: t('customer.editor.title'),
        backLinkTo: '/stock-manager/customer'
      }}
      submitProps={{
        loading: form.formState.isSubmitting,
        onSubmit: handleSubmit,
        children: t(`customer.actions.${customerId ? 'update': 'create'}.label`)
      }}
    >
      <Form onSubmit={handleSubmit} columns={16} spacing={2}>
        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            controllerProps={{
              name: 'name',
              control: form.control
            }}
            textFieldProps={{
              label: t('customer.editor.fields.name')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpDropdown
            controllerProps={{
              name: 'shopId',
              control: form.control
            }}
            dropdownProps={{
              label: t('customer.editor.fields.shop'),
              ...shopDropdownProps
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            controllerProps={{
              name: 'email',
              control: form.control
            }}
            textFieldProps={{
              label: t('customer.editor.fields.email')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            controllerProps={{
              name: 'phone',
              control: form.control
            }}
            textFieldProps={{
              label: t('customer.editor.fields.phone')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            controllerProps={{
              name: 'collectedPoints',
              control: form.control
            }}
            textFieldProps={{
              label: t('customer.editor.fields.collected-points')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            controllerProps={{
              name: 'discountPercentage',
              control: form.control
            }}
            textFieldProps={{
              label: t('customer.editor.fields.discount-percentage')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            controllerProps={{
              name: 'taxNumber',
              control: form.control
            }}
            textFieldProps={{
              label: t('customer.editor.fields.tax-number')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            controllerProps={{
              name: 'companyName',
              control: form.control
            }}
            textFieldProps={{
              label: t('customer.editor.fields.company-name')
            }}
          />
        </FormItem>

        <AddressEditor
          form={form}
          zipProps={{
            label: t('customer.editor.fields.zip')
          }}
          cityProps={{
            label: t('customer.editor.fields.city')
          }}
          addressProps={{
            label: t('customer.editor.fields.address')
          }}
        />
      </Form>
    </Editor>
  );
};
