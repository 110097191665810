import { useTranslation } from 'react-i18next';

import { Box, Collapse, Grid, Typography } from '@mui/material';

import { PAYMENT_METHODS } from '../../saleReducer';
import { priceFormatter } from '../../../utils/PriceFormatter';
import { PaymentInput } from './PaymentInput';
import { usePayment } from '../../hooks/usePayment';

const styles = {
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

export const MixedPaymentMethod = () => {
  const { t } = useTranslation('stockManager');

  const {
    method,
    currency,
    hasDifference,
    difference,
    mixed: {
      cash,
      card,
      transfer,
      setCash,
      setCard,
      setTransfer
    }
  } = usePayment();

  return (
    <Collapse in={method === PAYMENT_METHODS.MIXED}>
      <Grid container sx={{ my: 2 }}>
        <Grid item xs={1} />
        <Grid item xs={6}>
          <Box>
            <Box sx={{ '& > *': { mt: 1 } }}>
              <Box>
                <PaymentInput
                  startLabel={t('sale.payment-panel.methods.cash')}
                  currency={currency()}
                  value={cash}
                  onChange={setCash}
                />
              </Box>
              <Box>
                <PaymentInput
                  startLabel={t('sale.payment-panel.methods.card')}
                  currency={currency()}
                  value={card}
                  onChange={setCard}
                />
              </Box>
              <Box>
                <PaymentInput
                  startLabel={t('sale.payment-panel.methods.transfer')}
                  currency={currency()}
                  value={transfer}
                  onChange={setTransfer}
                />
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={5} sx={styles.center}>
          <Typography variant="h6">
            {t('sale.payment-panel.mixed.difference')}
          </Typography>

          <Typography
            align="center"
            sx={{ fontWeight: 'bold' }}
            color={!hasDifference() ? 'success' : 'error'}
          >
            {`${priceFormatter(difference())} ${currency()}`}
          </Typography>
        </Grid>
      </Grid>
    </Collapse>
  );
};
