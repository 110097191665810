import { TableView } from '../../../shared/components';
import { EmpTableV2 } from '../../../shared/components/table/EmpTableV2';
import { useChargingSessions } from './useChargingSessions';

export const ChargingSessions = () => {
  const { tableProps } = useChargingSessions();

  return (
    <TableView width="xl">
      <EmpTableV2 {...tableProps} />
    </TableView>
  );
};
