import FileUpload from 'react-material-file-upload';
import { Controller } from 'react-hook-form';

export const AttachmentUploader = ({ controllerProps, fileUploadProps }) => {
  return (
    <Controller
      {...controllerProps}
      render={({ field }) => (
        <FileUpload
          {...fileUploadProps}
          value={field.value}
          onChange={field.onChange}
        />
      )}
    />
  );
};
