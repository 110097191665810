import { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';

import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';

import { EmpAutocomplete } from './EmpAutocomplete';
import { ServiceBase } from '../helpers/ServiceBase';

const styles = {
  disabled: {
    '& label.Mui-disabled': theme => ({
      color: theme.palette.text.primary
    }),
    '& div > div.Mui-disabled': theme => ({
      color: theme.palette.text.primary,
      WebkitTextFillColor: theme.palette.text.primary
    }),
    '& div > svg.Mui-disabled': theme => ({
      color: theme.palette.text.primary,
      WebkitTextFillColor: theme.palette.text.primary
    }),
    '& div.Mui-disabled:before': {
      borderBottomStyle: 'solid'
    }
  }
};

export const EmpDropdown = (props) => {
  const {
    controllerProps,
    dropdownProps = {}
  } = props;

  if (dropdownProps.loading) {
    dropdownProps.disabled = true;
    dropdownProps.IconComponent = EmpDropdownSpinner;
  }

  if (!dropdownProps?.options?.length) {
    dropdownProps.options = [{ value: 0, label: 'There are no options!', disabled: true }];
    //dropdownProps.disabled = true;
  }

  if (controllerProps) {
    if (dropdownProps?.options.length > 10) {
      return <EmpAutocomplete controllerProps={controllerProps} dropdownProps={dropdownProps} />
    }

    return <EmpDropdownForm controllerProps={controllerProps} dropdownProps={dropdownProps} />;
  }
  else {
    return <EmpDropdownRegular dropdownProps={dropdownProps} />;
  }
};

const EmpDropdownRegular = (props) => {
  const {
    dropdownProps: {
      label,
      error,
      helperText,
      options,
      sx = {},
      ...rest
    }
  } = props;

  const { disabled = false } = rest;
  const mergedSx = !disabled ? sx : { ...sx, ...styles.disabled };

  return (
    <FormControl variant="standard" error={error} sx={mergedSx}>
      <InputLabel>{label}</InputLabel>
      <Select {...rest} value={rest.value || ''} >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value} disabled={option.disabled || false}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText || ' '}</FormHelperText>
    </FormControl>
  );
};

const EmpDropdownForm = (props) => {
  const {
    controllerProps,
    dropdownProps
  } = props;

  const {
    label,
    options,
    onChange,
    sx = {},
    ...rest
  } = dropdownProps;

  const { disabled = false } = rest;
  const mergedSx = !disabled ? sx : { ...sx, ...styles.disabled };

  const prevValue = useRef(null);

  const handleChange = (event, formOnChange) => {
    event.target.prevValue = prevValue.current;
    prevValue.current = event.target.value;

    onChange?.(event);
    formOnChange?.(event);
  };

  return (
    <Controller
      {...controllerProps}
      render={({ field, fieldState }) => (
        <FormControl variant="standard" error={fieldState.invalid} sx={mergedSx}>
          <InputLabel>{label}</InputLabel>
          <Select {...rest} {...field}
            value={field.value || ''}
            onChange={(event) => handleChange(event, field.onChange)}
          >
            {options.map(option => (
              <MenuItem key={option.value} value={option.value} disabled={option.disabled || false}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{fieldState.error?.message || ' '}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export const EmpDropdownSpinner = () => {
  return (
    <Box>
      <CircularProgress
        thickness={5}
        size={18}
      />
    </Box>
  );
};

const defaultValueGetter = (value) => {
  return value.id;
};

const defaultLabelGetter = (value) => {
  return value.name;
};

export const useEmpDropdownAdapter = (props) => {
  const {
    url,
    sortByLabel = false,
    valueGetter = defaultValueGetter,
    labelGetter = defaultLabelGetter
  } = props;

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setLoading(true);

    const newUrl = url.includes('?') ?
      `${url}&pageNumber=0&pageSize=1000` :
      `${url}?pageNumber=0&pageSize=1000`;

    ServiceBase.getAsync(newUrl)
      .then(response => {
        return response.json();
      })
      .then((result) => {
        let data = result;

        if ('pageNumber' in result && 'pageSize' in result) {
          data = result.data;
        }

        let options = data.map(value => ({
          original: value,
          value: valueGetter(value),
          label: labelGetter(value)
        }));

        if (sortByLabel) {
          options = options
            .sort((option1, option2) => option1.label.localeCompare(option2.label));
        }

        setOptions(options);
      })
      .catch(error => {
        // TODO
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url, sortByLabel, valueGetter, labelGetter]);

  return [
    loading,
    options
  ];
};
