import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';

import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Language as LanguageIcon,
  Translate as TranslateIcon
} from '@mui/icons-material';

import { EmpStyles } from '../../../shared';

export const LanguageSelectorMenuItem = () => {
  const { t, i18n } = useTranslation('core');

  const [menuOpen, setMenuOpen] = useState(false);

  const selected = localStorage.getItem('language') || 'en';

  const handleLanguageSelected = (language) => {
    localStorage.setItem('language', language);
    i18n.changeLanguage(language);
  };

  const selectedEnClass = selected === 'en' ? EmpStyles.menu.active : {};
  const selectedHuClass = selected === 'hu' ? EmpStyles.menu.active : {};

  return (
    <Box>
      <ListItem button onClick={() => setMenuOpen(!menuOpen)}>
        <ListItemIcon>
          <LanguageIcon />
        </ListItemIcon>
        <ListItemText primary={t('side-menu.language')} />
        {menuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={menuOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            sx={{...EmpStyles.menu.nested, ...selectedEnClass}}
            onClick={() => handleLanguageSelected('en')}
          >
            <ListItemIcon>
              <TranslateIcon />
            </ListItemIcon>
            <ListItemText primary={t('languages.english')}/>
          </ListItem>
          <ListItem
            button
            sx={{...EmpStyles.menu.nested, ...selectedHuClass}}
            onClick={() => handleLanguageSelected('hu')}
          >
            <ListItemIcon>
              <TranslateIcon />
            </ListItemIcon>
            <ListItemText primary={t('languages.hungarian')}/>
          </ListItem>
        </List>
      </Collapse>
    </Box>
  );
};
