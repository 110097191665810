import { useTranslation } from 'react-i18next';
import { useSaleContext } from '../context/SaleContext';

export const useCustomerInfo = () => {
  const { t } = useTranslation('stockManager');
  const { shop, shops, customer } = useSaleContext();

  const canRedeemPoints = () => {
    const currentShop = shops?.find(s => s.id === shop);
    return currentShop?.canRedeemPoints || false;
  };

  const name = () => {
    if (!customer) {
      return t('sale.customer-panel.anonym');
    }

    if (!customer.name) {
      return '-';
    }

    return customer.name;
  };

  const discount = () => {
    return !customer?.discountPercentage ? 0 : customer.discountPercentage;
  };

  const points = () => {
    return !customer?.collectedPoints ? 0 : customer.collectedPoints;
  };

  return {
    customer,
    canRedeemPoints,
    name,
    discount,
    points
  };
};
