import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  CircularProgress,
  Typography
} from '@mui/material';

const styles = {
  container: {
    width: '60px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loadingTextContainer: {
    alignSelf: 'flex-start'
  },
  loadingText: {
    marginTop: (theme) => theme.spacing(1)
  }
};

export const EmpLoadingIndicator = (props) => {
  const { t } = useTranslation('core');

  const {
    withoutLabel
  } = props;

  const [dots, setDots] = useState('.');

  useEffect(() => {
    const timer = setInterval(() => {
      let count = dots.length + 1;

      if (count > 3) {
        count = 1;
      }

      setDots('.'.repeat(count));
    }, 400);

    return () => clearInterval(timer);
  }, [dots]);

  return (
    <Box sx={styles.container}>
      <CircularProgress />
      {!withoutLabel && (
        <Box sx={styles.loadingTextContainer}>
          <Typography variant="subtitle1" sx={styles.loadingText}>
            {t('authentication.loading') + dots}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
