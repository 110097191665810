import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { useCustomerInfo } from '../hooks/useCustomerInfo';

export const CustomerInfo = () => {
  const { t } = useTranslation('stockManager');
  const customerInfo = useCustomerInfo();

  const canRedeemPoints = customerInfo.canRedeemPoints();
  const xs = canRedeemPoints ? 4 : 6;

  return (
    <Grid container>
      <Grid item xs={xs}>
        <Typography variant="h6" align="center">
          {t('sale.customer-panel.fields.name')}
        </Typography>
        <Typography align="center">
          {customerInfo.name()}
        </Typography>
      </Grid>

      <Grid item xs={xs}>
        <Typography variant="h6" align="center">
          {t('sale.customer-panel.fields.discount')}
        </Typography>
        <Typography align="center">
          {customerInfo.discount() + '%'}
        </Typography>
      </Grid>

      {canRedeemPoints && (
        <Grid item xs={xs}>
          <Typography variant="h6" align="center">
            {t('sale.customer-panel.fields.points')}
          </Typography>
          <Typography align="center">
            {customerInfo.points()}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
