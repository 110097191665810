import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import {
  Refresh as RefreshIcon,
  SentimentDissatisfied as SentimentDissatisfiedIcon
} from '@mui/icons-material';

import { EmpTableActions } from './EmpTableActions';
import { EmpTablePaginator } from './components/EmpTablePaginator';
import { EmpTableHeaderRow } from './components/EmpTableHeaderRow';
import { EmpTableToolbar } from './components/EmpTableToolbar';
import { useEmpTableUtils } from './EmpTableUtils';

const styles = {
  tableToolbar: {
    padding: 1,
    display: 'flex',
    flexDirection: {
      //xs: 'column',
      sm: 'row'
    },
    justifyContent: {
      xs: 'center',
      sm: 'space-between'
    },
    alignItems: {
      xs: 'center',
      sm: 'center'
    },
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    backgroundColor: (theme) => theme.palette.secondary.main
  },
  title: {
    pl: 2,
  },
  icon: {
    fontSize: '2.5rem'
  }
};

export const EmpTableV2 = (props) => {
  const {
    columns,
    refresh,
    error,
    actions,
    rowColor = () => undefined,
    actionsProps = null,
    pagination = null,
    sorting = null
  } = props;

  const { rows, loading, totalCount } = props.state;

  const { getWidth } = useEmpTableUtils();

  return (
    <Box>
      <TableContainer>
        {/* Table toolbar */}
        <EmpTableToolbar
          title={props.title}
          icon={props.icon}
          filters={props.filters}
          components={props.components?.toolbar}
        />
          <Table sx={styles.table}>
          <TableHead>
            <EmpTableHeaderRow
              columns={columns}
              sorting={sorting}
              hasActions={actions || actionsProps}
            />
          </TableHead>
          {loading ? (
            <TableLoader
              columnCount={columns.length + 1 || 1}
            />
          ) : error ? (
            <TableError
              columnCount={columns.length + 1 || 1}
            />
          ) : !rows?.length ? (
            <TableEmpty
              columnCount={columns.length + 1 || 1}
              onRefresh={refresh}
            />
          ) : (
            <TableBody>
              {(rows || []).map((row, index) => (
                <TableRow key={index} style={{ backgroundColor: rowColor(row) }}>
                  {actions && (
                    <TableCell>
                      {actions(row)}
                    </TableCell>
                  )}
                  {columns.map((column, index) => (
                    column.render ? (
                      <TableCell key={index} align={column.align || 'left'} sx={{ width: getWidth(column), minHeight: '50px' }}>
                        {column.render(row, { refresh })}
                      </TableCell>
                    ) : (
                      <TableCell key={index} align={column.align || 'left'} sx={{ width: getWidth(column), minHeight: '50px' }}>
                        {row[column.field]}
                      </TableCell>
                    )
                  ))}
                  {actionsProps && (
                    <TableCell>
                      <EmpTableActions {...actionsProps(row)} />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        <EmpTablePaginator
          pagination={pagination}
          totalCount={totalCount}
        />
      </TableContainer>
    </Box>
  );
};

const TableLoader = (props) => {
  const { columnCount } = props;

  return (
    <TableBody>
      <TableRow>
        <TableCell align="center" colSpan={columnCount}>
          {/* <EmpLoadingIndicator /> */}
          <CircularProgress />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

const TableError = (props) => {
  const { t } = useTranslation('core');
  const { columnCount } = props;

  return (
    <TableBody>
      <TableRow>
        <TableCell align="center" colSpan={columnCount}>
          <SentimentDissatisfiedIcon sx={styles.icon} />
          <Typography variant="body1">
            {t('general.table-error')}
          </Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

const TableEmpty = ({ columnCount, onRefresh }) => {
  const { t } = useTranslation('core');

  return (
    <TableBody>
      <TableRow>
        <TableCell align="center" colSpan={columnCount}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body1">
              {t('general.table-empty')}
            </Typography>
            <IconButton size="small" sx={{ ml: 1 }} onClick={onRefresh}>
              <RefreshIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};
