import { useSaleContext } from '../context/SaleContext';
import { PAYMENT_METHODS } from '../saleReducer';
import { saleActions } from '../saleActions';
import { formatters } from '../../utils/Formatters';
import { useCurrentTenant } from '../../../../shared/hooks/useCurrentTenant';

export const usePayment = () => {
  const { tenant } = useCurrentTenant();
  const { customer, basket, payment, extraDiscount, dispatch } = useSaleContext();

  const currency = () => {
    if (!basket.products?.length) return '';
    return basket.products[0].currency.shortName;
  };

  const totalPayable = () => {
    if (!basket.products?.length) return 'N/A';
    if (payment.mode === PAYMENT_METHODS.GIVEAWAY) return `0 ${currency()}`;
    return `${formatters.price(basket.totalDiscountedPrice)} ${currency()}`;
  };

  const totalPayableInEuro = () => {
    if (!basket.products?.length) return 'N/A';
    if (payment.mode === PAYMENT_METHODS.GIVEAWAY) return '0 EUR';

    if (tenant?.additionalData?.euroExchangeRate) {
      return `${formatters.price(
        basket.totalDiscountedPrice / tenant.additionalData.euroExchangeRate
      )} EUR`;
    }

    return '0 EUR';
  };

  const totalMixed = () => {
    if (!basket.products?.length) return 'N/A';
    return payment.cash + payment.card + payment.transfer;
  };

  const hasDifference = () => {
    if (!basket.products?.length) return true;
    if (payment.mode !== PAYMENT_METHODS.MIXED) return false;
    return (totalMixed() || 0) !== basket.totalDiscountedPrice;
  };

  const difference = () => {
    if (!basket.products?.length) return 'N/A';
    return totalMixed() - basket.totalDiscountedPrice;
  };

  const changeMethod = (value) => {
    dispatch(saleActions.setPaymentMethod(parseInt(value)));
  };

  const setCash = (value) => {
    dispatch(saleActions.setPaymentCash(parseFloat(value)));
  };

  const setCard = (value) => {
    dispatch(saleActions.setPaymentCard(parseFloat(value)));
  };

  const setTransfer = (value) => {
    dispatch(saleActions.setPaymentTransfer(parseFloat(value)));
  };

  const setExtraDiscount = (value) => {
    dispatch(saleActions.setExtraDiscount(!value ? null : parseInt(value)));
  };

  const isGiveaway = customer?.discountPercentage >= 100 || extraDiscount >= 100;

  return {
    method: isGiveaway ? PAYMENT_METHODS.GIVEAWAY : payment.mode,
    isGiveaway,
    changeMethod,
    currency,
    totalPayable,
    totalPayableInEuro,
    hasDifference,
    difference,
    extraDiscount,
    setExtraDiscount,
    mixed: {
      cash: payment.cash,
      card: payment.card,
      transfer: payment.transfer,
      setCash,
      setCard,
      setTransfer
    }
  };
};
