import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import {
  CircularProgress,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Description as DescriptionIcon,
  MoreVert as MoreVertIcon
} from '@mui/icons-material';

import { useApiNotification } from '../hooks/useApiNotification';
import { ServiceBase } from '../helpers/ServiceBase';
import { usePopup } from './EmpPopup';

const styles = {
  progress: {
    position: 'absolute',
    zIndex: 1,
    left: '11px',
    bottom: '3px'
  }
};

export const ActionsMenu = ({ actions = {}, other = null }) => {
  const { t } = useTranslation('core');
  const { confirm } = usePopup();

  const {
    namespace = 'core',
    fragment = 'general.api-notification'
  } = (actions?.delete?.localization || {});

  const {
    showRemoveError,
    showRemoveSuccess
  } = useApiNotification({
    successNavigationUrl: actions?.delete?.successNavigationUrl,
    translation: {
      namespace,
      fragment
    }
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleOpen = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (url) => {
    const result = await confirm(
      t(`${namespace}:${fragment}.delete.confirmation.title`),
      t(`${namespace}:${fragment}.delete.confirmation.content`)
    );

    if (result === 'confirm') {
      handleDeleteInternal(url);
    }
  };

  const handleDeleteInternal = () => {
    if (actions?.delete?.url) {
      setLoading(true);

      ServiceBase.deleteAsync(actions.delete.url)
        .then(() => {
          showRemoveSuccess();
        })
        .catch(error => {
          showRemoveError(error);
        })
        .finally(() => {
          setLoading(false);

          setTimeout(() => {
            if (actions?.delete?.callback) {
              actions?.delete?.callback();
            }
            handleClose();
          }, 500);
        });
    }
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {other}
        {'datasheet' in actions && (
          <Link to={actions.datasheet.to} component={RouterLink}>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <DescriptionIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {actions.datasheet.label || t('general.actions.datasheet')}
              </ListItemText>
            </MenuItem>
          </Link>
        )}

        {'editor' in actions && (
          <Link to={actions.editor.to} component={RouterLink}>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {actions.editor.label || t('general.actions.editor')}
              </ListItemText>
            </MenuItem>
          </Link>
        )}

        {'delete' in actions && (
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <DeleteIcon color="error" fontSize="small" />
              {loading && <CircularProgress size={30} sx={styles.progress} />}
            </ListItemIcon>
            <ListItemText>
              {actions.delete.label || t('general.actions.delete')}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
