import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Link, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Repeat as RepeatIcon } from '@mui/icons-material';
import { ActionsMenu } from '../../../shared/components';

export const ProductActionsMenu = ({ product, onDelete, disabledActions = [] }) => {
  const { t } = useTranslation('stockManager');

  const actions = {};

  if (!disabledActions.includes('datasheet')) {
    actions.datasheet = {
      to: `/stock-manager/product/${product.id}`
    };
  }

  if (!disabledActions.includes('editor')) {
    actions.editor = {
      to: `/stock-manager/product/editor/${product.id}`
    };
  }

  if (!disabledActions.includes('delete')) {
    actions.delete = {
      url: `/stock-manager/product/${product.id}`,
      successNavigationUrl: '/stock-manager/product',
      callback: onDelete
    };
  }

  const other = (
    <Link to={`/stock-manager/stock-movements?filters=productId|equals|${product.id}`} component={RouterLink}>
      <MenuItem>
        <ListItemIcon>
          <RepeatIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          {t('product.actions.show-stock-movements.label')}
        </ListItemText>
      </MenuItem>
    </Link>
  );

  return <ActionsMenu actions={actions} other={other} />;
};
