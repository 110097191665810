import { ServiceBase } from '../../shared';

const BASE_URL = '/ev-charger/charging-session';

export const ChargingSessionService = {
  async getGuestChargingsPaginated(parameters) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/guest-chargings?${ServiceBase.makeQueryString(parameters)}`))
      .json();
  },

  async getGuestChargings(guestId) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/guest-chargings/${guestId}`))
      .json()
      .then(chargingSessions => (
        chargingSessions.map(chargingSession => ({
          ...chargingSession,
          payload: JSON.parse(chargingSession.payload)
        }))
      ));
  },

  async getChargingSessionsPaginated(parameters) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}?${ServiceBase.makeQueryString(parameters)}`))
      .json()
      .then(paginated => ({
        ...paginated,
        data: paginated.data.map(chargingSession => ({
          ...chargingSession,
          payload: JSON.parse(chargingSession.payload)
        }))
      }));
  },

  async getLatestPriceInfo() {
    return await (await ServiceBase
      .getAsync('/ev-charger/price-info'))
      .json();
  },

  async setPrice(newPrice) {
    await ServiceBase.postAsync('/ev-charger/price-info', { value: newPrice });
  }
};
