import { useTranslation } from 'react-i18next';
import { Editor, EmpTextField, Form, FormItem } from '../../../shared/components';
import { useCurrencyEditor } from './useCurrencyEditor';

export const CurrencyEditor = () => {
  const { t } = useTranslation('stockManager');

  const {
    currencyId,
    form,
    handleSubmit
  } = useCurrencyEditor();

  return (
    <Editor
      width="sm"
      headerProps={{
        title: t('currency.editor.title'),
        backLinkTo: '/stock-manager/currency'
      }}
      submitProps={{
        loading: form.formState.isSubmitting,
        onSubmit: handleSubmit,
        children: t(`currency.actions.${ currencyId ? 'update' : 'create' }.label`)
      }}
    >
      <Form onSubmit={handleSubmit} columns={16} spacing={2}>
        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            controllerProps={{
              name: 'name',
              control: form.control
            }}
            textFieldProps={{
              label: t('currency.editor.fields.name')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            controllerProps={{
              name: 'shortName',
              control: form.control
            }}
            textFieldProps={{
              label: t('currency.editor.fields.short-name')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[5, 6, 0]}>
          <EmpTextField
            controllerProps={{
              name: 'symbol',
              control: form.control
            }}
            textFieldProps={{
              label: t('currency.editor.fields.symbol')
            }}
          />
        </FormItem>
      </Form>
    </Editor>
  );
};
