import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { EmpTextField, FormItem } from '../../../shared/components';

const styles = {
  emptyRoot: {
    display: 'flex',
    justifyContent: 'center'
  }
};

export const ProductPriceViewer = ({ prices }) => {
  const { t } = useTranslation('stockManager');

  if (!prices?.length) {
    return (
      <Grid container spacing={2} columns={16}>
        <FormItem xs={[0, 16, 0]} sx={styles.emptyRoot}>
          <Typography>
            {t('product.viewer.product-price.empty')}
          </Typography>
        </FormItem>
      </Grid>
    );
  }

  return (
    <>
      {prices.map((price, index) => (
        <Grid key={index} container spacing={2} columns={16}>
          <FormItem xs={[0, 16, 0]} sm={[0, 8, 0]} md={[0, 5, 0]}>
            <EmpTextField
              textFieldProps={{
                label: t('product.viewer.fields.prices.shop'),
                disabled: true,
                value: price?.shop?.name || '-'
              }}
            />
          </FormItem>

          <FormItem xs={[0, 16, 0]} sm={[0, 8, 0]} md={[0, 5, 0]}>
            <EmpTextField
              textFieldProps={{
                label: t('product.viewer.fields.prices.currency'),
                disabled: true,
                value: price?.currency?.name || '-'
              }}
            />
          </FormItem>

          <FormItem xs={[0, 8, 0]} sm={[0, 8, 0]} md={[0, 3, 0]}>
            <EmpTextField
              textFieldProps={{
                label: t('product.viewer.fields.prices.selling-price'),
                disabled: true,
                value: price?.sellingPrice || '-'
              }}
            />
          </FormItem>

          <FormItem xs={[0, 8, 0]} sm={[0, 8, 0]} md={[0, 3, 0]}>
            <EmpTextField
              textFieldProps={{
                label: t('product.viewer.fields.prices.points-per-unit'),
                disabled: true,
                value: price?.pointsPerUnit || '-'
              }}
            />
          </FormItem>
        </Grid>
      ))}
    </>
  );
};
