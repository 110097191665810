import './EmpTablePaginator.scss';
import { TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const EmpTablePaginator = ({ pagination, totalCount }) => {
  const { t } = useTranslation('core');

  const {
    state: {
      pageNumber,
      pageSize,
      options
    },
    onPageNumberChange,
    onPageSizeChange
  } = pagination;

  const handlePageChange = (event, page) => {
    onPageNumberChange(parseInt(page));
  };

  const handleRowsPerPageChange = ({ target }) => {
    onPageSizeChange(parseInt(target.value));
  };

  return (
    <TablePagination
      showFirstButton
      showLastButton
      component="div"
      labelDisplayedRows={({ from, to, count }) => t('pagination.displayed-rows', { from, to, count })}
      labelRowsPerPage={t('pagination.rows-per-page')}
      count={totalCount}
      page={pageNumber}
      onPageChange={handlePageChange}
      rowsPerPage={pageSize}
      onRowsPerPageChange={handleRowsPerPageChange}
      rowsPerPageOptions={options}
    />
  );
};
