import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';
import { FormItem, EmpTextField, EmpDatePicker } from '../../../shared/components';

export const HistoryForm = (props) => {
  const { noTitle, historyObject } = props;
  const { t } = useTranslation('stockManager');

  return (
    <React.Fragment>
      {!noTitle && (
        <Grid container spacing={2} columns={16}>
          <FormItem xs={[0, 16, 0]}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              {t('product.viewer.sections.history')}
            </Typography>
          </FormItem>
        </Grid>
      )}

      <Grid container spacing={2} columns={16}>
        <FormItem xs={[0, 16, 0]} sm={[0, 6, 0]} md={[0, 3, 0]}>
          <EmpDatePicker
            datePickerProps={{
              label: t('product.viewer.fields.created-at'),
              disabled: true,
              value: historyObject?.createdAt
            }}
          />
        </FormItem>

        <FormItem xs={[0, 16, 0]} sm={[0, 10, 0]}  md={[0, 5, 0]}>
          <EmpTextField
            textFieldProps={{
              label: t('product.viewer.fields.created-by'),
              disabled: true,
              value: historyObject?.createdBy || ''
            }}
          />
        </FormItem>

        <FormItem xs={[0, 16, 0]} sm={[0, 6, 0]}  md={[0, 3, 0]}>
          <EmpDatePicker
            datePickerProps={{
              label: t('product.viewer.fields.modified-at'),
              disabled: true,
              value: historyObject?.modifiedAt
            }}
          />
        </FormItem>

        <FormItem xs={[0, 16, 0]} sm={[0, 10, 0]}  md={[0, 5, 0]}>
          <EmpTextField
            textFieldProps={{
              label: t('product.viewer.fields.modified-by'),
              disabled: true,
              value: historyObject?.modifiedBy || '-'
            }}
          />
        </FormItem>
      </Grid>
    </React.Fragment>
  );
}
