import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useApiNotification } from '../../../shared/hooks/useApiNotification';
import { ProductUnitService } from './ProductUnitService';

const schema = yup.object().shape({
  name:
    yup.string().required().trim().max(32)
});

export const useProductUnitEditor = () => {
  const { productUnitId } = useParams();

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const {
    showUpdateError,
    showUpdateSuccess,
    showCreateError,
    showCreateSuccess
  } = useApiNotification({
    successNavigationUrl: '/stock-manager/product-unit',
    translation: {
      namespace: 'stockManager',
      fragment: 'product-unit.actions'
    }
  });

  const { handleSubmit, setValue } = form;

  useEffect(() => {
    if (productUnitId) {
      ProductUnitService.getProductUnitById(productUnitId)
        .then(productUnit => {
          Object.entries(productUnit)
            .forEach(([key, value]) => setValue(key, value));
        });
    }
  }, [setValue, productUnitId]);

  const handleSubmitInternal = async (data) => {
    if (productUnitId) {
      await updateProductUnit(data);
    }
    else {
      await createProductUnit(data);
    }
  };

  const createProductUnit = async (productUnit) => {
    return ProductUnitService.createProductUnit(productUnit)
      .then(() => {
        showCreateSuccess();
      })
      .catch(error => {
        showCreateError(error);
      });
  };

  const updateProductUnit = async (productUnit) => {
    return ProductUnitService.updateProductUnit(productUnit)
      .then(() => {
        showUpdateSuccess();
      })
      .catch(error => {
        showUpdateError(error);
      });
  };

  return {
    productUnitId,
    form,
    handleSubmit: handleSubmit(handleSubmitInternal)
  };
};
