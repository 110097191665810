import * as yup from 'yup';

const formValueAdapter = (formValues) => {
  const schemas = {};
  const defaultValues = {};

  Object.entries(formValues)
    .forEach(([key, { schema, defaultValue }]) => {
      schemas[key] = schema;
      defaultValues[key] = defaultValue;
    });

  return {
    schema: yup.object().shape(schemas),
    defaultValues: defaultValues
  };
};

export const formUtils = {
  formValueAdapter
};
