import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useWatch } from 'react-hook-form';

import { Button, Grid, IconButton } from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';

import { EmpDropdown, EmpNumberField, EmpTextField, FormItem } from '../../../shared/components';

const styles = {
  emptyPrices: {
    display: 'flex',
    justifyContent: 'center'
  },
  actionColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

export const ProductPriceEditor = ({ form, shopDropdownProps }) => {
  const { t } = useTranslation('stockManager');

  const [selectedShops, setSelectedShops] = useState([]);

  const { fields, append, remove } = useFieldArray({
    name: 'prices',
    control: form.control
  });

  useEffect(() => {
    setSelectedShops(
      fields
        .filter(field => field.shopId)
        .map(field => field.shopId)
    );
  }, [fields]);

  const handleChange = ({ target }) => {
    let newSelectedShops = [target.value, ...selectedShops];

    if (target.prevValue) {
      newSelectedShops = newSelectedShops
        .filter(shopId => shopId !== target.prevValue)
    }

    setSelectedShops(newSelectedShops);
  };

  if (!fields?.length) {
    return (
      <Grid container columns={16} spacing={2}>
        <FormItem xs={[0, 16, 0]} sx={styles.emptyPrices}>
          <Button
            variant="text"
            color="inherit"
            startIcon={<AddIcon />}
            onClick={() => append({})}
          >
            {t('product.editor.actions.add-price.label')}
          </Button>
        </FormItem>
      </Grid>
    );
  }

  return (
    fields.map(({ id }, index) => (
      <Grid key={id} container columns={16} spacing={2}>
        <PriceItem
          control={form.control}
          index={index}
          append={append}
          remove={remove}
          items={fields.length}
          onShopChange={handleChange}
          selectedShops={selectedShops}
          shopDropdownProps={shopDropdownProps}
        />
      </Grid>
    ))
  );
};

const PriceItem = ({ control, index, append, remove, items, onShopChange, selectedShops, shopDropdownProps }) => {
  const { t } = useTranslation('stockManager');

  const shopId = useWatch({
    name: `prices.${index}.shopId`,
    control: control
  });

  const shopDropdownPropsCopy = {
    ...shopDropdownProps,
    options: shopDropdownProps.options.filter(x => {
      const value = selectedShops.find(y => y === x.value);
      return !value || value === shopId ? true : false;
    })
  };

  let currencyName = null;

  if (shopId) {
    const shop = shopDropdownProps.options
      .find(shop => shop.original.id === shopId);

    if (shop) {
      currencyName = shop.original.currency.name;
    }
  }

  return (
    <>
      <FormItem xs={[0, 16, 0]} sm={[0, 8, 0]} md={[0, 4, 0]}>
        <EmpDropdown
          controllerProps={{
            name: `prices.${index}.shopId`,
            control
          }}
          dropdownProps={{
            label: t('product.editor.fields.prices.shop'),
            onChange: onShopChange,
            ...shopDropdownPropsCopy
          }}
        />
      </FormItem>

      <FormItem xs={[0, 16, 0]} sm={[0, 8, 0]} md={[0, 4, 0]}>
        <EmpTextField
          textFieldProps={{
            label: t('product.editor.fields.prices.currency'),
            disabled: true,
            value: currencyName
          }}
        />
      </FormItem>

      <FormItem xs={[0, 8, 0]} sm={[0, 4, 0]} md={[0, 3, 0]}>
        <EmpNumberField
          controllerProps={{
            name: `prices.${index}.sellingPrice`,
            control
          }}
          numberFieldProps={{
            label: t('product.editor.fields.prices.price')
          }}
        />
      </FormItem>

      <FormItem xs={[0, 8, 0]} sm={[0, 4, 0]} md={[0, 3, 0]}>
        <EmpNumberField
          controllerProps={{
            name: `prices.${index}.pointsPerUnit`,
            control
          }}
          numberFieldProps={{
            label: t('product.editor.fields.prices.points')
          }}
        />
      </FormItem>

      <FormItem xs={[0, 16, 0]} sm={[0, 2, 0]} md={[0, 2, 0]} sx={styles.actionColumn}>
        {(index === items - 1 && shopDropdownProps.options.length > items) && (
          <IconButton onClick={() => append({})}>
            <AddIcon />
          </IconButton>
        )}
        <IconButton color="error" onClick={() => remove(index, shopId)}>
          <DeleteIcon />
        </IconButton>
      </FormItem>
    </>
  );
};
