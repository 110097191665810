import { ServiceBase } from '../../../shared';

const BASE_URL = '/stock-manager/stock-movements';

export const StockService = {
  async getWarehouses() {
    return await (await ServiceBase
      .getAsync('/stock-manager/warehouses?pageNumber=0&pageSize=2000'))
      .json();
  },

  async getProducts() {
    return await (await ServiceBase
      .getAsync('/stock-manager/products?pageNumber=0&pageSize=2000'))
      .json();
  },

  //    requestParams: `/stock-manager/stock-state?productid=-1&warehouseid=${warehouse}`
  async getCurrentStock(parameters, warehouseId) {
    return await (await ServiceBase
      .getAsync(`/stock-manager/stock-state?${ServiceBase.makeQueryString(parameters)}&productId=-1&warehouseId=${warehouseId}`))
      .json();
  },

  async getStockMovementsPaginated(parameters) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}?${ServiceBase.makeQueryString(parameters)}`))
      .json();
  }
};
