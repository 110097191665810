import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { formUtils, useEmpDropdownAdapter } from '../../../shared/components';
import { useApiNotification } from '../../../shared/hooks/useApiNotification';
import { DocumentService } from '../documents/DocumentService';
import { AttachmentService } from '../attachments/AttachmentService';

const formValues = formUtils.formValueAdapter({
  documentTypeId: {
    defaultValue: 1,
    schema: yup.number().integer().required()
  },
  supplierName: {
    defaultValue: '',
    schema: yup.string().max(64)
  },
  remarks: {
    defaultValue: '',
    schema: yup.string().max(32)
  },
  warehouseId: {
    defaultValue: '',
    schema: yup.number().required()
  },
  stockChanges: {
    defaultValue: null,
    schema: yup.array(yup.object().shape({
      productId: yup.number().integer().required(),
      amount: yup.number().required().positive(),
    }))
  },
  files: {
    defaultValue: [],
    schema: null
  }
});

const labelGetterDocType = (value) => value.typeName;

export const useStockChangeEditor = () => {
  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(formValues.schema),
    //defaultValues: formValues.defaultValues
  });

  const {
    showCreateError,
    showCreateSuccess
  } = useApiNotification({
    successNavigationUrl: '/stock-manager/documents'
  });

  // *** dropdowns ***
  // documentTypeDropdownProps
  const [documentTypeDropdownLoading, documentTypeDropdownOptions] = useEmpDropdownAdapter({
    url: '/stock-manager/documents/document-types',
    labelGetter: labelGetterDocType,
    sortByLabel: true
  });

  const documentTypeDropdownProps = {
    loading: documentTypeDropdownLoading,
    options: documentTypeDropdownOptions
  };

  // warehouseDropdownProps
  const [warehouseDropdownLoading, warehouseDropdownOptions] = useEmpDropdownAdapter({
    url: '/stock-manager/warehouses',
    sortByLabel: true
  });

  const warehouseDropdownProps = {
    loading: warehouseDropdownLoading,
    options: warehouseDropdownOptions
  };

  // productDropdownProps
  const [productDropdownLoading, productDropdownOptions] = useEmpDropdownAdapter({
    url: '/stock-manager/products',
    sortByLabel: true
  });

  const productDropdownProps = {
    loading: productDropdownLoading,
    options: productDropdownOptions
  };

  // TODO - it is always true because only the Raktár bevételi jegy támogatott
  const isIncreasingStock = true;
  const { handleSubmit } = form;

  const handleSubmitInternal = (data) => {
    const { files, ...document } = data;

    return DocumentService.createDocument(document)
      .then(document => {
        AttachmentService.uploadAttachments(document.id, files)
          .then(() => {
            showCreateSuccess();
          })
          .catch(error => {
            showCreateError(error);
          });
      })
      .catch(error => {
        showCreateError(error);
      });
  };

  const onErrorHandler = (error) => {
    console.warn(error);
  };

  return {
    form,
    warehouseDropdownProps,
    documentTypeDropdownProps,
    productDropdownProps,
    isIncreasingStock,
    handleSubmit: handleSubmit(handleSubmitInternal, onErrorHandler)
  };
};
