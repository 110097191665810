import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Link, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Receipt as ReceiptIcon } from '@mui/icons-material';
import { ActionsMenu } from '../../../shared/components';

export const ShopActionsMenu = ({ shop, onDelete, disabledActions = [] }) => {
  const { t } = useTranslation('stockManager');

  const actions = {};

  if (!disabledActions.includes('datasheet')) {
    actions.datasheet = {
      to: `/stock-manager/shop/${shop.id}`
    };
  }

  if (!disabledActions.includes('editor')) {
    actions.editor = {
      to: `/stock-manager/shop/editor/${shop.id}`
    };
  }

  if (!disabledActions.includes('delete')) {
    actions.delete = {
      url: `/stock-manager/shops/${shop.id}`,
      successNavigationUrl: '/stock-manager/shop',
      callback: onDelete
    };
  }

  const other = (
    <Link
      component={RouterLink}
      to={`/stock-manager/invoice?filters=shopName|contains|${shop.name}`}
    >
      <MenuItem>
        <ListItemIcon>
          <ReceiptIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          {t('shop.list.actions.show-invoices.title')}
        </ListItemText>
      </MenuItem>
    </Link>
  );

  return <ActionsMenu actions={actions} other={other} />;
};
