import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { Editor, EmpTextField, FormItem } from '../../../shared/components';
import { useWarehouseViewer } from './useWarehouseViewer';
import { WarehouseActionsMenu } from './WarehouseActionsMenu';

export const WarehouseViewer = () => {
  const { t } = useTranslation('stockManager');
  const { warehouse } = useWarehouseViewer();

  return (
    <Editor
      width="sm"
      headerProps={{
        title: t('warehouse.viewer.title'),
        backLinkTo: '/stock-manager/warehouses',
        actions: warehouse && (
          <WarehouseActionsMenu
            warehouse={warehouse}
            disabledActions={['datasheet']}
          />
        )
      }}
    >
      <Grid container columns={16} spacing={2}>
        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              label: t('warehouse.viewer.fields.id'),
              disabled: true,
              value: warehouse?.id || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              label: t('warehouse.viewer.fields.name'),
              disabled: true,
              value: warehouse?.name || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 12, 2]}>
          <EmpTextField
            textFieldProps={{
              label: t('warehouse.viewer.fields.address'),
              disabled: true,
              value: warehouse?.address || '-'
            }}
          />
        </FormItem>
      </Grid>
    </Editor>
  );
};
