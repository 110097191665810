import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import DescriptionIcon from '@mui/icons-material/Description';
import { EmpLoadingIconButton } from '../../../shared/components';
import { useTableV2 } from '../../../shared/components/table/hooks/useTableV2';
import { ChargingSessionService } from '../ChargingSessionService';
import { EVChargerFormatters } from '../_common/Formatters';
import { GuestChargingsToolbar } from './GuestChargingsToolbar';

const columnsFactory = (t, detailModalProps) => [{
  header: t('guest-charging.list.columns.id'),
  field: 'initiatorId',
  sortable: false
}, {
  header: t('guest-charging.list.columns.initiator-name'),
  field: 'initiatorName',
  sortable: false
}, {
  header: t('guest-charging.list.columns.initiator-pin'),
  field: 'initiatorPIN',
  sortable: false
}, {
  header: t('guest-charging.list.columns.charging-sessions'),
  align: 'right',
  sortable: false,
  render: ({ chargingSessions }) => `${chargingSessions}x`
}, {
  header: t('guest-charging.list.columns.total-energy'),
  align: 'right',
  sortable: false,
  render: ({ totalEnergy }) => EVChargerFormatters.energy(totalEnergy)
}, {
  header: t('guest-charging.list.columns.total-cost'),
  align: 'right',
  sortable: false,
  render: ({ totalPrice }) => <b>{EVChargerFormatters.cost(totalPrice)}</b>
}, {
  align: 'right',
  sortable: false,
  render: ({ initiatorId, initiatorName }) => (
    <EmpLoadingIconButton
      loading={detailModalProps.loading || false}
      variant="text"
      sx={{ borderRadius: '10px' }}
      onClick={() => detailModalProps.onClick(initiatorId, initiatorName)}
    >
      <DescriptionIcon />
    </EmpLoadingIconButton>
  )
}];

export const useGuestChargings = () => {
  const { t } = useTranslation('evCharger');

  const [detailModalLoading, setDetailModalLoading] = useState(false);
  const [detailModal, setDetailModal] = useState({
    open: false,
    guest: null,
    chargingSessions: null
  });

  const handleDetailModalClick = async (guestId, guestName) => {
    try {
      setDetailModalLoading(true);

      const chargingSessions = await ChargingSessionService.getGuestChargings(guestId);

      setDetailModal({
        open: true,
        guest: {
          id: guestId,
          name: guestName
        },
        chargingSessions
      });
    }
    finally {
      setDetailModalLoading(false);
    }
  };

  const handleClose = () => {
    setDetailModal({
      open: false,
      guest: null,
      chargingSessions: null
    });
  }

  const [guest, setGuest] = useState(null);
  const guestInputRef = useRef({
    guest: null,
    timer: null
  });

  const handleChange = (value) => {
    if (guestInputRef.current.timer !== null) {
      clearTimeout(guestInputRef.current.timer);
    }

    guestInputRef.current.guest = value;

    guestInputRef.current.timer = setTimeout(() => {
      setGuest(guestInputRef.current.guest);
    }, 250);
  };

  const handleChargingSessionsLoad = useCallback((parameters) => {
    if (guest) {
      parameters.filters = {
        ...parameters.filters,
        initiator: {
          operator: 'contains',
          value: guest
        }
      };
    }

    return ChargingSessionService.getGuestChargingsPaginated(parameters);
  }, [guest])

  const columns = useMemo(() => {
    return columnsFactory(t, { loading: detailModalLoading, onClick: handleDetailModalClick });
  }, [t, detailModalLoading]);

  const tableProps = useTableV2({
    columns,
    title: t('guest-charging.list.title'),
    icon: <ElectricalServicesIcon />,
    dataSource: handleChargingSessionsLoad,
    components: {
      toolbar: {
        other: <GuestChargingsToolbar onChange={handleChange} />
      }
    }
  });

  return {
    tableProps,
    detailModal,
    handleClose
  };
};
