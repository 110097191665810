import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ProductService } from './ProductService';

export const useProductViewer = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    if (productId) {
      ProductService.getProductById(productId)
        .then(product => {
          setProduct(product);
        });
    }
  }, [productId]);

  return {
    product
  };
};
