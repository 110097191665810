import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '../../shared/hooks/useCurrentUser';

export const DefaultRoute = ({ component: Component }) => {
  const navigate = useNavigate();
  const { user } = useCurrentUser();

  useEffect(() => {
    if (!user || user.expired) {
      navigate('/login');
    }
  }, [navigate, user]);

  if (!user || user.expired) {
    return <></>;
  }

  return <Component />;
};
