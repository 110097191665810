import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { DateTime } from 'luxon';
import { Store as StoreIcon } from '@mui/icons-material';
import { useEmpDropdownAdapter } from '../../../shared/components';
import { useTableV2 } from '../../../shared/components/table/hooks/useTableV2';
import { StockService } from './StockService';
import { useSearchParams } from 'react-router-dom';
import { useCurrentBreakpoint } from '../../../shared/contexts/BreakpointProvider';

const columnsFactory = (t, isDesktop) => {
  const columns = [];

  columns.push({
    header: t('current-stock.list.columns.product-name'),
    sortable: false,
    width: {
      mobile: '60%',
      desktop: '30%'
    },
    field: 'productName'
  });

  if (isDesktop()) {
    columns.push({
      header: t('current-stock.list.columns.sku'),
      sortable: false,
      width: '25%',
      field: 'sku'
    });
  }

  columns.push({
    header: {
      mobile: t('current-stock.list.columns.current-amount.mobile'),
      desktop: t('current-stock.list.columns.current-amount.desktop')
    },
    sortable: false,
    align: 'right',
    width: { mobile: '15%', desktop: '10%' },
    render: ({ currentAmount }) => `${currentAmount}`
  });

  columns.push({
    header: {
      mobile: t('current-stock.list.columns.packing.mobile'),
      desktop: t('current-stock.list.columns.packing.desktop')
    },
    sortable: false,
    width: { mobile: '25%', desktop: '10%' },
    render: ({ productUnit }) => `${productUnit}`
  });

  if (isDesktop()) {
    columns.push({
      header: t('current-stock.list.columns.last-stock-change-date'),
      sortable: false,
      align: 'right',
      width: '25%',
      render: ({ lastStockChangeDate }) => DateTime
        .fromISO(lastStockChangeDate)
        .setLocale(i18n.language)
        .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
    });
  }

  return columns;
};

export const useStockCurrentState = () => {
  const { isDesktop } = useCurrentBreakpoint();
  const { t } = useTranslation('stockManager');

  const columns = useMemo(() => columnsFactory(t, isDesktop), [t, isDesktop]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [warehouseId, setWarehouse] = useState(
    searchParams.has('warehouse') ? parseInt(searchParams.get('warehouse')) : null
  );

  const getCurrentStock = useCallback((parameters) => {
    if (!warehouseId) return Promise.resolve({ data: [], totalCount: 0 });
    return StockService.getCurrentStock(parameters, warehouseId);
  }, [warehouseId]);

  const tableProps = useTableV2({
    columns,
    rowColor: ({ currentAmount }) => currentAmount < 10 ? '#ef9a9a' : undefined,
    title: t('current-stock.list.title'),
    icon: <StoreIcon />,
    dataSource: getCurrentStock
  });

  const [warehouseLoading, warehouseOptions] = useEmpDropdownAdapter({
    url: '/stock-manager/warehouses'
  });

  useEffect(() => {
    setSearchParams((prev) => {
      if (warehouseId) {
        prev.set('warehouse', warehouseId);
      }
      else {
        prev.delete('warehouse');
      }

      return prev;
    });
  }, [warehouseId, setSearchParams]);

  useEffect(() => {
    if (warehouseOptions.length > 0) {
      if (warehouseId) {
        const warehouse = warehouseOptions.find(x => x.value === warehouseId);
        if (!warehouse) {
          setWarehouse(warehouseOptions[0].value);
        }
      }
      else {
        setWarehouse(warehouseOptions[0].value);
      }
    }
  }, [warehouseId, warehouseOptions, setSearchParams]);

  const handleWarehouseChange = ({ target }) => {
    setWarehouse(target.value);
  };

  return {
    tableProps,
    warehouseDropdownProps: {
      label: t('current-stock.list.fields.warehouse'),
      value: warehouseId,
      onChange: handleWarehouseChange,
      loading: warehouseLoading,
      options: warehouseOptions
    }
  };
};
