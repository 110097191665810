const getFileNameFromHeader = (headers, defaultFileName) => {
  if (!headers.has('Content-Disposition')) {
    return defaultFileName;
  }

  for (let value of headers.get('Content-Disposition').split(';')) {
    if (value.trim().indexOf(`filename*=UTF-8''`) === 0) {
      return decodeURIComponent(value.trim().replace(`filename*=UTF-8''`, ''));
    }
  }

  return defaultFileName;
};

const parseFileResponse = async (response, defaultFileName) => {
  return {
    filename: getFileNameFromHeader(response.headers, defaultFileName),
    contentType: response.headers.get('Content-Type'),
    blob: await response.blob()
  };
};

const downloadFile = async (file, openInBrowser = false) => {
  // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
  const newBlob = new Blob([file.blob], { type: file.contentType });

  // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
  }
  else {
    // For other browsers: create a link pointing to the ObjectURL containing the blob.
    const objUrl = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = objUrl;

    if (!openInBrowser) {
      link.download = file.filename;
    }
    else {
      link.target = '_blank';
    }

    link.click();

    // For Firefox it is necessary to delay revoking the ObjectURL.
    setTimeout(() => {
      window.URL.revokeObjectURL(objUrl);
    }, 250);
  }
};

export const fileDownloadHelper = {
  parseFileResponse,
  downloadFile
};
