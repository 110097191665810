import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterForm, FilterItem } from '../../../shared/components';

const defaultValues = {
  date: {
    operator: 'equals',
    value: null
  },
  shopName: {
    operator: 'contains',
    value: null
  },
  sellerName: {
    operator: 'contains',
    value: null
  },
  customerName: {
    operator: 'contains',
    value: null
  },
  discountPercentage: {
    operator: 'equals',
    value: null
  },
  extraDiscountPercentage: {
    operator: 'equals',
    value: null
  },
  totalDiscountedAmount: {
    operator: 'equals',
    value: null
  },
  receivedPoints: {
    operator: 'equals',
    value: null
  },
  redeemedPoints: {
    operator: 'equals',
    value: null
  },
  paymentMode: {
    operator: 'equals',
    value: null
  },
  itemCount: {
    operator: 'equals',
    value: null
  },
  invoiceElements: {
    operator: 'contains',
    value: null
  }
};

const paymentModeFactory = (t) => [{
  label: t('sale.payment-panel.methods.cash'),
  value: 'cash'
}, {
  label: t('sale.payment-panel.methods.card'),
  value: 'card'
}, {
  label: t('sale.payment-panel.methods.transfer'),
  value: 'transfer'
}, {
  label: t('sale.payment-panel.methods.mixed'),
  value: 'mixed'
}, {
  label: t('sale.payment-panel.methods.giveaway'),
  value: 'giveaway'
}];

export const InvoiceFilterForm = ({ filters, onFilterChange }) => {
  const { t } = useTranslation('stockManager');
  const paymentModes = useMemo(() => paymentModeFactory(t), [t]);

  filters = {
    ...defaultValues,
    ...filters
  };

  const handleOperatorChange = ({ target: { name, value } }) => {
    onFilterChange(name, { ...filters[name], operator: value });
  };

  const handleValueChange = ({ target: { name, value } }) => {
    onFilterChange(name, { ...filters[name], value: value });
  };

  return (
    <FilterForm>
      {/* Date */}
      <FilterItem
        type="date"
        name="date"
        label={t('invoice.list.filter.fields.date')}
        filter={filters.date}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 0] }}
      />

      <FilterItem
        type="string"
        name="invoiceElements"
        label={t('invoice.list.filter.fields.product')}
        filter={filters.invoiceElements}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 1] }}
      />

      {/* Shop */}
      <FilterItem
        type="string"
        name="shopName"
        label={t('invoice.list.filter.fields.shop')}
        filter={filters.shopName}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 0] }}
      />

      {/* Seller */}
      <FilterItem
        type="string"
        name="sellerName"
        label={t('invoice.list.filter.fields.seller')}
        filter={filters.sellerName}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 1] }}
      />

      {/* Customer */}
      <FilterItem
        type="string"
        name="customerName"
        label={t('invoice.list.filter.fields.customer')}
        filter={filters.customerName}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 0] }}
      />

      {/* Customer discount */}
      <FilterItem
        type="number"
        nullable
        name="discountPercentage"
        label={t('invoice.list.filter.fields.customer-discount')}
        filter={filters.discountPercentage}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 1] }}
      />

      {/* Extra discount */}
      <FilterItem
        type="number"
        nullable
        name="extraDiscountPercentage"
        label={t('invoice.list.filter.fields.extra-discount')}
        filter={filters.extraDiscountPercentage}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 0] }}
      />

      {/* Total discounted amount */}
      <FilterItem
        type="number"
        name="totalDiscountedAmount"
        label={t('invoice.list.filter.fields.total-discounted-price')}
        filter={filters.totalDiscountedAmount}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 1] }}
      />

      {/* Redeemed points */}
      <FilterItem
        type="number"
        nullable
        name="redeemedPoints"
        label={t('invoice.list.filter.fields.redeemed-points')}
        filter={filters.redeemedPoints}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 0] }}
      />

      {/* Received Points */}
      <FilterItem
        type="number"
        nullable
        name="receivedPoints"
        label={t('invoice.list.filter.fields.received-points')}
        filter={filters.receivedPoints}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 1] }}
      />

      {/* Payment mode */}
      <FilterItem
        type="list"
        listOptions={paymentModes}
        name="paymentMode"
        label={t('invoice.list.filter.fields.payment-mode')}
        filter={filters.paymentMode}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 0] }}
      />

      {/* Items */}
      <FilterItem
        type="number"
        name="itemCount"
        label={t('invoice.list.filter.fields.item-count')}
        filter={filters.itemCount}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 1] }}
      />
    </FilterForm>
  );
};
