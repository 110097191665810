import { ServiceBase } from '../../../shared';

const BASE_URL = '/stock-manager/attachments';

export const AttachmentService = {
  async uploadAttachments(documentId, files) {
    if (files.length > 0) {
      const formData = new FormData();
      formData.append('documentId', documentId);

      files.forEach(file => {
        formData.append('files', file, file.name);
      });

      return await (await ServiceBase
        .postAsync(`${BASE_URL}/upload`, formData))
        .json();
    }
  }
};
