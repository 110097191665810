import { TableView } from '../../../shared/components';
import { EmpTableV2 } from '../../../shared/components/table/EmpTableV2';
import { useEmployeeList } from './useEmployeeList';

export const EmployeeList = () => {
  const { tableProps } = useEmployeeList();

  return (
    <TableView width="lg">
      <EmpTableV2 {...tableProps} />
    </TableView>
  );
};
