import { useEvChargerMenu } from './Menu';
import { EvChargerRoutes } from './Routes';

const EvChargerModule = {
  name: 'ev-charger',
  abbreviation: 'evc',
  menu: useEvChargerMenu,
  baseRoute: '/ev-charger',
  routes: EvChargerRoutes
};

export default EvChargerModule;
