import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { Editor, FormItem, EmpTextField } from '../../../shared/components';
import { useProductUnitViewer } from './useProductUnitViewer';
import { ProductUnitActionsMenu } from './ProductUnitActionsMenu';

export const ProductUnitViewer = () => {
  const { t } = useTranslation('stockManager');
  const { productUnit } = useProductUnitViewer();

  return (
    <Editor
      width="md"
      headerProps={{
        title: t('product-unit.viewer.title'),
        backLinkTo: '/stock-manager/product-unit',
        actions: productUnit && (
          <ProductUnitActionsMenu
            productUnit={productUnit}
            disabledActions={['datasheet']}
          />
        )
      }}
    >
      <Grid container columns={16} spacing={2}>
        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: productUnit?.id || '',
              label: t('product-unit.viewer.fields.id')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: productUnit?.name || '',
              label: t('product-unit.viewer.fields.name')
            }}
          />
        </FormItem>
      </Grid>
    </Editor>
  );
};
