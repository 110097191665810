import { useTranslation } from 'react-i18next';
import { Editor, EmpDropdown, Form, FormItem } from '../../../shared/components';
import { useEmployeeEditor } from './useEmployeeEditor';

export const EmployeeEditor = () => {
  const { t } = useTranslation('stockManager');

  const {
    employeeId,
    form,
    userDropdownProps,
    shopDropdownProps,
    roleDropdownProps,
    handleSubmit
  } = useEmployeeEditor();

  return (
    <Editor
      width="md"
      headerProps={{
        title: t('employee.editor.title'),
        backLinkTo: '/stock-manager/employee'
      }}
      submitProps={{
        loading: form.formState.isSubmitting,
        onSubmit: handleSubmit,
        children: t(`employee.editor.actions.${ employeeId ? 'update' : 'create' }.label`)
      }}
    >
      <Form onSubmit={handleSubmit} columns={16} spacing={2}>
        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpDropdown
            controllerProps={{
              name: 'userId',
              control: form.control
            }}
            dropdownProps={{
              ...userDropdownProps,
              label: t('employee.editor.fields.user')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpDropdown
            controllerProps={{
              name: 'role',
              control: form.control
            }}
            dropdownProps={{
              ...roleDropdownProps,
              label: t('employee.editor.fields.role')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[5, 6, 5]}>
          <EmpDropdown
            controllerProps={{
              name: 'shopId',
              control: form.control
            }}
            dropdownProps={{
              ...shopDropdownProps,
              label: t('employee.editor.fields.shop')
            }}
          />
        </FormItem>
      </Form>
    </Editor>
  );
};
