import { ActionsMenu } from '../../../shared/components';

export const ManufacturerActionsMenu = ({ manufacturer, onDelete, disabledActions = [] }) => {
  const actions = {};

  if (!disabledActions.includes('datasheet')) {
    actions.datasheet = {
      to: `/stock-manager/manufacturer/${manufacturer.id}`
    };
  }

  if (!disabledActions.includes('editor')) {
    actions.editor = {
      to: `/stock-manager/manufacturer/editor/${manufacturer.id}`
    };
  }

  if (!disabledActions.includes('delete')) {
    actions.delete = {
      url: `/stock-manager/manufacturers/${manufacturer.id}`,
      successNavigationUrl: '/stock-manager/manufacturer',
      callback: onDelete
    };
  }

  return <ActionsMenu actions={actions} />;
};
