import { ServiceBase } from '../../../shared';

const BASE_URL = '/stock-manager/customers';

export const CustomerService = {
  async getCustomersPaginated(parameters) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}?${ServiceBase.makeQueryString(parameters)}`))
      .json();
  },

  async getCustomerById(customerId) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/${customerId}`))
      .json();
  },

  async createCustomer(customer) {
    return await (await ServiceBase
      .postAsync(BASE_URL, customer))
      .json();
  },

  async updateCustomer(customer) {
    return await (await ServiceBase
      .putAsync(`${BASE_URL}/${customer.id}`, customer))
      .json();
  }
};
