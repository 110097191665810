import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useEmpDropdownAdapter } from '../../../shared/components';
import { useApiNotification } from '../../../shared/hooks/useApiNotification';
import { Roles } from '../../website-user/Roles';
import { EmployeeService } from './EmployeeService';

const schema = yup.object().shape({
  userId:
    yup.number().integer().required(),

  role:
    yup.string().required(),

  shopId:
    yup.number().integer().required()
});

export const useEmployeeEditor = () => {
  const { employeeId } = useParams();

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const userIdRef = useRef(null);

  const {
    showUpdateError,
    showUpdateSuccess,
    showCreateError,
    showCreateSuccess
  } = useApiNotification({
    successNavigationUrl: '/stock-manager/employee'
  });

  const [shopsLoading, shopOptions] = useEmpDropdownAdapter({
    url: '/stock-manager/shops',
    sortByLabel: true,
  });

  const getUrl = () => {
    if (employeeId) {
      return `/stock-manager/employees/not-assigned-users?filters=id|equals|${employeeId}`;
    }
    else {
      return '/stock-manager/employees/not-assigned-users';
    }
  };

  const [usersLoading, userOptions] = useEmpDropdownAdapter({
    url: getUrl(),
    sortByLabel: true
  });

  const { handleSubmit, setValue, watch } = form;

  useEffect(() => {
    if (employeeId) {
      EmployeeService.getEmployeeById(employeeId)
        .then(employee => {
          Object.entries(employee)
            .forEach(([key, value]) => {
              if (key === 'shop') {
                const shopId = value?.id || '';
                setValue('shopId', shopId);
              }
              else if (key === 'user') {
                const userId = value?.id || '';
                setValue('userId', userId);
              }
              else if (key === 'role')
              {}
              else {
                setValue(key, value);
              }
            });
        });
    }
  }, [employeeId, setValue]);

  const handleSubmitInternal = async (data) => {
    if (employeeId) {
      await updateEmployee(data);
    }
    else {
      await createEmployee(data);
    }
  };

  const createEmployee = (employee) => {
    return EmployeeService.createEmployee(employee)
      .then(() => {
        showCreateSuccess();
      })
      .catch(error => {
        showCreateError(error);
      });
  };

  const updateEmployee = (employee) => {
    return EmployeeService.updateEmployee(employee)
      .then(() => {
        showUpdateSuccess();
      })
      .catch(error => {
        showUpdateError(error);
      });
  };

  const userDropdownProps = {
    loading: usersLoading,
    options: userOptions
  };

  const shopDropdownProps = {
    loading: shopsLoading,
    options: shopOptions
  };

  const roleDropdownProps = {
    loading: false,
    options: Object.entries(Roles.STM).map(([key, role]) => ({
      value: role.id,
      label: role.name
    }))
  };

  const userId = watch('userId');

  if (userId && userIdRef.current !== userId) {
    const user = userDropdownProps.options
      .find(user => user.value === userId);

    if (user) {
      const module = user.original.modules
        .find(module => module.abbreviation === 'STM');

      if (module) {
        form.setValue('role', module.role);
        userIdRef.current = userId;
      }
    }
  }

  return {
    employeeId,
    form,
    userDropdownProps,
    shopDropdownProps,
    roleDropdownProps,
    handleSubmit: handleSubmit(handleSubmitInternal)
  };
};
