export const getInitialState = () => ({
  pagination: {
    pageNumber: 0,
    pageSize: 20,
    options: [5, 10, 20, 50]
  },
  sorting: {
    column: null,
    direction: null
  },
  filters: null
});

export const TableReducer = (state, action) => {
  const { type, payload } = action;

  if (!(type in reducers)) return state;
  return reducers[type](state, payload);
};

const reducers = {
  onPageNumberChange(state, pageNumber) {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        pageNumber
      }
    };
  },

  onPageSizeChange(state, pageSize) {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        pageSize
      }
    };
  },

  onFiltersReset(state) {
    return {
      ...state,
      filters: null
    };
  },

  onFiltersChange(state, payload) {
    if (payload && Object.keys(payload).length > 0) {
      return {
        ...state,
        filters: payload
      };
    }
    return state;
  },

  onSortingChange(state, payload) {
    if (state.sorting?.column === payload) {
      if (state.sorting?.direction === 'desc') {
        return {
          ...state,
          sorting: {
            column: null,
            direction: null
          }
        };
      }
      else {
        const newState = {
          ...state,
          sorting: {
            column: payload,
            direction: 'desc'
          }
        };

        return newState;
      }
    }
    else {
      const newState = {
        ...state,
        sorting: {
          column: payload,
          direction: 'asc'
        }
      };

      return newState;
    }
  }
};
