import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useWatch } from 'react-hook-form';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';

import { EmpDropdown, EmpNumberField, FormItem } from '../../../shared/components';

const styles = {
  empty: {
    display: 'flex',
    justifyContent: 'center'
  },
  actionColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

export const ProductAmountEditor = ({ form, productDropdownProps, isIncreasingStock }) => {
  const { t } = useTranslation('stockManager');

  const [selectedProducts, setSelectedProducts] = useState([]);

  const { fields, append, remove } = useFieldArray({
    name: 'stockChanges',
    control: form.control
  });

  useEffect(() => {
    setSelectedProducts(
      fields
        .filter(field => field.productId)
        .map(field => field.productId)
    );
  }, [fields]);

  const handleChange = ({ target }) => {
    let newSelectedProducts = [target.value, ...selectedProducts];

    if (target.prevValue) {
      newSelectedProducts = newSelectedProducts
        .filter(productId => productId !== target.prevValue)
    }

    setSelectedProducts(newSelectedProducts);
  };

  if (!fields?.length) {
    return (
      <Grid container columns={16} spacing={2}>
        <FormItem xs={[0, 16, 0]} sx={styles.empty}>
          <Button
            variant="text"
            color="inherit"
            startIcon={<AddIcon />}
            onClick={() => append({})}
          >
            {t('in-out-goods.editor.fields.products.actions.add')}
          </Button>
        </FormItem>
      </Grid>
    );
  }

  return (
    <>
      {fields.map((field, index) => (
        <Grid key={field.id} container columns={16} spacing={2}>
          <AmountItem
            control={form.control}
            index={index}
            append={append}
            remove={remove}
            items={fields.length}
            isIncreasingStock={isIncreasingStock}
            onProductChange={handleChange}
            selectedProducts={selectedProducts}
            productDropdownProps={productDropdownProps}
          />
        </Grid>
      ))}
    </>
  );
};

const AmountItem = ({ control, index, append, remove, items, isIncreasingStock, onProductChange, selectedProducts, productDropdownProps }) => {
  const { t } = useTranslation('stockManager');

  const productId = useWatch({
    name: `stockChanges.${index}.productId`,
    control: control
  });

  const productDropdownPropsCopy = {
    ...productDropdownProps,
    options: productDropdownProps.options.filter(x => {
      const value = selectedProducts.find(y => y === x.value);
      return !value || value === productId ? true : false;
    })
  };

  return (
    <>
      <FormItem xs={[0, 16, 0]} sm={[0, 12, 0]} md={[0, 8, 0]}>
        <EmpDropdown
          controllerProps={{
            name: `stockChanges.${index}.productId`,
            control: control
          }}
          dropdownProps={{
            label: t('in-out-goods.editor.fields.products.fields.product'),
            onChange: onProductChange,
            ...productDropdownPropsCopy
          }}
        />
      </FormItem>

      <FormItem xs={[0, 1, 0]} sx={{ alignSelf: 'center' }}>
        <Box alignSelf="center" mx={1}>
          <Typography
            fontSize={26}
            fontWeight="bold"
            color={isIncreasingStock ? '#1b5e20' : '#b71c1c'}
          >
            {!isIncreasingStock ? '-' : '+'}
          </Typography>
        </Box>
      </FormItem>

      <FormItem xs={[0, 8, 0]} sm={[0, 10, 0]} md={[0, 4, 0]}>
        <EmpNumberField
          controllerProps={{
            name: `stockChanges.${index}.amount`,
            control: control
          }}
          numberFieldProps={{
            label: t('in-out-goods.editor.fields.products.fields.amount')
          }}
        />
      </FormItem>

      <FormItem xs={[0, 16, 0]} sm={[0, 4, 0]} md={[0, 3, 0]} sx={styles.actionColumn}>
        {(index === items - 1 && productDropdownProps.options.length > items) && (
          <IconButton onClick={() => append({})}>
            <AddIcon />
          </IconButton>
        )}
        <IconButton color="error" onClick={() => remove(index, productId)}>
          <DeleteIcon />
        </IconButton>
      </FormItem>
    </>
  );
};
