import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { CoreFormatters } from '../../../core';
import { EmpModal, EmpTextField } from '../../../shared/components';
import { EVChargerFormatters } from '../_common/Formatters';

export const ChargingDetailModal = ({ open, onClose, guest, chargingSessions }) => {
  const { t } = useTranslation('evCharger');

  return (
    <EmpModal
      open={open}
      fullWidth
      maxWidth="md"
      titleIcon={<DescriptionIcon />}
      localization={{
        title: `${t('guest-charging.detail-modal.title')} - ${guest?.name || ''}`
      }}
      onClose={onClose}
      actions={
        <Button color="error" onClick={onClose}>
          {t('guest-charging.detail-modal.actions.close')}
        </Button>
      }
    >
      <Box>
        <Typography variant="h6" sx={{ marginBottom: 4 }}>
          {`${t('guest-charging.detail-modal.chargings-section')} (${chargingSessions?.length || 0}x)`}
        </Typography>

        <ChargingDetailRows data={chargingSessions || []} />

        <Box sx={{ marginTop: 3 }}>
          <ChargingSummaryRow data={chargingSessions || []} />
        </Box>
      </Box>
    </EmpModal>
  )
};

const ChargingDetailRows = ({ data }) => {
  const { t } = useTranslation('evCharger');

  return (
    <Grid container spacing={2}>
      {data.map(x => (
        <>
          <Grid item xs={2}>
            <EmpTextField
              textFieldProps={{
                label: t('guest-charging.detail-modal.fields.date'),
                value:  CoreFormatters.yearAndMonthAndDayUnix(x.payload.s_start),
                disabled: true
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <EmpTextField
              textFieldProps={{
                label: t('guest-charging.detail-modal.fields.started-end'),
                value: `${CoreFormatters.hourAndMinuteUnix(x.payload.s_start)} - ${x.payload.s_end ? CoreFormatters.hourAndMinuteUnix(x.payload.s_end) : ''}`,
                disabled: true
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <EmpTextField
              textFieldProps={{
                label: t('guest-charging.detail-modal.fields.duration'),
                value: x.payload?.s_end ? `${CoreFormatters.durationHourAndMinuteUnix(x.payload.s_start, x.payload.s_end)}` : '-',
                disabled: true
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <EmpTextField
              textFieldProps={{
                label: t('guest-charging.detail-modal.fields.unit-price'),
                value: `${x.unitPrice} ${t('currency')}`,
                disabled: true
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <EmpTextField
              textFieldProps={{
                label: t('guest-charging.detail-modal.fields.energy'),
                value: EVChargerFormatters.energy(x.payload.chg_energy),
                disabled: true
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <EmpTextField
              textFieldProps={{
                label: t('guest-charging.detail-modal.fields.cost'),
                value: EVChargerFormatters.cost(x.unitPrice * x.payload.chg_energy),
                disabled: true
              }}
            />
          </Grid>
        </>
      ))}
    </Grid>
  );
};

const ChargingSummaryRow = ({ data }) => {
  const { t } = useTranslation('evCharger');

  let totalEnergy = 0;
  let totalPrice = 0;

  (data || []).forEach((chargingSession) => {
    totalEnergy += Math.round(chargingSession.payload.chg_energy * 100) / 100;
    totalPrice += chargingSession.unitPrice * Math.round(chargingSession.payload.chg_energy * 100) / 100;
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={8} />
      <Grid item xs={2}>
        <EmpTextField
          textFieldProps={{
            label: t('guest-charging.detail-modal.fields.total-energy'),
            value: EVChargerFormatters.energy(totalEnergy),
            disabled: true,
            InputProps: {
              sx: {
                fontWeight: 'bold'
              }
            }
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <EmpTextField
          textFieldProps={{
            label: t('guest-charging.detail-modal.fields.total-cost'),
            value: EVChargerFormatters.cost(totalPrice),
            disabled: true,
            InputProps: {
              sx: {
                fontWeight: 'bold'
              }
            }
          }}
        />
      </Grid>
    </Grid>
  );
};
