import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useApiNotification } from '../../../shared/hooks/useApiNotification';
import { ProductCategoryService } from './ProductCategoryService';

const schema = yup.object().shape({
  name:
    yup.string().required().trim().max(32)
});

export const useProductCategoryEditor = () => {
  const { productCategoryId } = useParams();

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const {
    showUpdateError,
    showUpdateSuccess,
    showCreateError,
    showCreateSuccess
  } = useApiNotification({
    successNavigationUrl: '/stock-manager/product-category',
    translation: {
      namespace: 'stockManager',
      fragment: 'product-category.actions'
    }
  });

  const { handleSubmit, setValue } = form;

  useEffect(() => {
    if (productCategoryId) {
      ProductCategoryService.getProductCategoryById(productCategoryId)
        .then(productCategory => {
          Object.entries(productCategory)
            .forEach(([key, value]) => setValue(key, value));
        });
    }
  }, [setValue, productCategoryId]);

  const handleSubmitInternal = async (data) => {
    if (productCategoryId) {
      await updateProductCategory(data);
    }
    else {
      await createProductCategory(data);
    }
  };

  const createProductCategory = async (productCategory) => {
    return ProductCategoryService.createProductCategory(productCategory)
      .then(() => {
        showCreateSuccess();
      })
      .catch(error => {
        showCreateError(error);
      });
  };

  const updateProductCategory = async (productCategory) => {
    return ProductCategoryService.updateProductCategory(productCategory)
      .then(() => {
        showUpdateSuccess();
      })
      .catch(error => {
        showUpdateError(error);
      });
  };

  return {
    productCategoryId,
    form,
    handleSubmit: handleSubmit(handleSubmitInternal)
  };
};
