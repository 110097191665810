import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterForm, FilterItem } from '../../../shared/components';
import { StockService } from './StockService';

const defaultValues = {
  warehouseId: {
    operator: 'equals',
    value: null
  },
  productId: {
    operator: 'equals',
    value: null
  },
  date: {
    operator: 'range',
    value: null
  }
};

export const StockChangesFilterForm = ({ filters, onFilterChange }) => {
  const { t } = useTranslation('stockManager');

  filters = {
    ...defaultValues,
    ...filters
  };

  const handleValueChange = ({ target: { name, value } }) => {
    onFilterChange(name, { ...filters[name], value: value });
  };

  const [warehousesLoading, setWarehousesLoading] = useState(false);
  const [warehouses, setWarehouses] = useState([]);

  const [productsLoading, setProductsLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setWarehousesLoading(true);
    StockService.getWarehouses()
      .then(warehouses => {
        setWarehouses(warehouses.data.map(warehouse => ({
          value: warehouse.id,
          label: warehouse.name
        })));
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        setWarehousesLoading(false);
      });

    setProductsLoading(true);
    StockService.getProducts()
      .then(products => {
        setProducts(products.data.map(product => ({
          value: product.id,
          label: product.name
        })));
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        setProductsLoading(false);
      });
  }, []);

  const parseDateFilter = (part) => {
    let parsed = { value: null };

    if (filters.date?.value) {
      const [min, max] = filters.date.value.split('<>');
      parsed = part === 'min' ? { value: new Date(min) } : { value: new Date(max) };
    }

    return parsed;
  };

  const handleFromDateChange = ({ target }) => {
    let min = '0001.01.01';
    let max = '9999.12.31';

    if (target.value) {
      min = target.value.toISODate().replaceAll('-', '.');;
    }

    if (filters.date.value) {
      let parts = filters.date.value.split('-');
      if (parts[1]) max = parts[1];
    }

    onFilterChange('date', { ...filters['date'], value: `${min}<>${max}` });
  };

  const handleToDateChange = ({ target }) => {
    let min = '0001.01.01';
    let max = '9999.12.31';

    if (target.value) {
      max = target.value.toISODate().replaceAll('-', '.');
    }

    if (filters.date.value) {
      let parts = filters.date.value.split('<>');
      if (parts[0]) min = parts[0];
    }

    onFilterChange('date', { ...filters['date'], value: `${min}<>${max}` });
  };

  const fromDate = parseDateFilter('min');
  const toDate = parseDateFilter('max');

  const isDefaultFromDate = fromDate?.value?.getTime() === new Date('0001.01.01').getTime();
  const isDefaultToDate = toDate?.value?.getTime() === new Date('9999.12.31').getTime();

  return (
    <FilterForm columns={18}>
      {/* Warehouse */}
      <FilterItem
        type="list"
        listLoading={warehousesLoading}
        listOptions={warehouses}
        name="warehouseId"
        label={t('stock-movement.list.filter.fields.warehouse')}
        filter={filters.warehouseId}
        onValueChange={handleValueChange}
        valueContainerProps={{ xs: [1, 8, 0] }}
      />

      {/* Product */}
      <FilterItem
        type="list"
        listLoading={productsLoading}
        listOptions={products}
        name="productId"
        label={t('stock-movement.list.filter.fields.product')}
        filter={filters.productId}
        onValueChange={handleValueChange}
        valueContainerProps={{ xs: [0, 8, 1] }}
      />

      {/* From date */}
      <FilterItem
        type="date"
        name="fromDate"
        label={t('stock-movement.list.filter.fields.from-date')}
        filter={!isDefaultFromDate ? fromDate : null}
        onValueChange={handleFromDateChange}
        valueContainerProps={{ xs: [1, 8, 0] }}
      />

      {/* To date */}
      <FilterItem
        type="date"
        name="toDate"
        label={t('stock-movement.list.filter.fields.to-date')}
        filter={!isDefaultToDate ? toDate : null}
        onValueChange={handleToDateChange}
        valueContainerProps={{ xs: [0, 8, 1] }}
      />
    </FilterForm>
  );
};
