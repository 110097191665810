import { Box } from '@mui/material';
import { EmpDropdown, TableView } from '../../../shared/components';
import { EmpTableV2 } from '../../../shared/components/table/EmpTableV2';
import { useStockCurrentState } from './useStockCurrentState';

export const StockCurrentState = () => {
  const { tableProps, warehouseDropdownProps } = useStockCurrentState();

  return (
    <TableView width="lg" beforeTable={<CurrentStockTableToolBar warehouseDropdownProps={warehouseDropdownProps} />}>
      <EmpTableV2 {...tableProps} />
    </TableView>
  );
};

const CurrentStockTableToolBar = ({ warehouseDropdownProps }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems={{ md: 'flex-end' }}>
      <Box sx={{ padding: { xs: '0 16px', md: 0 } }}>
        <EmpDropdown
          dropdownProps={{
            sx: { width: { xs: '100%', md: '250px' } },
            ...warehouseDropdownProps
          }}
        />
      </Box>
    </Box>
  );
};
