import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

export const useQueryParam = ({ name, initialValue = null }) => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  const params = new URLSearchParams(search);
  const actualValue = params.has(name) ? params.get(name) : initialValue;

  const [value, setValueInternal] = useState(actualValue);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const queryValue = params.get(name);

    if (!queryValue) {
      if (value !== null && value !== undefined) {
        params.set(name, value);
        navigate(`${pathname}?${params.toString()}`, { replace: true });
      }
    }
  }, [navigate, name, value, pathname, search]);

  const setValue = useCallback((value) => {
    setValueInternal(value);

    const params = new URLSearchParams(search);
    params.set(name, value);

    navigate(`${pathname}?${params.toString()}`, { replace: true });
  }, [navigate, name, pathname, search]);

  const clearValue = useCallback(() => {
    setValueInternal(null);

    const params = new URLSearchParams(search);
    params.delete(name);

    navigate(`${pathname}?${params.toString()}`, { replace: true });
  }, [navigate, name, pathname, search]);

  return {
    value,
    setValue,
    clearValue
  };
};
