import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Link, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { AccountCircle as AccountCircleIcon } from '@mui/icons-material';

import { EmpStyles } from '../../../shared/styles/EmpStyles';
import { useModules } from '../../../shared/hooks/useModules';

export const UserProfileMenuItem = () => {
  const { t } = useTranslation('core');
  const { currentModule } = useModules();

  return (
    <Link to="/user-profile" component={RouterLink}>
      <ListItem button sx={currentModule === 'user-profile' ? EmpStyles.menu.active : {}}>
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary={t('side-menu.user-profile.base')} />
      </ListItem>
    </Link>
  );
};
