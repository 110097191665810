import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { List as ListIcon } from '@mui/icons-material';
import { useTableV2 } from '../../../shared/components/table/hooks/useTableV2';
import { ProductActionsMenu } from './ProductActionsMenu';
import { ProductFilterForm } from './ProductFilterForm';
import { ProductService } from './ProductService';

const columnsFactory = (t) => [{
  header: t('product.list.columns.id'),
  field: 'id',
  width: '5%'
}, {
  header: t('product.list.columns.name'),
  field: 'name',
  width: '20%'
}, {
  header: t('product.list.columns.sku'),
  field: 'sku',
  width: '20%'
}, {
  header: t('product.list.columns.ean'),
  field: 'ean',
  render: (row) => (row.ean && `${row.ean.substring(0, 1)} ${row.ean.substring(1, 7)} ${row.ean.substring(7, 14)}`) || '-',
  width: '12.5%'
}, {
  header: t('product.list.columns.manufacturer'),
  render: (row) => row.manufacturer.name,
  width: '17.5%',
  sortable: false
}, {
  header: t('product.list.columns.product-category'),
  render: (row) => row.productCategory.name,
  width: '10%',
  sortable: false
}, {
  header: t('product.list.columns.product-unit'),
  render: (row) => row.productUnit.name,
  width: '10%',
  sortable: false
}, {
  width: '5%',
  sortable: false,
  render: (row, { refresh }) => (
    <ProductActionsMenu
      product={row}
      onDelete={refresh}
    />
  )
}];

export const useProductList = () => {
  const { t } = useTranslation('stockManager');
  const columns = useMemo(() => columnsFactory(t), [t]);

  const tableProps = useTableV2({
    columns,
    title: t('product.list.title'),
    icon: <ListIcon />,
    dataSource: ProductService.getProductsPaginated,
    components: {
      toolbar: {
        createButtonProps: {
          to: '/stock-manager/product/editor',
          children: t('product.actions.create.label')
        },
        filterModalProps: {
          maxWidth: 'md',
          title: t('product.list.filter.title'),
          children: ProductFilterForm
        }
      }
    }
  });

  return {
    tableProps
  };
};
