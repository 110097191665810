import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { TextField } from '@mui/material';

//************************
// EmpTextField
//************************

const styles = {
  disabled: {
    '& label.Mui-disabled': theme => ({
      color: theme.palette.text.primary
    }),
    '& div > input.Mui-disabled': theme => ({
      color: theme.palette.text.primary,
      WebkitTextFillColor: theme.palette.text.primary
    }),
    '& div.Mui-disabled:before': {
      borderBottomStyle: 'solid'
    }
  }
};

export const EmpTextField = (props) => {
  const { controllerProps, textFieldProps } = props;

  if (controllerProps) {
    return <EmpTextFieldForm {...props} />;
  }
  else {
    return <EmpTextFieldRegular textFieldProps={textFieldProps} />;
  }
};

const EmpTextFieldRegular = ({ textFieldProps }) => {
  const { sx = {}, disabled = false } = textFieldProps;
  const mergedSx = !disabled ? sx : { ...sx, ...styles.disabled };

  return (
    <TextField
      {...textFieldProps}
      sx={mergedSx}
      value={textFieldProps?.value || ''}
      helperText={textFieldProps.helperText || ' '}
    />
  );
};

const EmpTextFieldForm = ({ controllerProps, textFieldProps }) => {
  const { sx = {}, disabled = false } = textFieldProps;
  const mergedSx = !disabled ? sx : { ...sx, ...styles.disabled };

  return (
    <Controller
      {...controllerProps}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          {...textFieldProps}
          sx={mergedSx}
          value={field.value || ''}
          error={fieldState.invalid}
          helperText={fieldState.error?.message || ' '}
        />
      )}
    />
  );
};

//************************
// EmpMaskedTextField
//************************

const maskChar = '\u2007';

export const EmpMaskedTextField = (props) => {
  const { controllerProps, textFieldProps, maskProps } = props;

  if (controllerProps) {
    return <EmpMaskedTextFieldForm {...props} />
  }
  else {
    return <EmpMaskedTextFieldRegular textFieldProps={textFieldProps} maskProps={maskProps} />
  }
};

const EmpMaskedTextFieldRegular = (props) => {
  const { textFieldProps = {}, maskProps } = props;

  const { disabled = false } = maskProps;
  const { helperText, sx } = textFieldProps;

  const mergedSx = !disabled ? sx : { ...sx, ...styles.disabled };

  return (
    <InputMask maskPlaceholder={maskChar} {...maskProps}>
      <TextField
        {...textFieldProps}
        sx={mergedSx}
        helperText={helperText || ' '}
      />
    </InputMask>
  );
};

const EmpMaskedTextFieldForm = (props) => {
  const { controllerProps, textFieldProps, maskProps } = props;

  const { disabled = false } = maskProps;
  const { sx } = textFieldProps;

  const mergedSx = !disabled ? sx : { ...sx, ...styles.disabled };

  return (
    <Controller
      {...controllerProps}
      render={({ field, fieldState }) => (
        <InputMask
          maskPlaceholder={maskChar}
          {...maskProps}
          {...field}
          value={field.value || ''}
        >
          <TextField
            {...textFieldProps}
            sx={mergedSx}
            error={fieldState.invalid}
            helperText={fieldState.error?.message || ' '}
          />
        </InputMask>
      )}
    />
  );
};
