import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { IDENTITY_CONFIG } from './AuthConstants';
import { AuthHelper } from './AuthHelper';
import { TenantService } from '../../shared/helpers/TenantService';
import { RedirectHelper } from '../../shared/helpers/RedirectHelper';
import { useApiNotification } from '../../shared';

const userManager = new UserManager({
  ...IDENTITY_CONFIG,
  userStore: new WebStorageStateStore({ store: localStorage })
});

const AuthContext = createContext({});

export const useEmpAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const { t } = useTranslation('core');
  const navigate = useNavigate();

  const [user, setUser] = useState(AuthHelper.getUser());
  const [tenant, setTenantInternal] = useState(AuthHelper.getTenant());
  const [tenants, setTenants] = useState([]);

  const { showApiError } = useApiNotification();

  const setTenant = useCallback((tenant) => {
    setTenantInternal(tenant);

    if (tenant) {
      localStorage.setItem('tenant', JSON.stringify(tenant));
    }
    else {
      localStorage.removeItem('tenant');
    }
  }, []);

  const fetchTenants = useCallback(() => {
    return TenantService.getTenants()
      .then(tenants => {
        setTenants(tenants);
        return tenants;
      })
      .catch(error => {
        setTenants([]);
        showApiError(null, error);
      });
  }, [showApiError]);

  const signin = useCallback(() => {
    return userManager.signinRedirect()
      .catch(error => {
        showApiError(t('login.error'))
      });
  }, [t, showApiError]);

  const signinCallback = useCallback(() => {
    userManager.signinCallback()
      .then((user) => {
        fetchTenants()
          .then(tenants => {
            if (tenants?.length) setTenant(tenants[0]);
            navigate(RedirectHelper.loadRedirectUrl(user));
          });
      });
  }, [navigate, fetchTenants, setTenant]);

  const signout = useCallback(() => {
    userManager.signoutRedirect();
  }, []);

  const signoutCallback = useCallback(() => {
    userManager.signoutCallback()
      .then(() => {
        setUser(null);
        setTenant(null);
        setTenants([]);
        navigate('/login');
      });
  }, [navigate, setTenant]);

  useEffect(() => {
    const handleUserLoaded = (user) => {
      setUser(user);
    };

    userManager.events.addUserLoaded(handleUserLoaded);

    return () => userManager.events.removeUserLoaded(handleUserLoaded);
  }, []);

  // Ez akkor fut le ha oldal újratöltés történt
  // (pl.: F5-el vagy bezárom a böngészőt majd újra megnyitom)
  useEffect(() => {
    const user = AuthHelper.getUser();

    if (user && !user.expired) {
      fetchTenants();
    }
  }, [fetchTenants]);

  const value = {
    user,
    tenant,
    tenants,
    setTenant,
    signin,
    signinCallback,
    signout,
    signoutCallback
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
