import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FilterHelper } from '../../../helpers/FilterHelper';
import { SortingHelper } from '../../../helpers/SortingHelper';
import { getInitialState, TableReducer } from './TableReducer';

export const useTableV2 = ({ dataSource, initialState = null, ...rest }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const canceled = useRef(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [state, dispatch] = useReducer(TableReducer, null, () => {
    const state = { ...getInitialState(), ...initialState };

    if (searchParams.has('pageNumber')) {
      state.pagination.pageNumber = parseInt(searchParams.get('pageNumber'));
    }

    if (searchParams.has('pageSize')) {
      state.pagination.pageSize = parseInt(searchParams.get('pageSize'));
    }

    if (searchParams.has('sorting')) {
      state.sorting = SortingHelper.parse(searchParams.get('sorting'));
    }

    if (searchParams.has('filters')) {
      state.filters = FilterHelper.parse(searchParams.get('filters'));
    }

    return state;
  });

  const onPageNumberChange = (pageNumber) => {
    dispatch({ type: 'onPageNumberChange', payload: pageNumber });
  };

  const onPageSizeChange = (pageSize) => {
    dispatch({ type: 'onPageSizeChange', payload: pageSize });
  };

  const onSortingChange = (column) => {
    dispatch({ type: 'onSortingChange', payload: column });
  };

  const onFiltersChange = (filters) => {
    dispatch({ type: 'onFiltersChange', payload: filters });
  };

  const onFiltersReset = (column) => {
    dispatch({ type: 'onFiltersReset' });
  };

  const loadData = useCallback(() => {
    setLoading(true);

    const parameters = {
      pagination: state.pagination,
      sorting: state.sorting,
      filters: state.filters
    };

    dataSource(parameters)
      .then(data => {
        if (!canceled.current) {
          setData(data);
        }
      })
      .finally(() => {
        if (!canceled.current) {
          setLoading(false);
        }
      });
  }, [dataSource, state]);

  useEffect(() => {
    canceled.current = false;
    return () => canceled.current = true;
  }, []);

  useEffect(() => {
    setSearchParams(() => {
      const newParams = new URLSearchParams();

      newParams.append('pageNumber', state.pagination.pageNumber);
      newParams.append('pageSize', state.pagination.pageSize);

      if (state.sorting?.column && state.sorting?.direction) {
        newParams.append('sorting', SortingHelper.build(state.sorting));
      }

      if (state?.filters && Object.keys(state.filters).length) {
        const filtersQueryParam = FilterHelper.build(state.filters);

        if (filtersQueryParam) {
          newParams.append('filters', filtersQueryParam);
        }
      }

      return newParams;
    });
  }, [state, setSearchParams]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return {
    state: {
      loading,
      rows: data?.data || [],
      totalCount: data?.totalCount || 0
    },

    refresh: loadData,
    ...rest,

    pagination: {
      state: state.pagination,
      onPageNumberChange,
      onPageSizeChange
    },

    sorting: {
      state: state.sorting,
      onSortingChange
    },

    filters: {
      state: state.filters,
      onFiltersChange,
      onFiltersReset
    }
  };
};
