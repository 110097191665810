import { ActionsMenu } from '../../shared/components';

export const UserActionsMenu = ({ user, onDelete, disabledActions = [] }) => {
  const actions = {};

  if (!disabledActions.includes('datasheet')) {
    actions.datasheet = {
      to: `/website-user/viewer/${user.id}`
    };
  }

  if (!disabledActions.includes('editor')) {
    actions.editor = {
      to: `/website-user/editor/${user.id}`
    };
  }

  if (!disabledActions.includes('delete')) {
    actions.delete = {
      url: `/core/users/${user.id}`,
      successNavigationUrl: '/website-user',
      callback: onDelete
    };
  }

  return <ActionsMenu actions={actions} />;
};
