import i18n from 'i18next';
import { DateTime } from 'luxon';

const date = (date, format) => {
  if (date instanceof Date) {
    date = date.toISOString();
  }

  return DateTime
    .fromISO(date)
    .setLocale(i18n.language)
    .toLocaleString(format);
};

const yearAndMonth = (date) => {
  if (date instanceof Date) {
    date = date.toISOString();
  }

  return DateTime
    .fromISO(date)
    .setLocale(i18n.language)
    .toLocaleString({ year: 'numeric', month: 'long' });
};

const yearAndMonthUnix = (unixTimestamp) => {
  return yearAndMonth(new Date(unixTimestamp * 1000));
};

const yearAndMonthAndDay = (date) => {
  if (date instanceof Date) {
    date = date.toISOString();
  }

  return DateTime
    .fromISO(date)
    .setLocale(i18n.language)
    .toLocaleString({ year: 'numeric', month: '2-digit', day: '2-digit' });
};

const yearAndMonthAndDayUnix = (unixTimestamp) => {
  return yearAndMonthAndDay(new Date(unixTimestamp * 1000));
};

const hourAndMinute = (date) => {
  if (date instanceof Date) {
    date = date.toISOString();
  }

  return DateTime
    .fromISO(date)
    .setLocale(i18n.language)
    .toLocaleString({ hour: '2-digit', minute: '2-digit' });
};

const hourAndMinuteUnix = (unixTimestamp) => {
  return hourAndMinute(new Date(unixTimestamp * 1000));
};

const hourAndMinuteAndSecond = (date) => {
  if (date instanceof Date) {
    date = date.toISOString();
  }

  return DateTime
    .fromISO(date)
    .setLocale(i18n.language)
    .toLocaleString({ hour: '2-digit', minute: '2-digit', second: '2-digit' });
};

const hourAndMinuteAndSecondUnix = (unixTimestamp) => {
  return hourAndMinuteAndSecond(new Date(unixTimestamp * 1000));
};

const duration = (startDate, endDate) => {
  let diff = endDate - startDate;

  const diffHours = Math.trunc(diff / 3_600_000);
  if (diffHours > 0) diff -= (diffHours * 3_600_000);

  const diffMinutes = Math.trunc(diff / 60_000);
  if (diffMinutes > 0) diff -= (diffMinutes * 60_000);

  const diffSeconds = Math.trunc(diff / 1_000);

  let formatted = '';

  if (diffHours > 0) {
    formatted += (diffHours < 10 ? `0${diffHours}` : diffHours) + ':';
  }

  formatted += (diffMinutes < 10 ? `0${diffMinutes}` : diffMinutes) + ':';
  formatted += (diffSeconds < 10 ? `0${diffSeconds}` : diffSeconds);

  return formatted || '-';
};

const durationUnix = (startUnixTimestamp, endUnixTimestamp) => {
  return duration(new Date(startUnixTimestamp * 1000), new Date(endUnixTimestamp * 1000))
};

const durationHourAndMinute = (startDate, endDate) => {
  let diff = endDate - startDate;

  const diffHours = Math.trunc(diff / 3_600_000);
  if (diffHours > 0) diff -= (diffHours * 3_600_000);

  const diffMinutes = Math.trunc(diff / 60_000);
  if (diffMinutes > 0) diff -= (diffMinutes * 60_000);

  let formatted = '';

  formatted += (diffHours < 10 ? `0${diffHours}` : diffHours) + ':';
  formatted += (diffMinutes < 10 ? `0${diffMinutes}` : diffMinutes);

  return formatted || '-';
};

const durationHourAndMinuteUnix = (startUnixTimestamp, endUnixTimestamp) => {
  return durationHourAndMinute(new Date(startUnixTimestamp * 1000), new Date(endUnixTimestamp * 1000));
};

export const CoreFormatters = {
  date,
  yearAndMonth,
  yearAndMonthUnix,
  yearAndMonthAndDay,
  yearAndMonthAndDayUnix,
  hourAndMinute,
  hourAndMinuteUnix,
  hourAndMinuteAndSecond,
  hourAndMinuteAndSecondUnix,
  duration,
  durationUnix,
  durationHourAndMinute,
  durationHourAndMinuteUnix
};
