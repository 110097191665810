import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

export const FilterActions = ({ onClose, onReset, onFilter }) => {
  const { t } = useTranslation('core');

  return (
    <>
      <Button color="error" onClick={onClose}>
        {t('filter.actions.close')}
      </Button>
      <Button onClick={onReset}>
        {t('filter.actions.reset')}
      </Button>
      <Button onClick={onFilter}>
        {t('filter.actions.filter')}
      </Button>
    </>
  );
};
