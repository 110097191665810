import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useEmpDropdownAdapter } from '../../../shared/components';
import { useApiNotification } from '../../../shared/hooks/useApiNotification';
import { CustomerService } from './CustomerService';

const schema = yup.object().shape({
  name:
    yup.string().required().trim().max(50),

  shopId:
    yup.number().integer().nullable().emptyNumberToNull(),

  collectedPoints:
    yup.number().integer().min(0).nullable().emptyNumberToNull(),

  discountPercentage:
    yup.number().integer().min(0).nullable().emptyNumberToNull(),

  email:
    yup.string().nullable().trim().max(100).emptyStringToNull(),

  taxNumber:
    yup.string().nullable().trim().max(50).emptyStringToNull(),

  companyName:
    yup.string().nullable().trim().max(50).emptyStringToNull(),

  phone:
    yup.string().nullable().trim().max(30).emptyStringToNull(),

  zip:
    yup.string().nullable().trim().max(20).emptyStringToNull(),

  city:
    yup.string().nullable().trim().max(50).emptyStringToNull(),

  address:
    yup.string().nullable().trim().max(200).emptyStringToNull()
});

export const useCustomerEditor = () => {
  const { customerId } = useParams();

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const {
    showCreateError,
    showCreateSuccess,
    showUpdateError,
    showUpdateSuccess
  } = useApiNotification({
    successNavigationUrl: '/stock-manager/customer',
    translation: {
      namespace: 'stockManager',
      fragment: 'customer.actions'
    }
  });

  const { handleSubmit, setValue } = form;

  useEffect(() => {
    if (customerId) {
      CustomerService.getCustomerById(customerId)
        .then(customer => {
          Object.entries(customer)
            .forEach(([key, value]) => {
              if (key === 'shop') {
                setValue('shopId', value?.id);
              }
              else {
                setValue(key, value);
              }
            });
        });
    }
  }, [customerId, setValue]);

  const [shopsLoading, shopOptions] = useEmpDropdownAdapter({
    url: '/stock-manager/shops'
  });

  const handleSubmitInternal = async (data) => {
    if (customerId) {
      await updateCustomer(data);
    }
    else {
      await createCustomer(data);
    }
  };

  const createCustomer = (customer) => {
    return CustomerService.createCustomer(customer)
      .then(() => {
        showCreateSuccess();
      })
      .catch(error => {
        showCreateError(error);
      });
  };

  const updateCustomer = (customer) => {
    return CustomerService.updateCustomer(customer)
      .then(() => {
        showUpdateSuccess();
      })
      .catch(error => {
        showUpdateError(error);
      });
  };

  const shopDropdownProps = {
    loading: shopsLoading,
    options: shopOptions
  };

  return {
    customerId,
    form,
    shopDropdownProps,
    handleSubmit: handleSubmit(handleSubmitInternal)
  };
};
