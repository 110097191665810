import { useState } from 'react';
import { saleActions } from '../saleActions';
import { useSaleContext } from '../context/SaleContext';
import { SaleService } from '../SaleService';

export const useCustomerSelector = () => {
  const { dispatch, shop } = useSaleContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(' ');

  const [cardNumber, setCardNumber] = useState('');
  const [customerName, setCustomerName] = useState('');

  const queryCustomer = () => {
    if (cardNumber) {
      setLoading(true);

      SaleService.getCustomerByCardNumber(cardNumber, shop)
        .then(customer => {
          setError(' ');

          setCardNumber('');
          setCustomerName('');

          dispatch(saleActions.setCustomer(customer));
        })
        .catch(error => {
          setError(error.errors[0].message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    else if (customerName) {
      setLoading(true);

      SaleService.getCustomerByName(customerName, shop)
        .then(customer => {
          setError(' ');

          setCardNumber('');
          setCustomerName('');

          dispatch(saleActions.setCustomer(customer));
        })
        .catch(error => {
          setError(error.errors[0].message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const setAnonymCustomer = () => {
    setError(' ');

    setCardNumber('');
    setCustomerName('');

    dispatch(saleActions.setCustomer(null));
  };

  return {
    loading,
    error,
    queryCustomer,
    setAnonymCustomer,

    cardNumber,
    setCardNumber,
    customerName,
    setCustomerName
  };
};
