import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';
import FunctionsIcon from '@mui/icons-material/Functions';
import { formatters } from '../utils/Formatters';
import { EmpLoadingIndicator } from '../../../shared/components';
import { intervalRenderer } from './DailyIncome';

const priceFormatterFactory = (currency) => {
  return (value) => `${formatters.price(value || 0)} ${currency}`;
};

export const SummaryPanel = ({ loading, dailyIncome, interval }) => {
  const { t } = useTranslation('stockManager');

  return (
    <Card>
      <CardHeader
        avatar={<FunctionsIcon />}
        title={t('daily-income.summary-panel.title')}
        subheader={intervalRenderer(interval)}
        sx={{ backgroundColor: 'secondary.main', padding: { xs: 1, md: 2 } }}
      />
      <Divider />
      <CardContent>
        <PanelContent loading={loading} dailyIncome={dailyIncome} />
      </CardContent>
    </Card>
  );
};

const PanelContent = ({ loading, dailyIncome }) => {
  const { t } = useTranslation('stockManager');
  const priceFormatter = priceFormatterFactory(dailyIncome?.shop?.currency?.shortName || '');

  if (loading) {
    return (
      <Box minHeight="300px" display="flex" alignItems="center" justifyContent="center">
        <EmpLoadingIndicator />
      </Box>
    );
  }

  if (!dailyIncome || !dailyIncome?.totalPurchases) {
    return (
      <Box minHeight="300px" display="flex" alignItems="center" justifyContent="center">
        <Typography align="center">
          {t('daily-income.purchases-panel.empty')}
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      <SummaryRow
        label={t('daily-income.summary-panel.fields.number-of-purchases')}
        value={dailyIncome?.totalPurchases}
      />

      <SummaryRow
        label={t('daily-income.summary-panel.fields.total-value')}
        value={priceFormatter(dailyIncome?.totalValue)}
      />

      <SummaryRow
        offset
        label={t('daily-income.summary-panel.fields.redeemed-points')}
        value={dailyIncome?.totalRedeemedPoints}
      />

      <SummaryRow
        label={t('daily-income.summary-panel.fields.payable')}
        value={priceFormatter(dailyIncome?.totalPaid)}
      />

      <SummaryRow
        offset
        label={t('daily-income.summary-panel.fields.cash')}
        value={priceFormatter(dailyIncome?.totalCash)}
      />

      <SummaryRow
        offset
        label={t('daily-income.summary-panel.fields.card')}
        value={priceFormatter(dailyIncome?.totalCard)}
      />

      <SummaryRow
        offset
        label={t('daily-income.summary-panel.fields.transfer')}
        value={priceFormatter(dailyIncome?.totalTransfer)}
      />
    </Grid>
  );
};

const SummaryRow = ({ offset = false, label, value }) => {
  return (
    <>
      {offset ? <Grid item xs={1} /> : <></>}
      <Grid item xs={offset ? 6 : 7}>
        <Typography>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography sx={{ fontWeight: 'bold' }} align="right">
          {value || 0}
        </Typography>
      </Grid>
    </>
  );
};
