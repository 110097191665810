import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { Box, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import SellIcon from '@mui/icons-material/Sell';
import { formatters } from '../utils/Formatters';
import { EmpLoadingIndicator } from '../../../shared/components';
import { EmpStyles } from '../../../shared';
import { intervalRenderer } from './DailyIncome';

const styles = {
  content: {
    minHeight: '500px',
    maxHeight: '500px',
  },
  scrollbar: {
    overflowY: 'auto',
    ...EmpStyles.scrollbar
  }
};

export const PurchasesPanel = ({ loading, purchases, interval }) => {
  const { t } = useTranslation('stockManager');
  const scrollbarStyle = !purchases?.length || loading ? {} : styles.scrollbar;

  return (
    <Card>
      <CardHeader
        avatar={<SellIcon />}
        title={t('daily-income.purchases-panel.title')}
        subheader={intervalRenderer(interval)}
        sx={{ backgroundColor: 'secondary.main', padding: { xs: 1, md: 2 } }}
      />
      <Divider />
      <CardContent sx={{ ...styles.content, ...scrollbarStyle }}>
        <PanelContent loading={loading} purchases={purchases} interval={interval} />
      </CardContent>
    </Card>
  );
};

const PanelContent = ({ loading, purchases, interval }) => {
  const { t } = useTranslation('stockManager');

  if (loading) {
    return (
      <Box minHeight="450px" display="flex" alignItems="center" justifyContent="center">
        <EmpLoadingIndicator />
      </Box>
    );
  }

  if (interval !== 'daily') {
    return (
      <Box minHeight="450px" display="flex" alignItems="center" justifyContent="center">
        <Typography align="center">
          {t('daily-income.purchases-panel.not-available')}
        </Typography>
      </Box>
    );
  }

  if (!purchases?.length) {
    return (
      <Box minHeight="450px" display="flex" alignItems="center" justifyContent="center">
        <Typography align="center">
          {t('daily-income.purchases-panel.empty')}
        </Typography>
      </Box>
    );
  }

  return purchases.map((purchase, index) => (
    <>
      <Box key={index}>
        <Box>
          <Typography>
            {formatters.date(purchase.date, DateTime.TIME_SIMPLE)} - {purchase.customer}
            <TotalRedeemedPoints totalRedeemedPoints={purchase?.totalRedeemedPoints} />
          </Typography>
        </Box>
        {purchase.items.map((item, index) => (
          <Box key={index} sx={{ ml: 4 }}>
            <Typography variant="caption">
              {`${item.product} - ${item.quantity} ${item.productUnit}`}
            </Typography>
          </Box>
        ))}
      </Box>
      {index !== purchases.length - 1 ? (<Divider sx={{ my: 2 }} />) : (<></>)}
    </>
  ));
};

const TotalRedeemedPoints = ({ totalRedeemedPoints }) => {
  const { t } = useTranslation('stockManager');

  if (!totalRedeemedPoints) return <></>;

  return (
    <Typography variant="caption" sx={{ ml: 2 }}>
      {t('daily-income.purchases-panel.redeemed-points', { points: totalRedeemedPoints })}
    </Typography>
  );
};
