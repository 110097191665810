import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { Editor, EmpCheckbox, EmpTextField, FormItem } from '../../../shared/components';
import { useShopViewer } from './useShopViewer';
import { ShopActionsMenu } from './ShopActionsMenu';

export const ShopViewer = () => {
  const { t } = useTranslation('stockManager');
  const { shop } = useShopViewer();

  return (
    <Editor
      width="md"
      headerProps={{
        title: t('shop.viewer.title'),
        backLinkTo: '/stock-manager/shop',
        actions: shop && <ShopActionsMenu shop={shop} disabledActions={['datasheet']} />
      }}
    >
      <Grid container columns={16} spacing={2}>
        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              label: t('shop.viewer.fields.id'),
              disabled: true,
              value: shop?.id || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              label: t('shop.viewer.fields.name'),
              disabled: true,
              value: shop?.name || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              label: t('shop.viewer.fields.currency'),
              disabled: true,
              value: shop?.currency?.shortName || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              label: t('shop.viewer.fields.warehouse'),
              disabled: true,
              value: shop?.warehouse?.name || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 12, 2]}>
          <EmpTextField
            textFieldProps={{
              label: t('shop.viewer.fields.address'),
              disabled: true,
              value: shop?.address || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpCheckbox
            label={t('shop.viewer.fields.can-redeem-points')}
            checkboxProps={{
              disabled: true,
              checked: shop?.canRedeemPoints || false
            }}
          />
        </FormItem>
      </Grid>
    </Editor>
  );
};
