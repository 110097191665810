import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, InputAdornment, Typography } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import EuroIcon from '@mui/icons-material/Euro';
import { useApiNotification } from '../../../shared';
import { useCurrentTenant } from '../../../shared/hooks/useCurrentTenant';
import { EmpLoadingButton, EmpModal, EmpNumberField } from '../../../shared/components';
import { TenantService } from '../../../shared/helpers/TenantService';
import { CurrencyService } from './CurrencyService';

export const EuroExchangeRateModal = () => {
  const { t } = useTranslation('stockManager');

  const { tenant, updateAdditionalData } = useCurrentTenant();
  const { showApiError } = useApiNotification();

  const [open, setOpen] = useState(false);
  const [rate, setRate] = useState(tenant?.additionalData?.euroExchangeRate);

  const [loading, setLoading] = useState(false);
  const [queryLoading, setQueryLoading] = useState(false);

  const handleOnChange = ({ target }) => {
    setRate(parseFloat(target.value));
  };

  const handleOnQueryMNB = () => {
    setQueryLoading(true);

    CurrencyService.getEuroExchangeRate()
      .then((response) => {
        setRate(response.exchangeRate);
      })
      .catch((exception) => {
        showApiError(null, exception);
      })
      .finally(() => {
        setQueryLoading(false);
      });
  };

  const handleOnSave = () => {
    setLoading(true);

    let additionalData = tenant?.additionalData || {};
    additionalData.euroExchangeRate = rate;

    TenantService.updateCurrentTenantAdditionalData(additionalData)
      .then(() => {
        updateAdditionalData(additionalData);
        handleOnClose();
      })
      .catch(error => {
        // TODO - show error notificaion
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="text"
        sx={{ borderRadius: '10px', ml: 2 }}
        startIcon={<EuroIcon />}
        onClick={() => setOpen(true)}
      >
        {t('currency.list.exchange-euro-rate.title')}
      </Button>
      <EmpModal
        open={open}
        fullWidth
        maxWidth="xs"
        titleIcon={<EuroIcon />}
        localization={{
          title: t('currency.list.exchange-euro-rate.title')
        }}
        onClose={handleOnClose}
        actions={
          <>
            <EmpLoadingButton loading={loading} onClick={handleOnSave}>
              {t('currency.list.exchange-euro-rate.actions.save')}
            </EmpLoadingButton>
            <Button color="error" onClick={handleOnClose}>
              {t('currency.list.exchange-euro-rate.actions.close')}
            </Button>
          </>
        }
      >
        <Typography sx={{ mb: 2 }} align="justify" variant="subtitle2">
          {t('currency.list.exchange-euro-rate.content')}
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="flex-end"
        >
          <EmpNumberField
            numberFieldProps={{
              sx: { width: '40%' },
              label: t('currency.list.exchange-euro-rate.fields.exchange-rate'),
              value: rate || '',
              onChange: handleOnChange,
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    HUF
                  </InputAdornment>
                )
              }
            }}
          />
          <Box>
            <EmpLoadingButton
              sx={{ ml: 2 }}
              loading={queryLoading}
              startIcon={<AccountBalanceIcon />}
              onClick={handleOnQueryMNB}
            >
              {t('currency.list.exchange-euro-rate.actions.query')}
            </EmpLoadingButton>
          </Box>
        </Box>
      </EmpModal>
    </>
  )
};
