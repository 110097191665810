import { useTranslation } from 'react-i18next';
import { Editor, EmpCheckbox, EmpDropdown, EmpTextField, Form, FormItem } from '../../../shared/components';
import { useShopEditor } from './useShopEditor';

export const ShopEditor = () => {
  const { t } = useTranslation('stockManager');

  const {
    shopId,
    form,
    currencyDropdownProps,
    warehouseDropdownProps,
    handleSubmit
  } = useShopEditor();

  return (
    <Editor
      width="md"
      headerProps={{
        title: t('shop.editor.title'),
        backLinkTo: '/stock-manager/shop'
      }}
      submitProps={{
        loading: form.formState.isSubmitting,
        onSubmit: handleSubmit,
        children: t(`shop.editor.actions.${shopId ? 'update' : 'create'}.label`)
      }}
    >
      <Form onSubmit={handleSubmit} columns={16} spacing={2}>
        <FormItem xs={[1, 14, 1]} sm={[2, 6, 8]}>
          <EmpTextField
            controllerProps={{
              name: 'name',
              control: form.control
            }}
            textFieldProps={{
              label: t('shop.editor.fields.name')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpDropdown
            controllerProps={{
              name: 'currencyId',
              control: form.control
            }}
            dropdownProps={{
              label: t('shop.editor.fields.currency'),
              ...currencyDropdownProps
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpDropdown
            controllerProps={{
              name: 'warehouseId',
              control: form.control
            }}
            dropdownProps={{
              label: t('shop.editor.fields.warehouse'),
              ...warehouseDropdownProps
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 12, 2]}>
          <EmpTextField
            controllerProps={{
              name: 'address',
              control: form.control
            }}
            textFieldProps={{
              label: t('shop.editor.fields.address')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
            <EmpCheckbox
              label={t('shop.editor.fields.can-redeem-points')}
              controllerProps={{
                name: 'canRedeemPoints',
                control: form.control
              }}
            />
        </FormItem>
      </Form>
    </Editor>
  );
};
