import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { CurrencyService } from './CurrencyService';

export const useCurrencyViewer = () => {
  const { currencyId } = useParams();
  const [currency, setCurrency] = useState(null);

  useEffect(() => {
    CurrencyService.getCurrencyById(currencyId)
      .then(currency => {
        setCurrency(currency);
      });
  }, [currencyId]);

  return {
    currency
  };
};
