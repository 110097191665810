import { useTranslation } from 'react-i18next';
import { Editor, EmpTextField, Form, FormItem} from '../../../shared/components';
import { AddressEditor } from '../shared';
import { CustomerActionsMenu } from './CustomerActionsMenu';
import { useCustomerViewer } from './useCustomerViewer';

export const CustomerViewer = () => {
  const { t } = useTranslation('stockManager');
  const { customer } = useCustomerViewer();

  return (
    <Editor
      width="md"
      headerProps={{
        title: t('customer.viewer.title'),
        backLinkTo: '/stock-manager/customer',
        actions: customer && (
          <CustomerActionsMenu
            customer={customer}
            disabledActions={['datasheet']}
          />
        )
      }}
    >
      <Form columns={16} spacing={2}>
        <FormItem xs={[1, 14, 1]} sm={[2, 6, 8]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: customer?.id || '-',
              label: t('customer.viewer.fields.id')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: customer?.name || '-',
              label: t('customer.viewer.fields.name')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: customer?.shop?.name || '-',
              label: t('customer.viewer.fields.shop')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: `${customer?.collectedPoints}` || '-',
              label: t('customer.viewer.fields.collected-points')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: `${customer?.discountPercentage}%` || '-',
              label: t('customer.viewer.fields.discount-percentage')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: customer?.taxNumber || '-',
              label: t('customer.viewer.fields.tax-number')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: customer?.companyName || '-',
              label: t('customer.viewer.fields.company-name')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: customer?.email || '-',
              label: t('customer.viewer.fields.email')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: customer?.phone || '-',
              label: t('customer.viewer.fields.phone')
            }}
          />
        </FormItem>

        <AddressEditor
          zipProps={{
            disabled: true,
            value: customer?.zip || '-',
            label: t('customer.viewer.fields.zip')
          }}
          cityProps={{
            disabled: true,
            value: customer?.city || '-',
            label: t('customer.viewer.fields.city')
          }}
          addressProps={{
            disabled: true,
            value: customer?.address || '-',
            label: t('customer.viewer.fields.address')
          }}
        />
      </Form>
    </Editor>
  );
};
