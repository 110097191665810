import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { usePayment } from '../../hooks/usePayment';

const styles = {
  space: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  paymentLabel: {
    fontWeight: 'bold',
    color: 'success.main'
  }
};

export const TotalPayable = () => {
  const { t } = useTranslation('stockManager');

  const {
    currency,
    totalPayable,
    totalPayableInEuro
  } = usePayment();

  return (
    <>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Box sx={styles.space}>
          <Typography variant="h4" align="center">
            {t('sale.payment-panel.fields.total-payable')}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" align="center" sx={styles.paymentLabel}>
          {totalPayable()}
        </Typography>
        {currency().toUpperCase() === 'HUF' && (
          <Typography variant="body1" align="center" sx={styles.paymentLabel}>
            {totalPayableInEuro()}
          </Typography>
        )}
      </Grid>
    </>
  );
};
