import { Outlet } from 'react-router-dom';
import { DefaultRoute, NotFoundPage, PrivateRoute } from '../../core';
import { Roles } from './Roles';
import { Sale } from './sale/Sale';
import { Dashboard } from './dashboard/Dashboard';
import { ProductEditor } from './products/ProductEditor';
import { ProductList } from './products/ProductList';
import { ShopList } from './shops/ShopList';
import { CustomerList } from './customers/CustomerList';
import { CustomerEditor } from './customers/CustomerEditor';
import { ShopEditor } from './shops/ShopEditor';
import { InvoiceList } from './invoices/InvocieList';
import { InvoiceViewer } from './invoices/InvoiceViewer';
import { ProductViewer } from './products/ProductViewer';
import { ManufacturerList } from './manufacturers/ManufacturerList';
import { ManufacturerEditor } from './manufacturers/ManufacturerEditor';
import { CustomerViewer } from './customers/CustomerViewer';
import { EmployeeList } from './employees/EmployeeList';
import { EmployeeEditor } from './employees/EmployeeEditor';
import { EmployeeViewer } from './employees/EmployeeViewer';
import { ShopViewer } from './shops/ShopViewer';
import { ManufacturerViewer } from './manufacturers/ManufacturerViewer';
import { ProductUnitList } from './product-units/ProductUnitList';
import { ProductUnitEditor } from './product-units/ProductUnitEditor';
import { ProductUnitViewer } from './product-units/ProductUnitViewer';
import { ProductCategoryViewer } from './product-categories/ProductCategoryViewer';
import { ProductCategoryEditor } from './product-categories/ProductCategoryEditor';
import { ProductCategoryList } from './product-categories/ProductCategoryList';
import { CurrencyViewer } from './currencies/CurrencyViewer';
import { CurrencyEditor } from './currencies/CurrencyEditor';
import { CurrencyList } from './currencies/CurrencyList';
import { WarehouseViewer } from './warehouses/WarehouseViewer';
import { WarehouseEditor } from './warehouses/WarehouseEditor';
import { WarehouseList } from './warehouses/WarehouseList';
import { StockCurrentState } from './stocks/StockCurrentState'
import { StockChangesList } from './stocks/StockChangesList';
import { StockChangeEditor } from './stocks/StockChangeEditor';
import { DocumentList } from './documents/DocumentList';
import { DocumentViewer } from './documents/DocumentViewer';
import { DailyIncome } from './daily-income/DailyIncome';

export const StockManagerRoutes = () => ({
  path: '/stock-manager',
  element: (
    <PrivateRoute
      component={Outlet}
      roles={Roles.STM_All}
    />
  ),
  children: [
    {
      path: '',
      element: (
        <PrivateRoute
          component={Dashboard}
          roles={Roles.STM_All}
        />
      )
    },
    {
      path: 'sale',
      element: (
        <PrivateRoute
          component={Sale}
          roles={Roles.STM_All}
        />
      )
    },
    {
      path: 'daily-income',
      element: (
        <PrivateRoute
          component={DailyIncome}
          roles={[Roles.STM_Admin, Roles.STM_ShopManager]}
        />
      )
    },
    {
      path: '/stock-manager/product',
      element: (
        <PrivateRoute
          component={ProductList}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/product/editor',
      element: (
        <PrivateRoute
          component={ProductEditor}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/product/editor/:productId',
      element: (
        <PrivateRoute
          component={ProductEditor}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/product/:productId',
      element: (
        <PrivateRoute
          component={ProductViewer}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/shop',
      element: (
        <PrivateRoute
          component={ShopList}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/shop/editor',
      element: (
        <PrivateRoute
          component={ShopEditor}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/shop/editor/:shopId',
      element: (
        <PrivateRoute
          component={ShopEditor}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/shop/:shopId',
      element: (
        <PrivateRoute
          component={ShopViewer}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/customer',
      element: (
        <PrivateRoute
          component={CustomerList}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/customer/editor/:customerId',
      element: (
        <PrivateRoute
          component={CustomerEditor}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/customer/editor',
      element: (
        <PrivateRoute
          component={CustomerEditor}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/customer/:customerId',
      element: (
        <PrivateRoute
          component={CustomerViewer}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/employee',
      element: (
        <PrivateRoute
          component={EmployeeList}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/employee/editor/:employeeId',
      element: (
        <PrivateRoute
          component={EmployeeEditor}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/employee/editor',
      element: (
        <PrivateRoute
          component={EmployeeEditor}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/employee/:employeeId',
      element: (
        <PrivateRoute
          component={EmployeeViewer}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/invoice',
      element: (
        <PrivateRoute
          component={InvoiceList}
          roles={[Roles.STM_Admin, Roles.STM_ShopManager]}
        />
      )
    },
    {
      path: '/stock-manager/invoice/editor/:invoiceId',
      element: (
        <PrivateRoute
          component={InvoiceViewer}
          roles={[Roles.STM_Admin, Roles.STM_ShopManager]}
        />
      )
    },
    {
      path: '/stock-manager/manufacturer',
      element: (
        <PrivateRoute
          component={ManufacturerList}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/manufacturer/editor',
      element: (
        <PrivateRoute
          component={ManufacturerEditor}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/manufacturer/editor/:manufacturerId',
      element: (
        <PrivateRoute
          component={ManufacturerEditor}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/manufacturer/:manufacturerId',
      element: (
        <PrivateRoute
          component={ManufacturerViewer}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/product-unit',
      element: (
        <PrivateRoute
          component={ProductUnitList}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/product-unit/editor',
      element: (
        <PrivateRoute
          component={ProductUnitEditor}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/product-unit/editor/:productUnitId',
      element: (
        <PrivateRoute
          component={ProductUnitEditor}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/product-unit/:productUnitId',
      element: (
        <PrivateRoute
          component={ProductUnitViewer}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/product-category',
      element: (
        <PrivateRoute
          component={ProductCategoryList}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/product-category/editor',
      element: (
        <PrivateRoute
          component={ProductCategoryEditor}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/product-category/editor/:productCategoryId',
      element: (
        <PrivateRoute
          component={ProductCategoryEditor}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/product-category/:productCategoryId',
      element: (
        <PrivateRoute
          component={ProductCategoryViewer}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/currency',
      element: (
        <PrivateRoute
          component={CurrencyList}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/currency/editor',
      element: (
        <PrivateRoute
          component={CurrencyEditor}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/currency/editor/:currencyId',
      element: (
        <PrivateRoute
          component={CurrencyEditor}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/currency/:currencyId',
      element: (
        <PrivateRoute
          component={CurrencyViewer}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/current-stock',
      element: (
        <PrivateRoute
          component={StockCurrentState}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/stock-movements',
      element: (
        <PrivateRoute
          component={StockChangesList}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/in-out-goods',
      element: (
        <PrivateRoute
          component={StockChangeEditor}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/documents',
      element: (
        <PrivateRoute
          component={DocumentList}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/documents/:documentId',
      element: (
        <PrivateRoute
          component={DocumentViewer}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/warehouses',
      element: (
        <PrivateRoute
          component={WarehouseList}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/warehouses/editor/:warehouseId',
      element: (
        <PrivateRoute
          component={WarehouseEditor}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/warehouses/editor',
      element: (
        <PrivateRoute
          component={WarehouseEditor}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '/stock-manager/warehouses/:warehouseId',
      element: (
        <PrivateRoute
          component={WarehouseViewer}
          roles={[Roles.STM_Admin]}
        />
      )
    },
    {
      path: '*',
      element: <DefaultRoute component={NotFoundPage} />
    }
  ]
});
