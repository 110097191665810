const build = (sorting) => {
  if (!sorting?.column || !sorting.direction) return null;
  return `${sorting.column}:${sorting.direction}`;
};

const parse = (value) => {
  let column = null, direction = null;

  if (value) {
    const parts = value.split(':');

    if (parts.length === 2 && (parts[1] === 'asc' || parts[1] === 'desc')) {
      column = parts[0];
      direction = parts[1];
    }
  }

  return {
    column,
    direction
  };
};

export const SortingHelper = {
  build,
  parse
};
