import { Box } from '@mui/material';
import { ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';
import { EmpLoadingButton } from '../../../../shared/components';

const styles = {
  navigationRoot: {
    display: 'flex',
    flex: '1',
    //alignSelf: 'flex-end',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: 2
    }
  }
};

export const StepperNavigator = ({ backButtonProps, nextButtonProps }) => {
  return (
    <Box sx={styles.navigationRoot}>
      {backButtonProps && (
        <Box>
          <EmpLoadingButton
            startIcon={<ArrowBackIosIcon />}
            {...backButtonProps}
          />
        </Box>
      )}
      {nextButtonProps && (
        <Box>
          <EmpLoadingButton
            endIcon={<ArrowForwardIosIcon />}
            {...nextButtonProps}
          />
        </Box>
      )}
    </Box>
  );
};
