import React, { createContext, useCallback, useContext, useState } from 'react';

import { Slide, Snackbar } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';

const NotificationContext = createContext({
  error: (title, message, config = {}) => {},
  success: (title, message, config = {}) => {}
});

export const useNotification = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = (props) => {
  const [notificationProps, setNotificationProps] = useState({
    open: false,
    title: null,
    message: null,
    severity: null
  });

  const success = useCallback((title, message, config = {}) => {
    const { duration = 2000, onClose } = config;

    setNotification(true, title, message, 'success');

    setTimeout(() => {
      setNotification(false, null, null, 'success');
      onClose && onClose();
    }, duration);
  }, []);

  const error = useCallback((title, message, config = {}) => {
    const { duration = 2000, onClose } = config;

    setNotification(true, title, message, 'error');

    setTimeout(() => {
      setNotification(false, null, null, 'error');
      onClose && onClose();
    }, duration);
  }, []);

  const setNotification = (open, title, message, severity) => {
    setNotificationProps({ open, title, message, severity });
  };

  const value = {
    error: error,
    success: success
  };

  return (
    <NotificationContext.Provider value={value}>
      {props.children}
      <EmpNotification {...notificationProps} />
    </NotificationContext.Provider>
  );
}

export const EmpNotification = (props) => {
  const { open, title, message, severity } = props;
  const anchor = { vertical: 'bottom', horizontal: 'left' };

  let content = null;

  if (message) {
    content = (
      <>
        <AlertTitle><strong>{title}</strong></AlertTitle>
        {message}
      </>
    );
  }
  else {
    content = (
      <>
        {title}
      </>
    )
  }

  return (
    <Snackbar open={open} anchorOrigin={anchor} TransitionComponent={Transition}>
      <Alert variant="filled" elevation={6} severity={severity}>
        {content}
      </Alert>
    </Snackbar>
  );
};

const Transition = (props) => {
  return <Slide {...props} direction="up" />;
};
