import { saleActionTypes } from './saleActions';

export const PAYMENT_METHODS = {
  CASH: 1,
  CARD: 2,
  TRANSFER: 3,
  MIXED: 4,
  GIVEAWAY: 5
};

export const initialState = {
  activeStep: 0,
  shop: null,
  shopsLoading: false,
  shopsError: null,
  shops: [],
  customer: null,
  productCategory: null,
  product: null,
  productCategoriesLoading: false,
  productCategoriesError: null,
  productCategories: [],
  originalProducts: [],
  products: [],
  showOutOfStockProducts: false,
  quantity: {
    raw: [],
    stringified: '',
    parsed: 0
  },
  basket: {
    products: [],
    totalPrice: 0,
    totalDiscountedPrice: 0
  },
  extraDiscount: null,
  redeemedPoints: {
    value: null,
    rounded: null,
    todayRedeemed: null
  },
  payment: {
    mode: 1,
    cash: null,
    card: null,
    transfer: null
  },
  comment: null
};

export const saleReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case saleActionTypes.RESET:
      return reset(state);

    case saleActionTypes.NEXT_STEP:
      return nextStep(state);

    case saleActionTypes.PREV_STEP:
      return prevStep(state);

    case saleActionTypes.SET_INITIAL:
      return { ...payload };

    case saleActionTypes.SET_SHOPS_LOADING:
      return setShopsLoading(state, payload);

    case saleActionTypes.SET_SHOPS_ERROR:
      return setShopsError(state, payload);

    case saleActionTypes.SET_SHOPS_SUCCESS:
      return setShopsSuccess(state, payload);

    case saleActionTypes.SET_SHOP:
      return setShop(state, payload);

    case saleActionTypes.SET_PRODUCT_CATEGORIES_LOADING:
      return setProductCategoriesLoading(state, payload);

    case saleActionTypes.SET_PRODUCT_CATEGORIES_ERROR:
      return setProductCategoriesError(state, payload);

    case saleActionTypes.SET_PRODUCT_CATEGORIES_SUCCESS:
      return setProductCategoriesSuccess(state, payload);

    case saleActionTypes.SET_PRODUCT_CATEGORY:
      return setProductCategory(state, payload);

    case saleActionTypes.RESET_PRODUCT_CATEGORY:
      return resetProductCategory(state);

    case saleActionTypes.SET_PRODUCT:
      return setProduct(state, payload);

    case saleActionTypes.SET_SHOW_OUT_OF_STOCK_PRODUCTS:
      return setShowOutOfStockProducts(state, payload);

    case saleActionTypes.SET_CUSTOMER:
      return setCustomer(state, payload);

    case saleActionTypes.SET_QUANTITY:
      return setQuantity(state, payload);

    case saleActionTypes.SET_REDEEMED_POINTS:
      return setPoints(state, payload);

    case saleActionTypes.SET_COMMENT:
      return setComment(state, payload);

    case saleActionTypes.SET_PAYMENT_METHOD:
      return setPaymentMethod(state, payload);

    case saleActionTypes.SET_PAYMENT_CASH:
      return setPaymentCash(state, payload);

    case saleActionTypes.SET_PAYMENT_CARD:
      return setPaymentCard(state, payload);

    case saleActionTypes.SET_PAYMENT_TRANSFER:
      return setPaymentTransfer(state, payload);

    case saleActionTypes.SET_EXTRA_DISCOUNT:
      return setExtraDiscount(state, payload);

    case saleActionTypes.ADD_TO_BASKET:
      return addToBasket(state);

    case saleActionTypes.REMOVE_FROM_BASKET:
      return removeFromBasket(state, payload);

    case saleActionTypes.INCREMENT_QUANTITY:
      return incrementQuantity(state, payload);

    case saleActionTypes.DECREMENT_QUANTITY:
      return decrementQuantity(state, payload);

    default:
      return state;
  }
};

const reset = (state) => {
  const { shop, shops } = state;

  return {
    ...initialState,
    shop,
    shops
  };
};

const setShopsLoading = (state, loading) => {
  return {
    ...state,
    shop: null,
    shopsLoading: loading
  };
};

const setShopsError = (state, error) => {
  return {
    ...state,
    shopsLoading: false,
    shopsError: error,
    shop: null,
    shops: []
  };
};

const setShopsSuccess = (state, shops) => {
  let { shop } = state;
  shop = shop || shops[0]?.id;

  return {
    ...state,
    shopsLoading: false,
    shopsError: null,
    shop,
    shops
  };
};

const setShop = (state, shop) => {
  return {
    ...state,
    shop
  };
};

const nextStep = (state) => {
  const { activeStep } = state;

  return {
    ...state,
    activeStep: activeStep + 1
  };
};

const prevStep = (state) => {
  const { activeStep } = state;

  return {
    ...state,
    activeStep: activeStep - 1
  };
};

const setCustomer = (state, customer) => {
  return {
    ...updateBasket({
      ...state,
      customer,
      redeemedPoints: {
        value: null,
        rounded: null,
        todayRedeemed: customer?.redeemedPointsToday || null
      }
    })
  };
};

const setProductCategoriesLoading = (state, loading) => {
  return {
    ...state,
    productCategoriesLoading: loading
  };
};

const setProductCategoriesError = (state, error) => {
  return {
    ...state,
    productCategoriesLoading: false,
    productCategoriesError: error,
    productCategories: []
  };
};

const setProductCategoriesSuccess = (state, productCategories) => {
  return {
    ...state,
    productCategoriesLoading: false,
    productCategoriesError: null,
    productCategories
  };
};

const resetProductCategory = (state) => {
  return {
    ...state,
    productCategory: null,
    products: []
  }
};

const setProductCategory = (state, productCategory) => {
  const { basket, showOutOfStockProducts } = state;

  const newState = {
    ...state,
    productCategory,
    products: outOfStockFilter(
      productCategory.products,
      basket.products,
      showOutOfStockProducts
    )
  };

  for (const basketProduct of basket.products) {
    const product = newState.products.find(product => product.id === basketProduct.id);

    if (product) {
      product.currentAmount -= basketProduct.quantity;
    }
  }

  return newState;
};

const setProduct = (state, product) => {
  return {
    ...state,
    product,
    quantity: {
      raw: [],
      stringified: '',
      parsed: 0
    }
  };
};

const setShowOutOfStockProducts = (state, showOutOfStockProducts) => {
  const { basket, products } = state;

  return {
    ...state,
    showOutOfStockProducts,
    products: outOfStockFilter(
      products.map(product => ({ ...product })),
      basket.products,
      showOutOfStockProducts
    )
  };
};

const setQuantity = (state, quantity) => {
  return {
    ...state,
    quantity
  };
};

const setPoints = (state, points) => {
  return {
    ...updateBasket({
      ...state,
      redeemedPoints: {
        ...state.redeemedPoints,
        value: points,
        rounded: points
      }
    })
  };
};

const setExtraDiscount = (state, extraDiscount) => {
  if (extraDiscount > 100) {
    extraDiscount = 100;
  }

  return {
    ...updateBasket({
      ...state,
      extraDiscount
    })
  };
};

const addToBasket = (state) => {
  const { basket, product, products, quantity, showOutOfStockProducts } = state;

  let contains = false;
  const basketProducts = basket.products.map(pProduct => {
    const productCopy = { ...pProduct };

    if (productCopy.id === product.id) {
      contains = true;
      productCopy.quantity += quantity.parsed;
    }

    return productCopy;
  });

  if (!contains) {
    basketProducts.push({
      ...product,
      quantity: quantity.parsed
    });
  }

  // Decrease the selected product's stock
  const productsCopy = products.map(pProduct => {
    const productCopy = { ...pProduct };

    if (productCopy.id === product.id) {
      productCopy.currentAmount -= quantity.parsed;
    }

    return productCopy;
  });

  return {
    ...updateBasket({
      ...state,
      product: null,
      quantity: {
        raw: [],
        stringified: '',
        parsed: 0
      },
      products: outOfStockFilter(
        productsCopy,
        basketProducts,
        showOutOfStockProducts
      ),
      basket: {
        ...basket,
        products: basketProducts
      }
    })
  };
};

const incrementQuantity = (state, productId) => {
  const { basket, product, products, showOutOfStockProducts } = state;

  const basketProducts = basket.products.map(product => {
    const productCopy = { ...product };

    if (productCopy.id === productId) {
      const newQuantity = productCopy.quantity + 1;

      if (productCopy.currentAmount >= newQuantity) {
        productCopy.quantity = newQuantity;
      }
    }

    return productCopy;
  });

  let newProduct = product;

  const productsCopy = products.map(product => {
    const productCopy = { ...product };

    if (productCopy.id === productId) {
      const newCurrentAmount = productCopy.currentAmount - 1;

      if (newProduct?.id === productCopy.id) {
        newProduct = productCopy;
      }

      if (newCurrentAmount >= 0) {
        productCopy.currentAmount = newCurrentAmount;
      }
    }

    return productCopy;
  });

  return {
    ...updateBasket({
      ...state,
      product: newProduct,
      products: outOfStockFilter(
        productsCopy,
        basketProducts,
        showOutOfStockProducts
      ),
      basket: {
        ...basket,
        products: basketProducts
      }
    })
  };
};

const decrementQuantity = (state, productId) => {
  const { basket, product, products, showOutOfStockProducts } = state;

  let newQuantity = null;

  const basketProducts = basket.products.map(product => {
    const productCopy = { ...product };

    if (productCopy.id === productId) {
      if (productCopy.quantity > 1) {
        productCopy.quantity -= 1;
        newQuantity = productCopy.quantity;
      }
    }

    return productCopy;
  });

  let newProduct = product;

  const productsCopy = products.map(product => {
    const productCopy = { ...product };

    if (productCopy.id === productId) {
      if (newProduct?.id === productCopy.id) {
        newProduct = productCopy;
      }

      if (newQuantity !== null && newQuantity >= 1) {
        productCopy.currentAmount++;
      }
    }

    return productCopy;
  });

  return {
    ...updateBasket({
      ...state,
      product: newProduct,
      products: outOfStockFilter(
        productsCopy,
        basketProducts,
        showOutOfStockProducts
      ),
      basket: {
        ...basket,
        products: basketProducts
      }
    })
  };
};

const removeFromBasket = (state, productId) => {
  const { basket, product, products, showOutOfStockProducts } = state;

  let removedProduct = null;
  const basketProducts = [];

  basket.products.forEach(product => {
    if (product.id !== productId) {
      basketProducts.push({ ...product });
    }
    else {
      removedProduct = product;
    }
  });

  let newProduct = product;

  // Increase the product's stock
  const productsCopy = products.map(product => {
    const productCopy = { ...product };

    if (newProduct?.id === productCopy.id) {
      newProduct = productCopy;
    }

    if (productCopy.id === removedProduct.id) {
      productCopy.currentAmount += removedProduct.quantity;
    }

    return productCopy;
  });

  return {
    ...updateBasket({
      ...state,
      product: newProduct,
      products: outOfStockFilter(
        productsCopy,
        basket.products,
        showOutOfStockProducts
      ),
      basket: {
        ...basket,
        products: basketProducts
      }
    })
  };
};

const setPaymentMethod = (state, method) => {
  return {
    ...state,
    payment: {
      mode: method,
      cash: null,
      card: null,
      transfer: null
    }
  };
};

const setPaymentCash = (state, cash) => {
  return {
    ...state,
    payment: {
      ...state.payment,
      cash
    }
  };
};

const setPaymentCard = (state, card) => {
  return {
    ...state,
    payment: {
      ...state.payment,
      card
    }
  };
};

const setPaymentTransfer = (state, transfer) => {
  return {
    ...state,
    payment: {
      ...state.payment,
      transfer
    }
  };
};

const setComment = (state, comment) => {
  return {
    ...state,
    comment
  };
};

const updateBasket = (state) => {
  let { basket, customer, extraDiscount, redeemedPoints } = state;

  basket.totalPrice = 0;
  basket.totalDiscountedPrice = 0;
  basket.totalPoints = 0;

  basket.customerDiscount = customer?.discountPercentage || 0;
  basket.extraDiscount = extraDiscount || 0;

  basket.products.forEach(productEntry => {
    productEntry.totalPrice = productEntry.quantity * productEntry.price;
    productEntry.customerDiscountedPrice = productEntry.totalPrice * (1 - (basket.customerDiscount / 100));
    productEntry.totalDiscountedPrice = productEntry.customerDiscountedPrice * (1 - (basket.extraDiscount / 100));

    basket.totalPrice += productEntry.totalPrice;
    basket.totalDiscountedPrice += productEntry.totalDiscountedPrice;
    basket.totalPoints += parseInt(productEntry.quantity, 10) * productEntry.pointsPerUnit;
  });

  redeemedPoints = updatePoints(state);

  return {
    ...state,
    basket,
    redeemedPoints,
    extraDiscount
  };
};

const updatePoints = (state) => {
  let { basket, customer, redeemedPoints: { value, rounded, todayRedeemed } } = state;

  if (value !== null) {
    if (value > customer.collectedPoints) {
      value = customer.collectedPoints;
      rounded = customer.collectedPoints;
    }

    // Customer wants to redeem more points than the total price
    if (value > basket.totalDiscountedPrice) {
      value = basket.totalDiscountedPrice;
      rounded = basket.totalDiscountedPrice;
    }

    if ((value + todayRedeemed) > 5000) {
      value = 5000 - todayRedeemed;
      rounded = 5000 - todayRedeemed;
    }

    // Point is integer so 15.86 requires 16 points
    if (!Number.isInteger(value)) {
      rounded = parseInt(value) + 1;
    }

    basket.totalPrice -= value;
    basket.totalDiscountedPrice -= value;
  }

  return {
    value,
    rounded,
    todayRedeemed
  };
};

const outOfStockFilter = (products, basketProducts, showOutOfStockProducts) => {
  const filtered = [];

  for (let i = 0; i < (products || []).length; i++) {
    const inBasket = basketProducts
      .some(product => product.id === products[i].id);

    products[i].inBasket = inBasket;

    if (showOutOfStockProducts || products[i].currentAmount > 0 || inBasket) {
      filtered.push({ ...products[i] });
    }
  };

  const inStockProducts = filtered
    .filter(product => product.currentAmount > 0 || product.inBasket)
    .sort((product1, product2) => product1.name.localeCompare(product2.name));

  const outOfStockProducts = filtered
    .filter(product => product.currentAmount === 0 && !product.inBasket)
    .sort((product1, product2) => product1.name.localeCompare(product2.name));

  return [...inStockProducts, ...outOfStockProducts];
};
