import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { DateTime } from 'luxon';
import { Box, Tooltip } from '@mui/material';
import { Article as DocumentsIcon } from '@mui/icons-material';
import { EmpText } from '../../../shared/components';
import { DocumentActionsMenu } from './DocumentActionsMenu';
import { DocumentService } from './DocumentService';
import { useTableV2 } from '../../../shared/components/table/hooks/useTableV2';

const columnsFactory = (t) => [{
    header: t('document.list.columns.serial-no'),
    field: 'serialNo',
    //width: '20%',
    render: ({ serialNo }) => (
      <EmpText ellipsis={15}>
        {serialNo}
      </EmpText>
    )
  }, {
    header: t('document.list.columns.document-type'),
    field: 'documentTypeName',
    render: ({documentType}) => documentType?.typeName || '-'
    //width: '20%'
  }, {
    header: t('document.list.columns.invoice-serial-no'),
    field: 'invoiceSerial',
    //width: '20%',
    render: ({ invoiceSerial }) => (
      <EmpText ellipsis={15}>
        {invoiceSerial}
      </EmpText>
    )
  }, {
    header: t('document.list.columns.supplier-name'),
    field: 'supplierName',
    //width: '15%',
    render: ({ supplierName }) => (
      <Tooltip title={supplierName || ''} arrow>
        <Box>{supplierName || '-'}</Box>
      </Tooltip>
    )
  }, {
    header: t('document.list.columns.warehouse'),
    field: 'warehouseName',
    //width: '20%',
    render: ({ warehouse }) => warehouse?.name || '-',
  }, {
    header: t('document.list.columns.created-by'),
    field: 'createdBy',
    //width: '20%',
    render: ({ createdBy }) => (
      <EmpText ellipsis={40}>
        {createdBy}
      </EmpText>
    ),
  }, {
    header: t('document.list.columns.created-at'),
    field: 'createdAt',
    // width: '37%',
    render: ({ createdAt }) => (
      <EmpText ellipsis={40}>
        {DateTime.fromISO(createdAt).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
      </EmpText>
    ),
  }, {
    render: (row) => <DocumentActionsMenu document={row} />,
    width: '5%',
    align: 'right',
    sortable: false
  }
];

export const useDocumentList = () => {
  const { t } = useTranslation('stockManager');

  const columns = useMemo(() => columnsFactory(t), [t]);

  const tableProps = useTableV2({
    columns,
    title: t('document.list.title'),
    icon: <DocumentsIcon />,
    dataSource: DocumentService.getDocumentsPaginated
  });

  return {
    tableProps
  };
};
