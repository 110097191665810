const nonAlphaNumericChars = [
  '!', '@', '#', '&', '(', ')', '–', '[', '{', '}', ']', ':', ';', ',', '?', '/', '*',
  '`', '~', '$', '^', '+', '=', '<', '>', '“'
];

const password = (value) => {
  let isValid = false;
  let atLeast8Chars = false;
  let atLeastOneDigit = false;
  let atLeastOneLowercase = false;
  let atLeastOneUppercase = false;
  let atLeastOneAlphaNumericChar = false;

  if (value) {
    if (value.length >= 8) {
      atLeast8Chars = true;
    }

    for (let char of value) {
      if (char >= '0' && char <= '9') {
        atLeastOneDigit = true;
      }
      else if (char >= 'a' && char <= 'z') {
        atLeastOneLowercase = true;
      }
      else if (char >= 'A' && char <= 'Z') {
        atLeastOneUppercase = true;
      }
      else if (nonAlphaNumericChars.includes(char)) {
        atLeastOneAlphaNumericChar = true;
      }
    }

    isValid = (
      atLeast8Chars &&
      atLeastOneDigit && 
      atLeastOneLowercase &&
      atLeastOneUppercase &&
      atLeastOneAlphaNumericChar
    );
  }

  return {
    isValid,
    atLeast8Chars,
    atLeastOneDigit,
    atLeastOneLowercase,
    atLeastOneUppercase,
    atLeastOneAlphaNumericChar
  };
};

export const validators = {
  password
};
