import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain as DomainIcon } from '@mui/icons-material';
import { ProductCategoryActionsMenu } from './ProductCategoryActionsMenu';
import { ProductCategoryFilterForm } from './ProductCategoryFilterForm';
import { ProductCategoryService } from './ProductCategoryService';
import { useTableV2 } from '../../../shared/components/table/hooks/useTableV2';

const columnsFactory = (t) => [{
  header: t('product-category.list.columns.id'),
  field: 'id',
  width: '30%'
}, {
  header: t('product-category.list.columns.name'),
  field: 'name',
  width: '60%'
},{
  width: '10%',
  render: (row, { refresh }) => (
    <ProductCategoryActionsMenu
      productCategory={row}
      onDelete={refresh}
    />
  )
}];

export const useProductCategoryList = () => {
  const { t } = useTranslation('stockManager');

  const columns = useMemo(() => columnsFactory(t), [t]);

  const tableProps = useTableV2({
    columns,
    title: t('product-category.list.title'),
    icon: <DomainIcon />,
    dataSource: ProductCategoryService.getProductCategoriesPaginated,
    components: {
      toolbar: {
        createButtonProps: {
          to: '/stock-manager/product-category/editor',
          children: t('product-category.actions.create.label')
        },
        filterModalProps: {
          maxWidth: 'sm',
          title: t('product-category.list.filter.title'),
          children: ProductCategoryFilterForm
        }
      }
    }
  });

  return {
    tableProps
  };
};
