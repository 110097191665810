import { useEffect } from 'react';
import { useEmpAuth } from '../AuthProvider';
import { LoadingScreen } from './LoadingScreen';

export const SignOutCallback = () => {
  const { signoutCallback } = useEmpAuth();

  useEffect(() => {
    signoutCallback();
  }, [signoutCallback]);

  return <LoadingScreen />;
};
