import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { Editor, FormItem, EmpTextField } from '../../../shared/components';
import { useManufacturerViewer } from './useManufacturerViewer';
import { ManufacturerActionsMenu } from './ManufacturerActionsMenu';

export const ManufacturerViewer = () => {
  const { t } = useTranslation('stockManager');
  const { manufacturer } = useManufacturerViewer();

  return (
    <Editor
      width="md"
      headerProps={{
        title: t('manufacturer.viewer.title'),
        backLinkTo: '/stock-manager/manufacturer',
        actions: manufacturer && (
          <ManufacturerActionsMenu
            manufacturer={manufacturer}
            disabledActions={['datasheet']}
          />
        )
      }}
    >
      <Grid container columns={16} spacing={2}>
        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: manufacturer?.id || '',
              label: t('manufacturer.viewer.fields.id')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: manufacturer?.name || '',
              label: t('manufacturer.viewer.fields.name')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: manufacturer?.shortName || '',
              label: t('manufacturer.viewer.fields.short-name')
            }}
          />
        </FormItem>
      </Grid>
    </Editor>
  );
};
