import i18next from 'i18next';
import { AuthHelper } from '../../core/authentication/AuthHelper';
import { FilterHelper } from './FilterHelper';
import { SortingHelper } from './SortingHelper';

const API_URL = `${process.env.REACT_APP_API_URL}/api`;

export const ServiceBase = {
  async deleteAsync(url) {
    return ServiceBase.makeRequest({
      url,
      method: 'DELETE'
    });
  },

  async getAsync(url) {
    return ServiceBase.makeRequest({
      url,
      method: 'GET'
    });
  },

  // Deprecated
  async makeGetRequest(config) {
    return ServiceBase.makeRequest({
      ...config,
      method: 'GET'
    });
  },

  async postAsync(url, payload, settings) {
    // const { payload } = config;

    return ServiceBase.makeRequest({
      url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      payload: JSON.stringify(payload)
    });
  },

  // Deprecated
  async makePostRequestAsJson(config) {
    const { payload } = config;

    return ServiceBase.makeRequest({
      ...config,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      payload: JSON.stringify(payload)
    });
  },

  async putAsync(url, payload, settings) {
    // const { payload } = config;

    return ServiceBase.makeRequest({
      url,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      payload: JSON.stringify(payload)
    });
  },

  // Deprecated
  async makePutRequestAsJson(config) {
    const { payload } = config;

    return ServiceBase.makeRequest({
      ...config,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      payload: JSON.stringify(payload)
    });
  },

  async makeRequest(config) {
    const {
      //language = 'en',
      //tenantId,
      //accessToken,
      method,
      url,
      headers = {},
      payload = null
    } = config;

    const user = AuthHelper.getUser();
    const tenant = AuthHelper.getTenant();

    headers['Accept-Language'] = i18next.language;
    headers['Authorization'] = `Bearer ${user?.access_token}`;
    headers['X-Tenant-Id'] = tenant?.externalId;

    let response = null;

    try {
      response = await fetch(`${API_URL}${url}`, {
        method,
        headers,
        body: payload
      });
    }
    catch (error) {
      // Network error - Comes directly from the fetch API
      throw new EmpFetchError(i18next.t('core:general.api.backend-not-available'));
    }

    if (!response.ok) {
      throw await response.json();
    }

    return response;
  },

  makeQueryString({ pagination, sorting, filters }) {
    const values = [];

    if (pagination) {
      const { pageNumber, pageSize } = pagination;
      values.push(`pageNumber=${pageNumber}&pageSize=${pageSize}`);
    }

    if (sorting) {
      const sortingQueryParameter = SortingHelper.build(sorting);

      if (sortingQueryParameter !== null) {
        values.push(`sorting=${sortingQueryParameter}`);
      }
    }

    if (filters) {
      const filtersQueryParameter = FilterHelper.build(filters);

      if (filtersQueryParameter !== null) {
        values.push(`filters=${filtersQueryParameter}`);
      }
    }

    return !values.length ? '' : values.join('&');
  }
}

class EmpFetchError extends Error {
  constructor(message) {
    super(message);
    this.errors = [{ message }]
  }
}
