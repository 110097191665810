import { useEmpAuth } from '../../core/authentication/AuthProvider';

export const useCurrentTenant = () => {
  const { tenant } = useEmpAuth();

  const removeTenant = () => {
    localStorage.removeItem('tenant');
  };

  const updateAdditionalData = (additionalData) => {
    tenant.additionalData = additionalData;
    localStorage.setItem('tenant', JSON.stringify(tenant));
  };

  return {
    tenant,
    removeTenant,
    updateAdditionalData
  };
};
