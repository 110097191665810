import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { StepperNavigator } from '../components/StepperNavigator';
import { BasketPanel } from '../components/BasketPanel';
import { ProductSelectorPanel } from '../components/ProductSelectorPanel';
import { QuantitySelectorPanel } from '../components/QuantitySelectorPanel';
import { useSaleContext } from '../context/SaleContext';
import { CustomerSelectorPanel } from '../components/CustomerSelectorPanel';
import { saleActions } from '../saleActions';


export const ProductSelectStep = () => {
  const { t } = useTranslation('stockManager');

  const {
    basket,
    dispatch
  } = useSaleContext();

  const nextButtonProps = {
    disabled: !basket.products.length,
    onClick: () => dispatch(saleActions.nextStep()),
    children: t('sale.product-select-step.next')
  };

  return (
    <Grid container spacing={2}>
      {/* Left side: product selector and basket */}
      <Grid item xs={7} style={{ height: '42.5vh' }}>
        <Box sx={{ height: '42.5vh', mb: 2 }}>
          <ProductSelectorPanel />
        </Box>
        <Box sx={{ height: '40vh' }}>
          <BasketPanel />
        </Box>
      </Grid>

      <Grid item xs={5} style={{ height: '30vh' }}>
        <Box style={{ height: '35vh' }}>
          <CustomerSelectorPanel />
        </Box>
        <Box style={{ height: '45vh' }}>
          <QuantitySelectorPanel />
        </Box>
        <Box style={{ height: '5vh' }}>
          <StepperNavigator nextButtonProps={nextButtonProps} />
        </Box>
      </Grid>
    </Grid>
  );
};
