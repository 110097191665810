import { useEffect } from 'react';
import { Box, Container } from '@mui/material';
import { SaleContextProvider, useSaleContext } from './context/SaleContext';
import { ProductSelectStep } from './steps/ProductSelectStep';
import { PaymentMethodSelectStep } from './steps/PaymentMethodSelectStep';
import { SuccessSaleStep } from './steps/SuccessSale';
import { saleActions } from './saleActions';
import { SaleService } from './SaleService';

export const Sale = () => {
  return (
    <SaleContextProvider>
      <SaleScreen />
    </SaleContextProvider>
  );
};

const SaleScreen = () => {
  const { activeStep, shop, dispatch } = useSaleContext();

  useEffect(() => {
    dispatch(saleActions.setShopsLoading(true));

    SaleService.getShops()
      .then(shops => {
        dispatch(saleActions.setShopsSuccess(shops))
      })
      .catch(error => {
        dispatch(saleActions.setShopsError(error))
      });
  }, [dispatch]);

  // TODO - redundant code
  useEffect(() => {
    if (shop) {
      dispatch(saleActions.reset());
      dispatch(saleActions.setProductCategoriesLoading(true));

      SaleService.getProductCategories(shop)
        .then(products => {
          dispatch(saleActions.setProductCategoriesSuccess(products));
        })
        .catch(error => {
          dispatch(saleActions.setProductCategoriesError(error));
        });
    }
  }, [dispatch, shop]);

  useEffect(() => {
    return () => {
      dispatch(saleActions.reset());
    }
  }, [dispatch])

  return (
    <Container maxWidth="xl" disableGutters>
      <Box sx={{ mt: 1, p: 2 }}>
        {activeStep === 0 && (
          <ProductSelectStep />
        )}
        {activeStep === 1 && (
          <PaymentMethodSelectStep />
        )}
        {activeStep === 2 && (
          <SuccessSaleStep />
        )}
      </Box>
    </Container>
  );
};
