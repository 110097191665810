import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid } from '@mui/material';

import { StepperNavigator } from '../components/StepperNavigator';
import { PaymentPanel } from '../components/payment-panel/PaymentPanel';
import { PointRedeemPanel } from '../components/PointRedeemPanel';

import { useSaleContext } from '../context/SaleContext';
import { useApiNotification } from '../../../../shared/hooks/useApiNotification';
import { BasketPanel } from '../components/BasketPanel';
import { usePayment } from '../hooks/usePayment';
import { saleActions } from '../saleActions';
import { SaleService } from '../SaleService';

export const PaymentMethodSelectStep = () => {
  const { t } = useTranslation('stockManager');

  const [loading, setLoading] = useState();

  const state = useSaleContext();
  const { dispatch } = state;

  const {
    showCreateError
  } = useApiNotification({});

  const onFinish = async () => {
    const request = {
      customerId: state.customer?.id,
      shopId: state.shop,
      extraDiscount: state.extraDiscount,
      redeemedPoints: state.redeemedPoints.rounded,
      paymentMethod: state.payment,
      comment: state.comment,
      products: state.basket.products.map(product => ({
        productId: product.id,
        quantity: product.quantity
      }))
    };

    try {
      setLoading(true);

      await SaleService.createSale(request);

      dispatch(saleActions.nextStep());
    }
    catch (error) {
      showCreateError(error);
    }
    finally {
      setLoading(false);
    }
  };

  const backButtonProps = {
    onClick: () => dispatch(saleActions.prevStep()),
    children: t('sale.payment-select-step.back')
  };

  const {
    hasDifference
  } = usePayment();

  const nextButtonProps = {
    onClick: onFinish,
    loading: loading,
    disabled: hasDifference(),
    children: t('sale.payment-select-step.next')
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={7}>
        <Box sx={{ height: '47.5vh', mb: 2 }}>
          <BasketPanel />
        </Box>
        <Box sx={{ height: '35vh' }}>
          <PointRedeemPanel />
        </Box>
      </Grid>

      <Grid item xs={5} style={{ display: 'flex', flexDirection: 'column', }}>
        <Box >
          <PaymentPanel />
        </Box>
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
          <StepperNavigator
            backButtonProps={backButtonProps}
            nextButtonProps={nextButtonProps}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
