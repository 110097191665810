import './SideMenuLayout.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { AppBar, Box, Drawer, IconButton, Typography, Toolbar } from '@mui/material';
import { Menu as MenuIcon, Person as PersonIcon } from '@mui/icons-material';
import { useCurrentUser } from '../../shared/hooks/useCurrentUser';
import { useCurrentBreakpoint } from '../../shared/contexts/BreakpointProvider';
import { TenantSelector } from '../authentication/components/TenantSelector';
import { SideMenu } from '../menu/SideMenu';

export const SideMenuLayout = () => {
  const { t } = useTranslation('core');

  const { hasAnyRole } = useCurrentUser();
  const { isMobile } = useCurrentBreakpoint();

  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  return (
    <div className="side-menu-layout">
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            size="large"
            color="inherit"
            className="menu-button"
            onClick={() => setSideMenuOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Box className="title-root">
            <Typography variant="h6">
              {isMobile() ? t('app.short-title') : t('app.title')}
            </Typography>
            <Typography variant="caption">
              {`v${process.env.REACT_APP_VERSION}`}
            </Typography>
          </Box>

          {hasAnyRole('emp-admin') && <TenantSelector />}

          <UserPanel />
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={sideMenuOpen}
        onClose={() => setSideMenuOpen(false)}
      >
        <SideMenu />
      </Drawer>
      <Box component="main" className="main-content">
        <Outlet />
      </Box>
    </div>
  );
};

const UserPanel = () => {
  const { name, username } = useCurrentUser();

  return (
    <Box className="user-panel">
      <PersonIcon fontSize="large" />
      <Box className="text">
        <Typography align="center" variant="subtitle2">{username}</Typography>
        <Typography align="center" variant="subtitle2">{name}</Typography>
      </Box>
    </Box>
  );
};
