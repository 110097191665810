import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Link } from '@mui/material';

export const MenuItem = (props) => {
  const {
    module: {
      to,
      children
    }
  } = props;

  if (children && children.length > 0) {
    return (
      <React.Fragment>
        {props.children}
      </React.Fragment>
    );
  }

  return (
    <Link to={to} component={RouterLink}>
      {props.children}
    </Link>
  );
};
