import { useTranslation } from 'react-i18next';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import { EmpNumberField } from '../../../../../shared/components';
import { useCustomerInfo } from '../../hooks/useCustomerInfo';
import { usePayment } from '../../hooks/usePayment';

const styles = {
  space: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

export const Discount = () => {
  const { t } = useTranslation('stockManager');

  const {
    name,
    discount
  } = useCustomerInfo();

  const {
    extraDiscount,
    setExtraDiscount
  } = usePayment();

  return (
    <>
      <Grid item xs={4} sx={{ pl: 1 }}>
        <Typography variant="h6" align="center">
          {t('sale.payment-panel.fields.name')}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box sx={styles.space}>
          <Typography variant="h6">
            {t('sale.payment-panel.fields.discount')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box sx={styles.space}>
          <Typography variant="h6">
            {t('sale.payment-panel.fields.extra-discount')}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={4} >
        <Typography align="center">
          {name()}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography align="center">
          {discount()}%
        </Typography>
      </Grid>
      <Grid item xs={4} sx={styles.center}>
        <EmpNumberField
          numberFieldProps={{
            sx: { width: '60px' },
            margin: 'none',
            size: 'small',
            placeholder: '0',
            value: extraDiscount || '',
            onChange: ({ target }) => setExtraDiscount(target.value),
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">%</InputAdornment>
              )
            }
          }}
        />
      </Grid>
    </>
  );
};
