import { TableView } from '../../../shared/components';
import { EmpTableV2 } from '../../../shared/components/table/EmpTableV2';
import { useCustomerList } from './useCustomerList';

export const CustomerList = () => {
  const { tableProps } = useCustomerList();

  return (
    <TableView width="md">
      <EmpTableV2 {...tableProps} />
    </TableView>
  );
};
