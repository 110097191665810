import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const PopupContext = createContext({
  confirm: (title, message, config = {}) => {}
});

export const usePopup = () => {
  return useContext(PopupContext);
};

export const PopupProvider = (props) => {
  const { children } = props;

  const [popupProps, setPopupProps] = useState({
    open: false,
    title: null,
    message: null,
    resolver: null,
    config: {}
  });

  const setPopup = (open, title, message, resolver, config) => {
    setPopupProps({ open, title, message, resolver, config });
  };

  const confirm = (title, message, config = {}) => {
    return new Promise(resolve => {
      setPopup(true, title, message, resolve, config);
    });
  };

  const handleClose = (action) => {
    const { resolver } = popupProps;

    setPopup(false, popupProps.title, popupProps.message, null, {});
    resolver(action);
  };

  const value = {
    confirm: confirm
  };

  return (
    <PopupContext.Provider value={value}>
      {children}
      <EmpPopup {...popupProps} onClose={handleClose} />
    </PopupContext.Provider>
  );
};

export const EmpPopup = (props) => {
  const { open, onClose, title, message } = props;

  const { t } = useTranslation('core');

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose('cancel')} color="primary">
          {t('general.cancel')}
        </Button>
        <Button onClick={() => onClose('confirm')} color="primary" autoFocus>
          {t('general.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
