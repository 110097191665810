import { useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Grid, IconButton } from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import { Editor, EmpDropdown, EmpPasswordField, EmpTextField, Form, FormItem } from '../../shared/components';
import { Roles } from './Roles';
import { useUserEditor } from './useUserEditor';
import { PasswordStrength } from './PasswordStrength';
import { FormSection } from '../../shared/components/form/FormItem';
import { validators } from '../../shared/validators/validators';

const styles = {
  empty: {
    display: 'flex',
    justifyContent: 'center'
  },
  actionColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

export const UserEditor = () => {
  const { t } = useTranslation('websiteUser');

  const {
    userId,
    form,
    tenant,
    handleSubmit
  } = useUserEditor();

  const moduleDropdownProps = {
    options: tenant.modules.map(({ id, name }) => ({
      value: id,
      label: name
    }))
  };

  return (
    <Editor
      width="md"
      headerProps={{
        title: t('editor.title'),
        backLinkTo: '/website-user'
      }}
      submitProps={{
        loading: form.formState.isSubmitting,
        onSubmit: handleSubmit,
        children: t(`actions.${ userId ? 'update' : 'create' }.label`)
      }}
    >
      <Form onSubmit={handleSubmit} columns={16} spacing={2}>
        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            controllerProps={{
              name: 'name',
              control: form.control
            }}
            textFieldProps={{
              label: t('editor.fields.name')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            controllerProps={{
              name: 'username',
              control: form.control
            }}
            textFieldProps={{
              label: t('editor.fields.username')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 8]}>
          <EmpTextField
            controllerProps={{
              name: 'email',
              control: form.control
            }}
            textFieldProps={{
              label: t('editor.fields.email')
            }}
          />
        </FormItem>

        {!userId && (
          <FormSection title={t('editor.fields.password.title')} xs={[1, 14, 1]} sm={[2, 12, 2]}>
            <PasswordEditor form={form} />
          </FormSection>
        )}

        <FormSection title={t('editor.fields.modules.title')} xs={[1, 14, 1]} sm={[2, 12, 2]}>
          <ModuleEditor
            form={form}
            modules={tenant.modules}
            moduleDropdownProps={moduleDropdownProps}
          />
        </FormSection>
      </Form>
    </Editor>
  );
};

const PasswordEditor = ({ form }) => {
  const { t } = useTranslation('websiteUser');

  const password = useWatch({
    name: 'password',
    control: form.control
  });

  const validationResult = validators.password(password);

  return (
    <Grid container columns={16} spacing={2}>
      <FormItem xs={[1, 14, 1]} sm={[0, 16, 0]}>
        <PasswordStrength validationResult={validationResult} />
      </FormItem>

      <FormItem xs={[1, 14, 1]} sm={[0, 8, 0]}>
        <EmpPasswordField
          controllerProps={{
            name: 'password',
            control: form.control
          }}
          textFieldProps={{
            label: t('editor.fields.password.fields.password')
          }}
        />
      </FormItem>

      <FormItem xs={[1, 14, 1]} sm={[0, 8, 0]}>
        <EmpPasswordField
          controllerProps={{
            name: 'confirmPassword',
            control: form.control
          }}
          textFieldProps={{
            label: t('editor.fields.password.fields.confirm-password')
          }}
        />
      </FormItem>
    </Grid>
  );
};

const ModuleEditor = ({ form, modules, moduleDropdownProps }) => {
  const { t } = useTranslation('websiteUser');

  const { fields, append, remove } = useFieldArray({
    name: 'modules',
    control: form.control
  });

  if (!fields?.length) {
    return (
      <Grid container columns={16} spacing={2}>
        <FormItem xs={[0, 16, 0]} sx={styles.empty}>
          <Button
            variant="text"
            color="inherit"
            startIcon={<AddIcon />}
            onClick={() => append({})}
          >
            {t('editor.fields.modules.actions.add')}
          </Button>
        </FormItem>
      </Grid>
    );
  }

  return fields.map((field, index) => (
    <Grid key={field.id} container columns={16} spacing={2}>
      <ModuleItem
        control={form.control}
        modules={modules}
        index={index}
        fieldCount={fields.length}
        append={append}
        remove={remove}
        moduleDropdownProps={moduleDropdownProps}
      />
    </Grid>
  ));
};

const ModuleItem = ({ control, modules, index, fieldCount, append, remove, moduleDropdownProps }) => {
  const { t } = useTranslation('websiteUser');

  const moduleId = useWatch({
    name: `modules.${index}.id`,
    control: control
  });

  let roles = [];
  const module = modules.find(m => m.id === moduleId);

  if (module) {
    roles = Roles[module.abbreviation].map(role => ({
      value: role.id,
      label: role.name
    }));
  }

  return (
    <>
      <FormItem xs={[0, 16, 0]} md={[0, 8, 0]}>
        <EmpDropdown
          controllerProps={{
            name: `modules.${index}.id`,
            control: control
          }}
          dropdownProps={{
            label: t('editor.fields.modules.fields.name'),
            ...moduleDropdownProps
          }}
        />
      </FormItem>

      <FormItem xs={[0, 16, 0]} md={[0, 5, 0]}>
        <EmpDropdown
          controllerProps={{
            name: `modules.${index}.role`,
            control: control
          }}
          dropdownProps={{
            label: t('editor.fields.modules.fields.role'),
            options: roles
          }}
        />
      </FormItem>

      <FormItem xs={[0, 16, 0]} sm={[0, 3, 0]} md={[0, 3, 0]} sx={styles.actionColumn}>
        {(index === fieldCount - 1 && moduleDropdownProps.options.length > fieldCount) && (
          <IconButton onClick={() => append({})}>
            <AddIcon />
          </IconButton>
        )}
        <IconButton color="error" onClick={() => remove(index, moduleId)}>
          <DeleteIcon />
        </IconButton>
      </FormItem>
    </>
  );
};
