import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OtherHouses as WarehouseIcon } from '@mui/icons-material';
import { WarehouseActionsMenu } from './WarehouseActionsMenu';
import { WarehouseFilterForm } from './WarehouseFilterForm';
import { WarehouseService } from './WarehouseService';
import { useTableV2 } from '../../../shared/components/table/hooks/useTableV2';

const columnsFactory = (t) => [{
  header: t('stockManager:warehouse.list.columns.id'),
  field: 'id',
  width: '10%'
}, {
  header: t('stockManager:warehouse.list.columns.name'),
  field: 'name',
  width: '35%'
}, {
  header: t('stockManager:warehouse.list.columns.address'),
  field: 'address',
  width: '50%',
  render: ({ address }) => address || '-'
}, {
  render: (row) => <WarehouseActionsMenu warehouse={row} />,
  width: '5%'
}];

export const useWarehouseList = () => {
  const { t } = useTranslation('stockManager');

  const columns = useMemo(() => columnsFactory(t), [t]);

  const tableProps = useTableV2({
    columns,
    title: t('warehouse.list.title'),
    icon: <WarehouseIcon />,
    dataSource: WarehouseService.getWarehousesPaginated,
    components: {
      toolbar: {
        createButtonProps: {
          to: '/stock-manager/warehouses/editor',
          children: t('warehouse.actions.create.label')
        },
        filterModalProps: {
          maxWidth: 'sm',
          title: t('warehouse.list.filter.title'),
          children: WarehouseFilterForm
        }
      }
    }
  });

  return {
    tableProps
  };
};
