import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';
import { useSaleContext } from '../context/SaleContext';
import { saleActions } from '../saleActions';
import { SaleService } from '../SaleService';

const styles = {
  root: {
    height: '85vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textWrapper: {
    ml: 2,
    pb: 2.5,
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    fontSize: 180,
    color: '#4caf50'
  },
  text: {
    fontSize: '4rem'
  },
  leftContent: {
    paddingLeft: '0.5rem',
    textTransform: 'lowercase'
  }
};

export const SuccessSaleStep = () => {
  const { t } = useTranslation('stockManager');

  const { dispatch, shop } = useSaleContext();
  const [counter, setCounter] = useState(100);

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter(prev => prev -= 20);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (counter <= 0) {
      handleClick();
    }
  }, [counter]);

  // TODO - redundant code
  const handleClick = () => {
    dispatch(saleActions.reset());
    dispatch(saleActions.setProductCategoriesLoading(true));

    SaleService.getProductCategories(shop)
      .then(products => {
        dispatch(saleActions.setProductCategoriesSuccess(products));
      })
      .catch(error => {
        dispatch(saleActions.setProductCategoriesError(error));
      });
  };

  const content1 = t('sale.success-sale-step.content-1');
  const content2 = t('sale.success-sale-step.content-2').replace('{x}', counter / 20);

  return (
    <Box sx={styles.root}>
      <CheckCircleOutlineIcon sx={styles.icon} />
      <Box sx={styles.textWrapper}>
        <Typography variant="h4">
          {t('sale.success-sale-step.title')}
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Button size="small" variant="text" onClick={handleClick}>
            <Box component="span">{content1}</Box>
            <Box component="span" sx={styles.leftContent}>{content2}</Box>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
