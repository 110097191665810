import './FullPageView.scss';
import { Box } from '@mui/material';
import { EmpBreadcrumb } from '../EmpBreadcrumb';

export const FullPageView = ({ hideBreadcrumb = false, children }) => {
  return (
    <Box className="full-page-view">
      {!hideBreadcrumb && (
        <Box className="breadcrumb-container">
          <EmpBreadcrumb />
        </Box>
      )}
      {children}
    </Box>
  )
};
