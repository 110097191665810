import { TableView } from '../../../shared/components';
import { EmpTableV2 } from '../../../shared/components/table/EmpTableV2';
import { useProductUnitList } from './useProductUnitList';

export const ProductUnitList = () => {
  const { tableProps } = useProductUnitList();

  return (
    <TableView width="md">
      <EmpTableV2 {...tableProps} />
    </TableView>
  );
};
