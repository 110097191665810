import { filterUtils } from "../components/table/hooks/useFilterUtils";

const parse = (rawFilters) => {
  if (!rawFilters) {
    return null;
  }

  const parsedFilters = {};
  const filters = decodeURIComponent(rawFilters).split(';');

  for (const filter of filters) {
    if (filter) {
      const parts = filter.split('|');

      parsedFilters[parts[0]] = {
        operator: parts[1],
        value: parts[2]
      };
    }
  }

  return parsedFilters;
};

export const FilterHelper = {
  parse,
  buildFilter: filterUtils.buildFilter,
  build: filterUtils.buildFilterV2
};
