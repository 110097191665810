import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers';
import { InputAdornment, TextField } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';

const styles = {
  disabled: {
    '& label.Mui-disabled': theme => ({
      color: theme.palette.text.primary
    }),
    '& div > input.Mui-disabled': theme => ({
      color: 'red',
      WebkitTextFillColor: theme.palette.text.primary
    }),
    '& div.Mui-disabled:before': {
      borderBottomStyle: 'solid'
    }
  }
};

export const EmpDatePicker = ({ controllerProps, datePickerProps }) => {
  if (controllerProps) {
    return <EmpDatePickerForm controllerProps={controllerProps} datePickerProps={datePickerProps} />;
  }
  else {
    return <EmpDatePickerRegular datePickerProps={datePickerProps} />;
  }
};

const EmpDatePickerRegular = ({ datePickerProps }) => {
  const { i18n } = useTranslation();

  const {
    sx = {},
    helperText,
    disabled = false,
    value,
    onChange,
    ...restDatePickerProps
  } = datePickerProps;

  const dateFormat = i18n.language === 'hu' ? 'yyyy.MM.dd.' : 'dd/MM/yyyy';

  return (
    <DatePicker
      {...restDatePickerProps}
      disabled={disabled}
      value={value || null}
      onChange={onChange || (() => {})}
      inputFormat={dateFormat}
      renderInput={(textFieldProps) => (
        <EmpDatePickerTextfield
          {...textFieldProps}
          sx={sx}
          disabled={disabled}
          helperText={helperText || ' '}
        />
      )}
    />
  );
};

const EmpDatePickerForm = ({ controllerProps, datePickerProps }) => {
  const { i18n } = useTranslation();

  const {
    sx = {},
    disabled = false,
    ...restDatePickerProps
  } = datePickerProps;

  const dateFormat = i18n.language === 'hu' ? 'yyyy.MM.dd.' : 'dd/MM/yyyy';

  return (
    <Controller
      {...controllerProps}
      render={({ field, fieldState }) => (
        <DatePicker
          {...field}
          {...restDatePickerProps}
          disabled={disabled}
          inputFormat={dateFormat}
          renderInput={(params) => (
            <EmpDatePickerTextfield
              {...params}
              sx={sx}
              disabled={disabled}
              error={fieldState.invalid}
              helperText={fieldState.error?.message || ' '}
            />
          )}
        />
      )}
    />
  );
};

const EmpDatePickerTextfield = (props) => {
  const getTextfieldProps = (textFieldProps) => {
    const { sx, disabled } = textFieldProps;

    if (!disabled) {
      return textFieldProps;
    }

    return {
      ...textFieldProps,
      sx: !disabled ? sx : { ...sx, ...styles.disabled },
      InputProps: {
        endAdornment: (
          <InputAdornment position='end' sx={{ color: 'text.secondary' }}>
            <EventIcon />
          </InputAdornment>
        )
      }
    }
  };

  return <TextField {...getTextfieldProps(props)} />;
};
