import { useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { EmpTextField } from './EmpTextField';

export const EmpPasswordField = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  return (
    <EmpTextField
      {...props}
      textFieldProps={{
        ...props.textFieldProps,
        InputProps: {
          type: showPassword ? 'text' : 'password',
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClick}
                onMouseDown={handleMouseDown}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }
      }}
    />
  );
};
