import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EmpDropdown } from '../..';
import { EmpTextField } from '../../EmpTextField';

const getDropdownOptions = (t, type, nullable) => {
  let options = [];

  if (type === 'string') {
    options = [
      { label: t('filter.operators.string.equals'), value: 'equals' },
      { label: t('filter.operators.string.contains'), value: 'contains' }
    ];
  }
  else if (type === 'number') {
    options = [
      { label: t('filter.operators.number.range'), value: 'range' },
      { label: t('filter.operators.number.equals'), value: 'equals' },
      { label: t('filter.operators.number.lessThan'), value: 'lessThan' },
      { label: t('filter.operators.number.lessThanOrEqual'), value: 'lessThanOrEqual' },
      { label: t('filter.operators.number.greaterThan'), value: 'greaterThan' },
      { label: t('filter.operators.number.greaterThanOrEqual'), value: 'greaterThanOrEqual' }
    ];
  }
  else if (type === 'date') {
    options = [
      { label: t('filter.operators.date-time.range'), value: 'range' },
      { label: t('filter.operators.date-time.equals'), value: 'equals' },
      { label: t('filter.operators.date-time.lessThan'), value: 'lessThan' },
      { label: t('filter.operators.date-time.lessThanOrEqual'), value: 'lessThanOrEqual' },
      { label: t('filter.operators.date-time.greaterThan'), value: 'greaterThan' },
      { label: t('filter.operators.date-time.greaterThanOrEqual'), value: 'greaterThanOrEqual' }
    ];
  }
  else if (type === 'bool') {
    options = [
      { label: t('filter.operators.boolean.is-true'), value: 'isTrue' },
      { label: t('filter.operators.boolean.is-false'), value: 'isFalse' }
    ];
  }

  if (nullable) {
    options.splice(0, 0, { label: t('filter.operators.nullable.is-not-empty'), value: 'isNotEmpty' });
    options.splice(0, 0, { label: t('filter.operators.nullable.is-empty'), value: 'isEmpty' });
  }

  return options;
};

const OperatorDropdownInternal = ({ type, name, disabled, nullable, value, onChange }) => {
  const { t } = useTranslation('core');

  const options = useMemo(() => {
    return getDropdownOptions(t, type, nullable)
  }, [t, type, nullable]);

  return type === 'list' ? (
    <EmpTextField
      textFieldProps={{
        name: name,
        label: ' ',
        value: t('filter.operators.date-time.equals'),
        disabled: true
      }}
    />
  ) : (
    <EmpDropdown
      dropdownProps={{
        options: options,
        name: name,
        value: value,
        onChange: onChange
      }}
    />
  )
};

export const OperatorDropdown = React.memo(OperatorDropdownInternal, (prev, next) => {
  if (prev.type !== next.type) {
    return false;
  }

  if (prev.name !== next.name) {
    return false;
  }

  if (prev.value !== next.value) {
    return false;
  }

  return true;
});
