import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { Typography } from '@mui/material';
import { Editor, EmpTextField, Form, FormItem } from '../../../shared/components';
import { useInvoiceViewer } from './useInvoiceViewer';
import { priceFormatter } from '../utils/PriceFormatter';

export const InvoiceViewer = () => {
  const { t, i18n } = useTranslation('stockManager');

  const {
    invoice
  } = useInvoiceViewer();

  return (
    <Editor
      width="lg"
      headerProps={{
        title: t('invoice.viewer.title'),
        backLinkTo: '/stock-manager/invoice'
      }}
    >
      {/* ID */}
      <Form columns={16} spacing={2}>
        <FormItem xs={[1, 14, 1]} sm={[1, 3, 0]}>
          <EmpTextField
            textFieldProps={{
              label: t('invoice.viewer.fields.id'),
              value: invoice?.invoiceSerial || '-',
              disabled: true
            }}
          />
        </FormItem>

        {/* Invoice Number */}
        <FormItem xs={[1, 14, 1]} sm={[0, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              label: t('invoice.viewer.fields.invoice-number'),
              value: invoice?.invoiceNumber || '-',
              disabled: true
            }}
          />
        </FormItem>

        {/* Date */}
        <FormItem xs={[1, 14, 1]} sm={[0, 5, 1]}>
          <EmpTextField
            textFieldProps={{
              label: t('invoice.viewer.fields.date'),
              value: formatters.date(invoice?.date, i18n),
              disabled: true
            }}
          />
        </FormItem>

        {/* Seller */}
        <FormItem xs={[1, 14, 1]} sm={[1, 4, 0]}>
          <EmpTextField
            textFieldProps={{
              label: t('invoice.viewer.fields.seller'),
              value: invoice?.employeeName || '-',
              disabled: true
            }}
          />
        </FormItem>

        {/* Shop */}
        <FormItem xs={[1, 14, 1]} sm={[0, 3, 0]}>
          <EmpTextField
            textFieldProps={{
              label: t('invoice.viewer.fields.shop-name'),
              value: invoice?.shopName || '-',
              disabled: true
            }}
          />
        </FormItem>

        {/* Shop Address */}
        <FormItem xs={[1, 14, 1]} sm={[0, 7, 1]}>
          <EmpTextField
            textFieldProps={{
              label: t('invoice.viewer.fields.shop-address'),
              value: invoice?.shopAddress || '-',
              disabled: true
            }}
          />
        </FormItem>

        {/* Customer */}
        <FormItem xs={[1, 14, 1]} sm={[1, 4, 0]}>
          <EmpTextField
            textFieldProps={{
              label: t('invoice.viewer.fields.customer-name'),
              value: invoice?.customerName || '-',
              disabled: true
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 10, 1]}>
          <EmpTextField
            textFieldProps={{
              label: t('invoice.viewer.fields.customer-address'),
              value: formatters.customerAddress(invoice || {}),
              disabled: true
            }}
          />
        </FormItem>

        {/* Payment Mode */}
        <FormItem xs={[1, 14, 1]} sm={[1, 2, 0]}>
          <EmpTextField
            textFieldProps={{
              label: t('invoice.viewer.fields.payment-mode'),
              value: !invoice?.paymentMode ? '-' : t(`invoice.viewer.payment-modes.${invoice?.paymentMode?.toString().toLowerCase()}`) || '',
              disabled: true
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 2, 0]}>
          <EmpTextField
            textFieldProps={{
              label: t('invoice.viewer.fields.payment-cash'),
              value: formatters.payment(invoice?.paymentCash, invoice?.currencyShortName),
              disabled: true
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 2, 0]}>
          <EmpTextField
            textFieldProps={{
              label: t('invoice.viewer.fields.payment-card'),
              value: formatters.payment(invoice?.paymentCard, invoice?.currencyShortName),
              disabled: true
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 2, 7]}>
          <EmpTextField
            textFieldProps={{
              label: t('invoice.viewer.fields.payment-transfer'),
              value: formatters.payment(invoice?.paymentTransfer, invoice?.currencyShortName),
              disabled: true
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[1, 14, 1]}>
          <Typography variant="h5">
            {t('invoice.viewer.fields.invoice-element.title')}
          </Typography>
        </FormItem>
        {invoice?.invoiceElements?.map(invoiceElement => (
          <InvoiceElementViewer
            key={invoiceElement.id}
            invoice={invoice}
            invoiceElement={invoiceElement}
          />
        ))}

        <FormItem xs={[1, 14, 1]} sm={[1, 14, 1]}>
          <Typography variant="h5">
            {t('invoice.viewer.fields.sum.title')}
          </Typography>
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[5, 2, 0]}>
          <Typography variant="h5">
            <EmpTextField
              textFieldProps={{
                label: t('invoice.viewer.fields.discount.customer'),
                value: formatters.percentage(invoice?.discountPercentage),
                disabled: true
              }}
            />
          </Typography>
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 2, 0]}>
          <Typography variant="h5">
            <EmpTextField
              textFieldProps={{
                label: t('invoice.viewer.fields.discount.extra'),
                value: formatters.percentage(invoice?.extraDiscountPercentage),
                disabled: true
              }}
            />
          </Typography>
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 2, 0]}>
          <Typography variant="h5">
            <EmpTextField
              textFieldProps={{
                label: t('invoice.viewer.fields.discount.redeemed-points'),
                value: invoice?.redeemedPoints || '-',
                disabled: true
              }}
            />
          </Typography>
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 2, 0]}>
          <Typography variant="h5">
            <EmpTextField
              textFieldProps={{
                label: t('invoice.viewer.fields.sum.total-received-points'),
                value: invoice?.receivedPoints || '-',
                disabled: true
              }}
            />
          </Typography>
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 2, 1]}>
          <Typography variant="h5">
            <EmpTextField
              textFieldProps={{
                label: t('invoice.viewer.fields.sum.total-price'),
                value: formatters.payment(invoice?.totalAmount, invoice?.currencyShortName),
                disabled: true
              }}
            />
          </Typography>
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[12, 3, 0]}>
          <Typography variant="h5">
            <EmpTextField
              textFieldProps={{
                label: t('invoice.viewer.fields.discount.total-discounted-price'),
                value: formatters.payment(invoice?.totalDiscountedAmount, invoice?.currencyShortName),
                disabled: true
              }}
            />
          </Typography>
        </FormItem>
      </Form>
    </Editor>
  );
};

const InvoiceElementViewer = ({ invoice, invoiceElement }) => {
  const { t } = useTranslation('stockManager');

  return (
    <>
      <FormItem xs={[1, 14, 1]} sm={[1, 4, 0]}>
        <EmpTextField
          textFieldProps={{
            label: t('invoice.viewer.fields.invoice-element.product'),
            value: invoiceElement?.productName || '-',
            disabled: true
          }}
        />
        </FormItem>

      <FormItem xs={[1, 14, 1]} sm={[0, 2, 0]}>
        <EmpTextField
          textFieldProps={{
            label: t('invoice.viewer.fields.invoice-element.quantity'),
            value: formatters.quantity(invoiceElement?.quantity, invoiceElement),
            disabled: true
          }}
        />
      </FormItem>

      <FormItem xs={[1, 14, 1]} sm={[0, 2, 0]}>
        <EmpTextField
          textFieldProps={{
            label: t('invoice.viewer.fields.invoice-element.unit-price'),
            value: formatters.payment(invoiceElement?.unitPrice, invoice?.currencyShortName),
            disabled: true
          }}
        />
      </FormItem>

      <FormItem xs={[1, 14, 1]} sm={[0, 2, 0]}>
        <EmpTextField
          textFieldProps={{
            label: t('invoice.viewer.fields.invoice-element.received-points'),
            value: invoiceElement?.pointsPerUnit || '-',
            disabled: true
          }}
        />
      </FormItem>

      <FormItem xs={[1, 14, 1]} sm={[0, 2, 0]}>
        <EmpTextField
          textFieldProps={{
            label: t('invoice.viewer.fields.invoice-element.total-received-points'),
            value: invoiceElement?.totalReceivedPoints || '-',
            disabled: true
          }}
        />
      </FormItem>

      <FormItem xs={[1, 14, 1]} sm={[0, 2, 1]}>
        <EmpTextField
          textFieldProps={{
            label: t('invoice.viewer.fields.invoice-element.total-price'),
            value: formatters.payment(invoiceElement?.totalAmount, invoice?.currencyShortName),
            disabled: true
          }}
        />
      </FormItem>
    </>
  );
};

const formatters = {
  customerAddress: (invoice) => {
    let formatted = '';

    if (invoice.customerZip) {
      formatted += invoice.customerZip;
    }

    if (invoice.customerCity) {
      formatted += ` ${invoice.customerCity}`;
    }

    if (invoice.customerAddress) {
      formatted += ` ${invoice.customerAddress}`;
    }

    return formatted || '-';
  },

  date: (date, i18n) => {
    if (!date) return '-';
    return DateTime
      .fromISO(date)
      .setLocale(i18n.language)
      .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
  },

  percentage: (percentage) => {
    if (!percentage) return '-';
    return `${percentage}%`;
  },

  payment: (payment, symbol) => {
    if (!payment) return '-';
    return symbol ? `${priceFormatter(payment)} ${symbol}` : priceFormatter(payment);
  },

  quantity: (quantity, invoiceElement) => {
    if (!quantity) return '-';
    return `${quantity} ${invoiceElement?.productUnitName}`;
  }
};
