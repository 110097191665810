import {
  Box,
  CircularProgress,
  IconButton
} from '@mui/material';

const styles = {
  root: {
    position: 'relative'
  },
  progress: {
    position: 'absolute',
    zIndex: 1,
  }
};

export const EmpLoadingIconButton = (props) => {
  const {
    loading,
    children,
    ...rest
  } = props;

  return (
    <Box component="span" sx={styles.root}>
      <IconButton {...rest}>
        {children}
        {loading && (
          <CircularProgress sx={styles.progress} />
        )}
      </IconButton>
    </Box>
  );
};
