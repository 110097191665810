import { Box, Container, Fade, Paper } from '@mui/material';
import { EmpBreadcrumb, EditorHeader, EditorSubmit } from '..';
import { EditorReset } from './EditorReset';

const styles = {
  root: {
    //padding: (theme) => theme.spacing(2, 0)
    paddingX: {
      xs: 1,
      md: 2
    },
    paddingY: {
      xs: 1,
      md: 2
    }
  },
  breadcrumbRoot: {
    marginTop: 1,
    marginBottom: {
      xs: 1,
      md: 2
    }
  },
  headerRoot: {
    padding: {
      xs: 0.25,
      md: 1
    }
  },
  contentRoot: {
    paddingX: {
      xs: 1,
      md: 4
    },
    paddingY: {
      xs: 2,
      md: 6
    }
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  footerRoot: {
    padding: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderTop: '1px solid rgb(0 0 0 / 14%)',
    '& > *': {
      mr: 1
    }
  }
};

export const Editor = (props) => {
  const {
    hideBreadcrumb = false,
    width = 'xl',
    classes = {},
    headerProps = null,
    submitProps = null,
    resetProps = null,
    footer = null,
    // loading = false, // TODO
    children
  } = props;

  const mergedStyles = {
    ...styles,
    ...classes
  };

  return (
    <Container maxWidth={width} sx={mergedStyles.root}>
      {!hideBreadcrumb && (
        <Box sx={mergedStyles.breadcrumbRoot}>
          <EmpBreadcrumb />
        </Box>
      )}
      <Paper sx={mergedStyles.cardRoot}>
        {headerProps && (
          <EditorHeader sx={styles.headerRoot} {...headerProps} />
        )}
        <Box sx={mergedStyles.contentRoot}>
          <Fade in={true} timeout={1000}>
            <Box>
              {children}
            </Box>
          </Fade>
        </Box>
          {(submitProps || resetProps || footer) && (
            <Box sx={styles.footerRoot}>
              {resetProps && (
                <Box>
                  <EditorReset {...resetProps} />
                </Box>
              )}
              {submitProps && (
                <Box>
                  <EditorSubmit {...submitProps} />
                </Box>
              )}
              {footer}
            </Box>
          )}
      </Paper>
    </Container>
  );
};
