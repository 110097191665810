import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

import { Box, Tooltip, Typography } from '@mui/material';

import { useState } from 'react';
import { useNotification } from './EmpNotification';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    cursor: 'pointer',
    '&:hover': {
      color: 'gray'
    }
  },
  icon: (hover, copied) => ({
    ml: 1,
    visibility: hover ? 'visible' : 'hidden',
    color: copied ? 'green' : undefined,
    fontSize: 16
  })
};

export const EmpText = ({ copyable, ellipsis, ...rest }) => {
  const [hover, setHover] = useState(false);
  const [copied, setCopied] = useState(false);

  const { success } = useNotification();

  const Icon = copied ? CheckIcon : ContentCopyIcon;

  const handleCopied = () => {
    setCopied(true);
    success('Copied to clipboard');
    //setTimeout(() => setCopied(false), 1000);
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
    setCopied(false)
  };

  let { children } = rest;

  if (!children) {
    children = '-';
  }

  if (ellipsis) {
    if (children.length > ellipsis) {
      children = (
        <Tooltip title={children} enterDelay={500}>
          <Box>
            {children.substring(0, ellipsis) + '...'}
          </Box>
        </Tooltip>
      )
    }
  }

  return (
    <Box sx={styles.root}>
      <Typography
        {...rest}
        sx={copyable ? styles.title : undefined}
        onClick={copyable ? handleCopied : undefined}
        onMouseEnter={copyable ? handleMouseEnter : undefined}
        onMouseLeave={copyable ? handleMouseLeave : undefined}
      >
        {children}
      </Typography>
      {copyable && <Icon sx={styles.icon(hover, copied)} />}
    </Box>
  );
}
