import { useTranslation } from 'react-i18next';
import { Editor, EmpTextField, Form, FormItem } from '../../../shared/components';
import { useManufacturerEditor } from './useManufacturerEditor';

export const ManufacturerEditor = () => {
  const { t } = useTranslation('stockManager');

  const {
    manufacturerId,
    form,
    handleSubmit
  } = useManufacturerEditor();

  return (
    <Editor
      width="sm"
      headerProps={{
        title: t('manufacturer.editor.title'),
        backLinkTo: '/stock-manager/manufacturer'
      }}
      submitProps={{
        loading: form.formState.isSubmitting,
        onSubmit: handleSubmit,
        children: t(`manufacturer.actions.${ manufacturerId ? 'update' : 'create' }.label`)
      }}
    >
      <Form onSubmit={handleSubmit} columns={16} spacing={2}>
        <FormItem xs={[1, 14, 1]} sm={[2, 8, 6]}>
          <EmpTextField
            controllerProps={{
              name: 'name',
              control: form.control
            }}
            textFieldProps={{
              label: t('manufacturer.editor.fields.name')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 7, 1]} sm={[2, 4, 10]}>
          <EmpTextField
            controllerProps={{
              name: 'shortName',
              control: form.control
            }}
            textFieldProps={{
              label: t('manufacturer.editor.fields.short-name')
            }}
          />
        </FormItem>
      </Form>
    </Editor>
  );
};
