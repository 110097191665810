import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';

import './index.css';
import App from './App';

const root = createRoot(document.getElementById('root'));

root.render(
  <CssBaseline enableColorScheme>
    <App />
  </CssBaseline>
);
