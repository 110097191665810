import { Link as RouterLink } from 'react-router-dom';

import {
  Collapse,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';

import { EmpStyles } from '../../../shared';
import { MenuItem } from './MenuItem';
import { useState } from 'react';
import { MenuExpander } from './MenuExpander';

export const MenuCollapse = ({ open, children }) => {
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {children.map((menuItem, index) => (
          menuItem.visible && <Compp key={index} menuItem={menuItem} index={index} />
        ))}
      </List>
    </Collapse>
  );
};

const Compp = ({ menuItem, index }) => {
  const [open, setOpen] = useState(menuItem.active);

  if (menuItem.children) {
    return (
      <MenuItem key={menuItem.label} module={menuItem}>
        <ListItem
          button
          sx={EmpStyles.menu.nested}
          onClick={() => setOpen(!open)}
        >
          <ListItemIcon>
            {menuItem.icon}
          </ListItemIcon>
          <ListItemText primary={menuItem.label} />
          <MenuExpander open={open} subMenus={menuItem.children} />
        </ListItem>
        <MenuCollapse open={open} children={menuItem.children} />
      </MenuItem>
    );
  }

  return (
    menuItem.visible && (
      <Link key={index} to={menuItem.to} component={RouterLink}>
        <ListItem button sx={menuItem.active ? { ...EmpStyles.menu.nested2(menuItem.nesting || 1), ...EmpStyles.menu.active } : EmpStyles.menu.nested2(menuItem.nesting || 1)}>
          <ListItemIcon>{menuItem.icon}</ListItemIcon>
          <ListItemText primary={menuItem.label}/>
        </ListItem>
      </Link>
    )
  );
};
