import { fileDownloadHelper, ServiceBase } from '../../../shared';

const BASE_URL = '/stock-manager/documents';
const ATT_BASE_URL = '/stock-manager/attachments';

export const DocumentService = {
  async getDocumentsPaginated(parameters) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}?${ServiceBase.makeQueryString(parameters)}`))
      .json();
  },

  async getDocuments(queryString) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}?${queryString}`))
      .json();
  },

  async getDocumentById(documentId) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/${documentId}`))
      .json();
  },

  async createDocument(document) {
    return await (await ServiceBase
      .postAsync(BASE_URL, document))
      .json();
  },

  async getAttachments(documentId) {
    return await (await ServiceBase
      .getAsync(`${ATT_BASE_URL}/document/${documentId}`))
      .json();
  },

  async downloadAttachment(attachmentId) {
    const response = await ServiceBase.getAsync(`${ATT_BASE_URL}/download/${attachmentId}`);
    return await fileDownloadHelper.parseFileResponse(response, 'attachment');
  }
};
