import { Link } from 'react-router-dom';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    backgroundColor: (theme) => theme.palette.secondary.main
  },
  titleRoot: {
    display: 'flex',
    alignItems: 'center'
  },
  actionsRoot: {
    marginRight: 1.5
  },
  backIcon: {
    marginRight: 2
  }
};

export const EditorHeader = (props) => {
  const {
    sx,
    title,
    actions,
    backLinkTo
  } = props;

  return (
    <Paper sx={{ ...styles.root, ...sx }}>
      <Box sx={styles.titleRoot}>
        <Link to={backLinkTo || ''}>
          <IconButton size="large" sx={styles.backIcon}>
            <ArrowBackIcon />
          </IconButton>
        </Link>
        <Typography variant="h5">
          {title}
        </Typography>
      </Box>
      <Box sx={styles.actionsRoot}>
        {actions}
      </Box>
    </Paper>
  );
};
