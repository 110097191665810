import { createContext, useContext, useReducer } from 'react';
import { initialState, saleReducer } from '../saleReducer';

const SaleContext = createContext(initialState);

export const useSaleContext = () => {
  return useContext(SaleContext);
};

export const SaleContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(saleReducer, initialState);

  return (
    <SaleContext.Provider value={{ ...state, dispatch }}>
      {children}
    </SaleContext.Provider>
  );
};
