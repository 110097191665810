import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { EmpTextField } from '../../../shared/components';

export const GuestSearch = ({ onChange }) => {
  const { t } = useTranslation('evCharger');
  const [guest, setGuest] = useState(null);

  return (
    <EmpTextField
      textFieldProps={{
        placeholder: t('guest-charging.list.guest-filter'),
        value: guest || '',
        onChange: ({ currentTarget }) => {
          setGuest(currentTarget.value);
          onChange(currentTarget.value);
        },
        FormHelperTextProps: {
          sx: { display: 'none' }
        },
        InputProps: {
          startAdornment: (
            <SearchIcon
              sx={{
                mr: 1,
                color: '#9e9e9e'
              }}
            />
          )
        }
      }}
    />
  );
};
