import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ShopService } from './ShopService';

export const useShopViewer = () => {
  const { shopId } = useParams();
  const [shop, setShop] = useState(null);

  useEffect(() => {
    ShopService.getShopById(shopId)
      .then(shop => {
        setShop(shop);
      });
  }, [shopId]);

  return {
    shop
  };
};
