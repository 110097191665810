import { ActionsMenu } from '../../../shared/components';

export const CustomerActionsMenu = ({ customer, onDelete, disabledActions = [] }) => {
  const actions = {};

  if (!disabledActions.includes('datasheet')) {
    actions.datasheet = {
      to: `/stock-manager/customer/${customer.id}`
    };
  }

  if (!disabledActions.includes('editor')) {
    actions.editor = {
      to: `/stock-manager/customer/editor/${customer.id}`
    };
  }

  if (!disabledActions.includes('delete')) {
    actions.delete = {
      url: `/stock-manager/customers/${customer.id}`,
      successNavigationUrl: '/stock-manager/customer',
      callback: onDelete
    };
  }

  return <ActionsMenu actions={actions} />;
};
