import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain as DomainIcon } from '@mui/icons-material';
import { useTableV2 } from '../../../shared/components/table/hooks/useTableV2';
import { ProductUnitActionsMenu } from './ProductUnitActionsMenu';
import { ProductUnitFilterForm } from './ProductUnitFilterForm';
import { ProductUnitService } from './ProductUnitService';

const columnsFactory = (t) => [{
  header: t('product-unit.list.columns.id'),
  field: 'id',
  width: '30%'
}, {
  header: t('product-unit.list.columns.name'),
  field: 'name',
  width: '60%'
}, {
  width: '10%',
  render: (row, { refresh }) => (
    <ProductUnitActionsMenu
      productUnit={row}
      onDelete={refresh}
    />
  )
}];

export const useProductUnitList = () => {
  const { t } = useTranslation('stockManager');

  const columns = useMemo(() => columnsFactory(t), [t]);

  const tableProps = useTableV2({
    columns,
    title: t('product-unit.list.title'),
    icon: <DomainIcon />,
    dataSource: ProductUnitService.getProductUnitsPaginated,
    components: {
      toolbar: {
        createButtonProps: {
          to: '/stock-manager/product-unit/editor',
          children: t('product-unit.actions.create.label')
        },
        filterModalProps: {
          maxWidth: 'sm',
          title: t('product-unit.list.filter.title'),
          children: ProductUnitFilterForm
        }
      }
    }
  });

  return {
    tableProps
  };
};
