import { useTranslation } from 'react-i18next';
import { EmpLoadingButton } from '../../../shared/components';
import { usePopup } from '../EmpPopup';

const styles = {
  button: {
    borderRadius: '20px'
  }
};

export const EditorReset = ({ onReset }) => {
  const { t } = useTranslation('core');
  const { confirm } = usePopup();

  const handleClick = async () => {
    const result = await confirm(
      t('editor.actions.reset.confirmation.title'),
      t('editor.actions.reset.confirmation.content')
    );

    if (result === 'confirm') {
      onReset();
    }
  };

  return (
    <EmpLoadingButton
      color="error"
      sx={styles.button}
      onClick={handleClick}
    >
      {t('editor.actions.reset.label')}
    </EmpLoadingButton>
  );
};
