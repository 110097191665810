import { Link as RouterLink } from 'react-router-dom';
import { Button, Link } from '@mui/material';

export const EmpLinkButton = (props) => {
  const { to, ...rest } = props;

  return (
    <Link to={to} component={RouterLink}>
      <Button {...rest} />
    </Link>
  );
};
