import i18n from 'i18next';
import { DateTime } from 'luxon';
import { priceFormatter } from './PriceFormatter';

const dateFormatter = (date, format) => {
  if (date instanceof Date) {
    date = date.toISOString();
  }

  return DateTime
    .fromISO(date)
    .setLocale(i18n.language)
    .toLocaleString(format);
};

const yearAndMonthFormatter = (date) => {
  if (date instanceof Date) {
    date = date.toISOString();
  }

  return DateTime
    .fromISO(date)
    .setLocale(i18n.language)
    .toLocaleString({ year: 'numeric', month: 'long' });
};

const yearAndMonthAndDayFormatter = (date) => {
  if (date instanceof Date) {
    date = date.toISOString();
  }

  return DateTime
    .fromISO(date)
    .setLocale(i18n.language)
    .toLocaleString({ year: 'numeric', month: '2-digit', day: '2-digit' });
};

const timeFormatter = (date) => {
  if (date instanceof Date) {
    date = date.toISOString();
  }

  return DateTime
    .fromISO(date)
    .setLocale(i18n.language)
    .toLocaleString({ hour: '2-digit', minute: '2-digit', second: '2-digit' });
};

const hourAndMinuteFormatter = (date) => {
  if (date instanceof Date) {
    date = date.toISOString();
  }

  return DateTime
    .fromISO(date)
    .setLocale(i18n.language)
    .toLocaleString({ hour: '2-digit', minute: '2-digit' });
};

const durationFormatter = (startDate, endDate) => {
  let diff = endDate - startDate;

  const diffHours = Math.trunc(diff / 3_600_000);
  if (diffHours > 0) diff -= (diffHours * 3_600_000);

  const diffMinutes = Math.trunc(diff / 60_000);
  if (diffMinutes > 0) diff -= (diffMinutes * 60_000);

  const diffSeconds = Math.trunc(diff / 1_000);

  let formatted = '';

  if (diffHours > 0) {
    formatted += (diffHours < 10 ? `0${diffHours}` : diffHours) + ':';
  }

  formatted += (diffMinutes < 10 ? `0${diffMinutes}` : diffMinutes) + ':';
  formatted += (diffSeconds < 10 ? `0${diffSeconds}` : diffSeconds);

  return formatted || '-';
};

export const formatters = {
  price: priceFormatter,
  date: dateFormatter,
  time: timeFormatter,
  hourAndMinute: hourAndMinuteFormatter,
  yearAndMonth: yearAndMonthFormatter,
  yearAndMonthAndDay: yearAndMonthAndDayFormatter,
  duration: durationFormatter
};
