import { useTranslation } from 'react-i18next';
import { FilterItem, FilterForm } from '../../../shared/components/';

const defaultValues = {
  id: {
    operator: 'equals',
    value: ''
  },
  name: {
    operator: 'contains',
    value: ''
  },
  associatedWarehouse: {
    operator: 'contains',
    value: ''
  },
  currency: {
    operator: 'contains',
    value: ''
  },
  address: {
    operator: 'contains',
    value: ''
  }
};

export const ShopFilterForm = ({ filters, onFilterChange }) => {
  const { t } = useTranslation('stockManager');

  filters = {
    ...defaultValues,
    ...filters
  };

  const handleOperatorChange = ({ target: { name, value } }) => {
    onFilterChange(name, { ...filters[name], operator: value });
  };

  const handleValueChange = ({ target: { name, value } }) => {
    onFilterChange(name, { ...filters[name], value: value });
  };

  return (
    <FilterForm>
      {/* ID */}
      <FilterItem
        type="number"
        name="id"
        label={t('shop.list.filter.fields.id')}
        filter={filters.id}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 0] }}
      />

      {/* Name */}
      <FilterItem
        type="string"
        name="name"
        label={t('shop.list.filter.fields.name')}
        filter={filters.name}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 1] }}
      />

      {/* Currency */}
      <FilterItem
        type="string"
        name="currency"
        label={t('shop.list.filter.fields.currency')}
        filter={filters.currency}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 0] }}
      />

      {/* Warehouse */}
      <FilterItem
        type="string"
        nullable
        name="associatedWarehouse"
        label={t('shop.list.filter.fields.warehouse')}
        filter={filters.associatedWarehouse}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 5, 1] }}
      />

      {/* Address */}
      <FilterItem
        type="string"
        nullable
        name="address"
        label={t('shop.list.filter.fields.address')}
        filter={filters.address}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [1, 3, 0] }}
        valueContainerProps={{ xs: [0, 14, 0] }}
      />
    </FilterForm>
  );
};
