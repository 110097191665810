import { useTranslation } from 'react-i18next';

import {
  AttachMoney as AttachMoneyIcon,
  List as ListIcon,
  GridOn as GridOnIcon,
  GroupAdd as GroupAddIcon,
  PrecisionManufacturing as PrecisionManufacturingIcon,
  Receipt as ReceiptIcon,
  Sell as SellIcon,
  Store as StoreIcon,
  ReadMore as ReadMoreIcon,
  Category as CategoryIcon,
  Domain as DomainIcon,
  Inventory as InventoryIcon,
  FactCheck as FactCheckIcon,
  ArrowBack as ArrowBackIcon,
  OtherHouses as WarehouseIcon,
  Repeat as RepeatIcon,
  Article as DocumentsIcon,
  Equalizer as EqualizerIcon
} from '@mui/icons-material';

import { Roles } from './Roles';
import { useModules } from '../../shared/hooks/useModules';
import { useCurrentUser } from '../../shared/hooks/useCurrentUser';

export const useStockManagerMenu = () => {
  const { t } = useTranslation('stockManager');

  const { hasAnyRole } = useCurrentUser();
  const { currentModule, currentView } = useModules();

  return {
    label: t('menu.base'),
    icon: <GridOnIcon />,
    active: currentModule === 'stock-manager',
    visible: hasAnyRole(Roles.STM_Admin, Roles.STM_ShopManager, Roles.STM_Shopkeeper),
    children: [{
      label: t('menu.sale'),
      icon: <AttachMoneyIcon />,
      active: currentView === 'sale',
      visible: hasAnyRole(Roles.STM_Admin, Roles.STM_ShopManager, Roles.STM_Shopkeeper),
      to: '/stock-manager/sale'
    }, {
      label: t('menu.daily-income'),
      icon: <EqualizerIcon />,
      active: currentView === 'daily-income',
      visible: hasAnyRole(Roles.STM_Admin, Roles.STM_ShopManager),
      to: '/stock-manager/daily-income'
    }, {
      label: t('menu.product'),
      icon: <ListIcon />,
      active: currentView === 'product',
      visible: hasAnyRole(Roles.STM_Admin),
      to: '/stock-manager/product'
    }, {
      label: t('menu.shops'),
      icon: <StoreIcon />,
      active: currentView === 'shop',
      visible: hasAnyRole(Roles.STM_Admin),
      to: '/stock-manager/shop'
    }, {
      label: t('menu.employees'),
      icon: <SellIcon />,
      active: currentView === 'employee',
      visible: hasAnyRole(Roles.STM_Admin),
      to: '/stock-manager/employee'
    }, {
      label: t('menu.customers'),
      icon: <GroupAddIcon />,
      active: currentView === 'customer',
      visible: hasAnyRole(Roles.STM_Admin),
      to: '/stock-manager/customer'
    }, {
      label: t('menu.invoices'),
      icon: <ReceiptIcon />,
      active: currentView === 'invoice',
      visible: hasAnyRole(Roles.STM_Admin, Roles.STM_ShopManager),
      to: '/stock-manager/invoice'
    }, {
      label: t('menu.other'),
      icon: <ReadMoreIcon />,
      active: currentView === 'currency' || currentView === 'manufacturer' || currentView === 'product-unit' || currentView === 'product-category',
      visible: hasAnyRole(Roles.STM_Admin),
      children: [{
        label: t('menu.currencies'),
        icon: <AttachMoneyIcon />,
        active: currentView === 'currency',
        visible: hasAnyRole(Roles.STM_Admin),
        nesting: 2,
        to: '/stock-manager/currency'
      }, {
        label: t('menu.manufacturers'),
        icon: <PrecisionManufacturingIcon />,
        active: currentView === 'manufacturer',
        visible: hasAnyRole(Roles.STM_Admin),
        nesting: 2,
        to: '/stock-manager/manufacturer'
      }, {
        label: t('menu.product-units'),
        icon: <DomainIcon />,
        active: currentView === 'product-unit',
        visible: hasAnyRole(Roles.STM_Admin),
        nesting: 2,
        to: '/stock-manager/product-unit'
      }, {
        label: t('menu.product-categories'),
        icon: <CategoryIcon />,
        active: currentView === 'product-category',
        visible: hasAnyRole(Roles.STM_Admin),
        nesting: 2,
        to: '/stock-manager/product-category'
      }]
    }, { // stock management root element 
      label: t('menu.stock-management'),
      icon: <InventoryIcon />,
      active: currentView === 'current-stock' || currentView === 'stock-movements' || currentView === 'in-out-goods' || currentView === 'documents' || currentView === 'warehouses',
      visible: hasAnyRole(Roles.STM_Admin),

      children: [{
        label: t('menu.current-stock'),
        icon: <FactCheckIcon />,
        active: currentView === 'current-stock',
        visible: hasAnyRole(Roles.STM_Admin),
        nesting: 2,
        to: '/stock-manager/current-stock'
      }, {
        label: t('menu.stock-movements'),
        icon: <RepeatIcon />,
        active: currentView === 'stock-movements',
        visible: hasAnyRole(Roles.STM_Admin),
        nesting: 2,
        to: '/stock-manager/stock-movements'
      }, {
        label: t('menu.in-out-goods'),
        icon: <ArrowBackIcon />,
        active: currentView === 'in-out-goods',
        visible: hasAnyRole(Roles.STM_Admin),
        nesting: 2,
        to: '/stock-manager/in-out-goods'
      }, {
        label: t('menu.documents'),
        icon: <DocumentsIcon />,
        active: currentView === 'documents',
        visible: hasAnyRole(Roles.STM_Admin),
        nesting: 2,
        to: '/stock-manager/documents'
      }, {
        label: t('menu.warehouses'),
        icon: <WarehouseIcon />,
        active: currentView === 'warehouses',
        visible: hasAnyRole(Roles.STM_Admin),
        nesting: 2,
        to: '/stock-manager/warehouses'
      }]
    }]
  }
};
