import React, { createContext, useContext } from 'react';
import { useMediaQuery } from '@mui/material';

const BreakpointContext = createContext({
  currentBreakpoint: null,
  isMobile: () => false,
  isDesktop: () => false
});

export const useCurrentBreakpoint = () => {
  return useContext(BreakpointContext);
};

export const BreakpointProvider = ({ children }) => {
  const matchXs = useMediaQuery(theme => theme.breakpoints.only('xs'));
  const matchSm = useMediaQuery(theme => theme.breakpoints.only('sm'));
  const matchMd = useMediaQuery(theme => theme.breakpoints.only('md'));
  const matchLg = useMediaQuery(theme => theme.breakpoints.only('lg'));
  const matchXl = useMediaQuery(theme => theme.breakpoints.only('xl'));

  let currentBreakpoint = null;

  if (matchXs) {
    currentBreakpoint = 'xs';
  }
  else if (matchSm) {
    currentBreakpoint = 'sm';
  }
  else if (matchMd) {
    currentBreakpoint = 'md';
  }
  else if (matchLg) {
    currentBreakpoint = 'lg';
  }
  else if (matchXl) {
    currentBreakpoint = 'xl';
  }

  const isMobile = () => {
    return currentBreakpoint === 'xs' || currentBreakpoint === 'sm';
  };

  const isDesktop = () => {
    return currentBreakpoint === 'md' || currentBreakpoint === 'lg' || currentBreakpoint === 'xl';
  };

  return (
    <BreakpointContext.Provider value={{ currentBreakpoint, isMobile, isDesktop }}>
      {children}
    </BreakpointContext.Provider>
  );
};
