import { Outlet } from 'react-router-dom';
import { DefaultRoute, NotFoundPage, PrivateRoute } from '../../core';
import { ChargingSessions } from './charging-sessions/ChargingSessions';
import { GuestChargings } from './guest-chargings/GuestChargings';
import { Roles } from './Roles';

export const EvChargerRoutes = () => ({
  path: '/ev-charger',
  element: (
    <PrivateRoute
      component={Outlet}
      roles={Roles.EV_All}
    />
  ),
  children: [
    {
      path: '',
      element: (
        <PrivateRoute
          component={GuestChargings}
          roles={Roles.EV_All}
        />
      )
    },
    {
      path: 'guest-chargings',
      element: (
        <PrivateRoute
          component={GuestChargings}
          roles={Roles.EV_All}
        />
      )
    },
    {
      path: 'charging-sessions',
      element: (
        <PrivateRoute
          component={ChargingSessions}
          roles={[Roles.EV_Admin]}
        />
      )
    },
    {
      path: '*',
      element: <DefaultRoute component={NotFoundPage} />
    }
  ]
});
