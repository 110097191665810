import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ProductCategoryService } from './ProductCategoryService';

export const useProductCategoryViewer = () => {
  const { productCategoryId } = useParams();
  const [productCategory, setProductCategory] = useState(null);

  useEffect(() => {
    ProductCategoryService.getProductCategoryById(productCategoryId)
      .then(productCategory => {
        setProductCategory(productCategory);
      });
  }, [productCategoryId]);

  return {
    productCategory
  };
};
