import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export const MenuExpander = ({ open, subMenus }) => {
  if (!subMenus?.length) {
    return <></>;
  }
  else {
    return open ? <ExpandLess /> : <ExpandMore />;
  }
};
