import { TableView } from '../../shared/components';
import { EmpTableV2 } from '../../shared/components/table/EmpTableV2';
import { useUserList } from './useUserList';

export const UserList = () => {
  const { tableProps } = useUserList();

  return (
    <TableView width="lg">
      <EmpTableV2 {...tableProps} />
    </TableView>
  );
};
