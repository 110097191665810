import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useApiNotification } from '../../../shared/hooks/useApiNotification';
import { CurrencyService } from './CurrencyService';

const schema = yup.object().shape({
  name:
    yup.string().required().trim().max(32).emptyStringToNull(),

  shortName:
    yup.string().required().trim().max(8).emptyStringToNull(),

  symbol:
    yup.string().required().trim().max(4).emptyStringToNull()
});

export const useCurrencyEditor = () => {
  const { currencyId } = useParams();

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const {
    showUpdateError,
    showUpdateSuccess,
    showCreateError,
    showCreateSuccess
  } = useApiNotification({
    successNavigationUrl: '/stock-manager/currency',
    translation: {
      namespace: 'stockManager',
      fragment: 'currency.actions'
    }
  });

  const { handleSubmit, setValue } = form;

  useEffect(() => {
    if (currencyId) {
      CurrencyService.getCurrencyById(currencyId)
        .then(currency => {
          Object.entries(currency)
            .forEach(([key, value]) => setValue(key, value));
        });
    }
  }, [setValue, currencyId]);

  const handleSubmitInternal = async (data) => {
    if (currencyId) {
      await updateCurrency(data);
    }
    else {
      await createCurrency(data);
    }
  };

  const createCurrency = async (currency) => {
    return CurrencyService.createCurrency(currency)
      .then(() => {
        showCreateSuccess();
      })
      .catch(error => {
        showCreateError(error);
      });
  };

  const updateCurrency = async (currency) => {
    return CurrencyService.updateCurrency(currency)
      .then(() => {
        showUpdateSuccess();
      })
      .catch(error => {
        showUpdateError(error);
      });
  };

  return {
    currencyId,
    form,
    handleSubmit: handleSubmit(handleSubmitInternal)
  };
};
