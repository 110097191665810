import { grey } from '@mui/material/colors';

export const EmpStyles = {
  invisibleScrollbar: {
    overflowY: 'scroll',

    /* IE and Edge */
    '-ms-overflow-style': 'none',

    /* Firefox */
    'scrollbar-width': 'none',

    /* Chrome */
    '&::-webkit-scrollbar': { 
      display: 'none'
    }
  },

  menu: {
    nested: {
      paddingLeft: 6
    },
    nested2: (nesting) => ({
      paddingLeft: nesting * 6
    }),
    active: {
      backgroundColor: grey[100]
    }
  },

  scrollbar: {
    '::-webkit-scrollbar-track': {
      WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#eeeeee',
      //borderRadius: '4px'
    },
    '::-webkit-scrollbar': {
      width: '8px',
      //borderRadius: '4px',
      backgroundColor: '#f5f5f5'
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#f5f5f5',
      border: '1.5px solid #bdbdbd',
      //borderRadius: '4px'
    }
  }
};
