import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { UserService } from './UserService';

export const useUserViewer = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (userId) {
      UserService.getById(userId)
        .then(user => {
          setUser(user);
        });
    }
  }, [userId]);

  return {
    user
  };
};
