import { ServiceBase } from '../../../shared';

const BASE_URL = '/stock-manager/daily-income';

export const DailyIncomeService = {
  async getDailyIncome(shopId, interval) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}?shopId=${shopId}&interval=${interval}`))
      .json();
  },

  async getShops() {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/shops`))
      .json();
  }
};
