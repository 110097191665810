import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ManufacturerService } from './ManufacturerService';

export const useManufacturerViewer = () => {
  const { manufacturerId } = useParams();
  const [manufacturer, setManufacturer] = useState(null);

  useEffect(() => {
    ManufacturerService.getManufacturerById(manufacturerId)
      .then(manufacturer => {
        setManufacturer(manufacturer);
      });
  }, [manufacturerId]);

  return {
    manufacturer
  };
};
