import React from 'react';

import {
  Button,
  CircularProgress
} from '@mui/material';

export const EmpLoadingButton = (props) => {
  const {
    loading = false,
    children,
    ...rest
  } = props;

  if (loading) {
    rest.disabled = true;
    rest.startIcon = (
      <CircularProgress
        //color="#616161"
        thickness={5}
        size={18}
      />
    )
  }

  return (
    <Button {...rest}>
      {children}
    </Button>
  );
};
