import { Grid } from '@mui/material';

export const Form = (props) => {
  const { onSubmit, children, ...rest } = props;

  return (
    <form onSubmit={onSubmit}>
      <Grid {...rest} container>
        {children}
      </Grid>
    </form>
  );
};
