import { TableView } from '../../../shared/components';
import { EmpTableV2 } from '../../../shared/components/table/EmpTableV2';
import { useInvoiceList } from './useInvoiceList';

export const InvoiceList = () => {
  const { tableProps } = useInvoiceList();

  return (
    <TableView width="xl">
      <EmpTableV2 {...tableProps} />
    </TableView>
  );
};
