import { saleActions } from '../saleActions';
import { useSaleContext } from '../context/SaleContext';

export const usePoints = () => {
  const { shop, shops, basket, customer, extraDiscount, redeemedPoints, dispatch } = useSaleContext();
  const { value, rounded } = redeemedPoints;

  const hasCustomer = !!customer;

  const shopCanRedeemPoints = () => {
    const currentShop = shops?.find(s => s.id === shop);
    return currentShop?.canRedeemPoints || false;
  };

  const customerCanRedeemPoints = () => {
    return hasCustomer && !extraDiscount && !customer?.discountPercentage;
  };

  const current = () => {
    return hasCustomer ? customer.collectedPoints : null;
  };

  const todayUsed = () => {
    return hasCustomer ? customer.redeemedPointsToday || '0' : 'N/A';
  };

  const received = () => {
    return hasCustomer ? basket.totalPoints : null;
  };

  const newTotal = () => {
    return hasCustomer ?
      customer.collectedPoints + basket.totalPoints - (redeemedPoints?.rounded || 0)
      : null;
  };

  const redeemAll = () => {
    redeem(current());
  };

  const redeemNothing = () => {
    redeem(0);
  };

  const redeem = (value) => {
    dispatch(saleActions.setRedeemedPoints(parseInt(value)));
  };

  return {
    redeemed: value,
    redeemedRounded: rounded,
    hasCustomer,
    shopCanRedeemPoints,
    customerCanRedeemPoints,
    current,
    todayUsed,
    received,
    newTotal,
    redeem,
    redeemAll,
    redeemNothing
  };
};
