import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useEmpDropdownAdapter } from '../../../shared/components';
import { useApiNotification } from '../../../shared/hooks/useApiNotification';
import { ProductService } from './ProductService';

const schema = yup.object().shape({
  name:
    yup.string().required().trim().max(64),

  sku:
    yup.string().required().trim().max(64),

  ean:
    yup.string().nullable().trim().max(64),

  manufacturerId:
    yup.number().integer().required(),

  productCategoryId:
    yup.number().integer().required(),

  productUnitId:
    yup.number().integer().required(),

  quantityNotificationLimit:
    yup.number().nullable(),

  remarks:
    yup.string().nullable().trim().max(64),

  prices: yup.array(yup.object().shape({
    shopId:
      yup.number().integer().required(),

    sellingPrice:
      yup.number().required().positive(),

    pointsPerUnit:
      yup.number().integer().nullable()
  }))
});

export const useProductEditor = () => {
  const { productId } = useParams();

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const [original, setOriginal] = useState(null);

  const {
    showUpdateError,
    showUpdateSuccess,
    showCreateError,
    showCreateSuccess
  } = useApiNotification({
    successNavigationUrl: '/stock-manager/product',
    translation: {
      namespace: 'stockManager',
      fragment: 'product.actions'
    }
  });

  const [manufacturersLoading, manufacturerOptions] = useEmpDropdownAdapter({
    url: '/stock-manager/manufacturers'
  });

  const [productCategoriesLoading, productCategoryOptions] = useEmpDropdownAdapter({
    url: '/stock-manager/product-categories'
  });

  const [productUnitsLoading, productUnitOptions] = useEmpDropdownAdapter({
    url: '/stock-manager/product-units'
  });

  const [shopsLoading, shopOptions] = useEmpDropdownAdapter({
    url: '/stock-manager/shops'
  });

  const { handleSubmit, reset } = form;

  useEffect(() => {
    if (productId) {
      ProductService.getProductById(productId)
        .then(product => {
          setOriginal(JSON.parse(JSON.stringify(product)))
          reset(mapProduct(product));
        });
    }
  }, [productId, reset]);

  const resetEditor = () => {
    reset(original ? mapProduct(original) : {});
  };

  const handleSubmitInternal = async (data) => {
    if (productId) {
      await updateProduct(data);
    }
    else {
      await createProduct(data);
    }
  };

  const createProduct = (product) => {
    return ProductService.createProduct(product)
      .then(() => {
        showCreateSuccess();
      })
      .catch(error => {
        showCreateError(error);
      });
  };

  const updateProduct = (product) => {
    return ProductService.updateProduct(product)
      .then(() => {
        showUpdateSuccess();
      })
      .catch(error => {
        showUpdateError(error);
      });
  };

  const manufacturerDropdownProps = {
    loading: manufacturersLoading,
    options: manufacturerOptions
  };

  const productCategoryDropdownProps = {
    loading: productCategoriesLoading,
    options: productCategoryOptions
  };

  const productUnitDropdownProps = {
    loading: productUnitsLoading,
    options: productUnitOptions
  };

  const shopDropdownProps = {
    loading: shopsLoading,
    options: shopOptions
  };

  return {
    productId,
    form,
    manufacturerDropdownProps,
    productCategoryDropdownProps,
    productUnitDropdownProps,
    shopDropdownProps,
    resetEditor,
    handleSubmit: handleSubmit(handleSubmitInternal)
  };
};

const mapProduct = (product) => {
  return {
    id: product.id,
    name: product.name,
    ean: product.ean,
    sku: product.sku,
    remarks: product.remarks,
    quantityNotificationLimit: product.quantityNotificationLimit,
    manufacturerId: product.manufacturer.id,
    productCategoryId: product.productCategory.id,
    productUnitId: product.productUnit.id,
    prices: product?.prices.map(price => ({
      currency: price.currency,
      pointsPerUnit: price.pointsPerUnit,
      sellingPrice: price.sellingPrice,
      shopId: price.shop.id
    }))
  };
};
