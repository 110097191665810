import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PrecisionManufacturing as PrecisionManufacturingIcon } from '@mui/icons-material';
import { ManufacturerActionsMenu } from './ManufacturerActionsMenu';
import { ManufacturerFilterForm } from './ManufacturerFilterForm';
import { ManufacturerService } from './ManufacturerService';
import { useTableV2 } from '../../../shared/components/table/hooks/useTableV2';

const columnsFactory = (t) => [{
  header: t('manufacturer.list.columns.id'),
  field: 'id',
  width: '20%'
}, {
  header: t('manufacturer.list.columns.name'),
  field: 'name',
  width: '40%'
}, {
  header: t('manufacturer.list.columns.short-name'),
  field: 'shortName',
  width: '30%',
  render: ({ shortName }) => shortName || '-'
}, {
  width: '10%',
  render: (row, { refresh }) => (
    <ManufacturerActionsMenu
      manufacturer={row}
      onDelete={refresh}
    />
  )
}];

export const useManufacturerList = () => {
  const { t } = useTranslation('stockManager');

  const columns = useMemo(() => columnsFactory(t), [t]);

  const tableProps = useTableV2({
    columns,
    title: t('manufacturer.list.title'),
    icon: <PrecisionManufacturingIcon />,
    dataSource: ManufacturerService.getManufacturersPaginated,
    components: {
      toolbar: {
        createButtonProps: {
          to: '/stock-manager/manufacturer/editor',
          children: t('manufacturer.actions.create.label')
        },
        filterModalProps: {
          maxWidth: 'sm',
          title: t('manufacturer.list.filter.title'),
          children: ManufacturerFilterForm
        }
      }
    }
  });

  return {
    tableProps
  };
};
