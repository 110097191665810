import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { ServiceBase } from '../../shared';

export const ContactUsPage = () => {
  const { t } = useTranslation('core');
  const [buildInfo, setBuildInfo] = useState(null);

  useEffect(() => {
    BuildInfoService.getBuildInfo()
      .then(buildInfo => {
        setBuildInfo(buildInfo);
      })
      .catch(error => {
        // TODO - add error handling
      });
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          {t('contact-us.sections.contact.title')}
        </Typography>

        <Grid container>
          <Grid item xs={2} sx={{ p: 1, backgroundColor: '#cecece' }}>
            <b>{t('contact-us.sections.contact.fields.company')}</b>
          </Grid>
          <Grid item xs={10} />

          <Grid item xs={2} sx={{ p: 1 }}>
            <b>{new Date().getFullYear()}</b>
          </Grid>
          <Grid item xs={10} />
        </Grid>
      </Box>

      <Box sx={{ p: 2 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          {t('contact-us.sections.application.title')}
        </Typography>

        <Grid container>
          <Grid item xs={2} sx={{ p: 1, backgroundColor: '#cecece' }}>
            <b>{t('contact-us.sections.application.fields.build-time')}</b>
          </Grid>
          <Grid item xs={10} sx={{ p: 1, backgroundColor: '#cecece' }}>
            {buildInfo?.buildTime || t('general.not-available')}
          </Grid>

          <Grid item xs={2} sx={{ p: 1 }}>
            <b>{t('contact-us.sections.application.fields.built-on')}</b>
          </Grid>
          <Grid item xs={10} sx={{ p: 1 }}>
            {buildInfo?.builtOn || t('general.not-available')}
          </Grid>

          <Grid item xs={2} sx={{ p: 1, backgroundColor: '#cecece' }}>
            <b>{t('contact-us.sections.application.fields.last-commit')}</b>
          </Grid>
          <Grid item xs={10} sx={{ p: 1, backgroundColor: '#cecece' }}>
            {buildInfo?.lastCommitHash || t('general.not-available')}
          </Grid>

          <Grid item xs={2} sx={{ p: 1 }}>
            <b>{t('contact-us.sections.application.fields.last-commit-hash')}</b>
          </Grid>
          <Grid item xs={10} sx={{ p: 1 }}>
            {buildInfo?.lastCommitHash || t('general.not-available')}
          </Grid>

          <Grid item xs={2} sx={{ p: 1, backgroundColor: '#cecece' }}>
            <b>{t('contact-us.sections.application.fields.commit-count')}</b>
          </Grid>
          <Grid item xs={10} sx={{ p: 1, backgroundColor: '#cecece' }}>
            {buildInfo?.commitCount || t('general.not-available')}
          </Grid>

          <Grid item xs={2} sx={{ p: 1 }}>
            <b>{t('contact-us.sections.application.fields.branch')}</b>
          </Grid>
          <Grid item xs={10} sx={{ p: 1 }}>
            {buildInfo?.branch || t('general.not-available')}
          </Grid>

          <Grid item xs={2} sx={{ p: 1, backgroundColor: '#cecece' }}>
            <b>{t('contact-us.sections.application.fields.version')}</b>
          </Grid>
          <Grid item xs={10} sx={{ p: 1, backgroundColor: '#cecece' }}>
            {buildInfo?.version || t('general.not-available')}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const BASE_URL = '/core/build-info';

const BuildInfoService = {
  async getBuildInfo() {
    return await (await ServiceBase
      .getAsync(BASE_URL))
      .json();
  }
};
