import { Box, Typography } from '@mui/material';

const style = {
  root: (height) => ({
    display: 'flex',
    flexDirection: 'column',
    height: height || '100%',
    borderRadius: '8px',
    border: '1px solid #bdbdbd',
    boxShadow: (theme) => theme.shadows[2]
  }),
  header: {
    p: 0.5,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #bdbdbd',
    backgroundColor: "secondary.main",
    borderTopLeftRadius: '7px',
    borderTopRightRadius: '7px'
  },
  content: {
    flexGrow: 1,
    overflow: 'auto'
  },
  item: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  icon: {
    ml: 2,
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    ml: 2,
    display: 'flex',
    justifyContent: 'flex-start'
  }
};

export const SalePanel = ({ title, icon, toolbar, height = '100%', children }) => {
  return (
    <Box sx={style.root(height)}>
      {/* Header */}
      <Box sx={style.header}>
        {/* Icon */}
        <Box sx={style.icon}>
          {icon}
        </Box>
        {/* Title */}
        <Box sx={style.title}>
          <Typography variant="h5">
            {title}
          </Typography>
        </Box>

        <Box style={style.item}>
          {toolbar}
        </Box>
      </Box>

      {/* Content */}
      <Box sx={style.content}>
        {children}
      </Box>
    </Box>
  );
};
