import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemText } from '@mui/material';

export const PasswordStrength = ({ validationResult }) => {
  const { t } = useTranslation('validation');

  const {
    atLeast8Chars,
    atLeastOneDigit,
    atLeastOneLowercase,
    atLeastOneUppercase,
    atLeastOneAlphaNumericChar
  } = (validationResult || {});

  return (
    <List dense>
      <ListItem sx={{ pl: 0 }}>
        <ListItemText
          primary={t('password.rules.at-least-8-chars')}
          sx={{ color: atLeast8Chars ? 'success.light' : 'error.main' }}
        />
      </ListItem>
      <ListItem sx={{ pl: 0 }}>
        <ListItemText
          primary={t('password.rules.at-least-one-digit')}
          sx={{ color: atLeastOneDigit ? 'success.light' : 'error.main' }}
        />
      </ListItem>
      <ListItem sx={{ pl: 0 }}>
        <ListItemText
          primary={t('password.rules.at-least-one-lowercase')}
          sx={{ color: atLeastOneLowercase ? 'success.light' : 'error.main' }}
        />
      </ListItem>
      <ListItem sx={{ pl: 0 }}>
        <ListItemText
          primary={t('password.rules.at-least-one-uppercase')}
          sx={{ color: atLeastOneUppercase ? 'success.light' : 'error.main' }}
        />
      </ListItem>
      <ListItem sx={{ pl: 0 }}>
        <ListItemText
          primary={t('password.rules.at-least-one-alphanumeric-char')}
          sx={{ color: atLeastOneAlphaNumericChar ? 'success.light' : 'error.main' }}
        />
      </ListItem>
    </List>
  );
};
