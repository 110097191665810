import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Link } from '@mui/material';

const breadcrumbMap = (t) => ({
  'home': t('side-menu.dashboard'),
  'user-profile': t('side-menu.user-profile.base'),
  'website-user': t('side-menu.website-user.base'),
  'maintenance': t('side-menu.maintenance.base'),
  'legal-entity': t('side-menu.maintenance.legal-entities'),
  'natural-person': t('side-menu.maintenance.natural-persons'),
  'customer': t('side-menu.maintenance.customers'),
  'access-controller-group': t('side-menu.maintenance.access-controller-groups.base'),
  'access-controller': t('side-menu.maintenance.access-controller-groups.access-controller'),
  'door': t('side-menu.maintenance.access-controller-groups.door'),
  'door-group': t('side-menu.maintenance.access-controller-groups.door-group'),
  'controller-message': t('side-menu.maintenance.access-controller-groups.controller-message'),
  'user': t('side-menu.maintenance.access-controller-groups.user'),
  'editor': t('side-menu.editor'),
  'stock-manager': t('side-menu.stock-manager.base'),
  'sale': t('side-menu.stock-manager.sale'),
  'daily-income': t('side-menu.stock-manager.daily-income'),
  'product': t('side-menu.stock-manager.product'),
  'shop': t('side-menu.stock-manager.shop'),
  'invoice': t('side-menu.stock-manager.invoice'),
  'manufacturer': t('side-menu.stock-manager.manufacturer'),
  'product-unit': t('side-menu.stock-manager.product-unit'),
  'product-category': t('side-menu.stock-manager.product-category'),
  'currency': t('side-menu.stock-manager.currency'),
  'employee': t('side-menu.stock-manager.employee'),
  'current-stock': t('side-menu.stock-manager.stock-current'),
  'stock-movements': t('side-menu.stock-manager.stock-movements'),
  'in-out-goods': t('side-menu.stock-manager.in-out-goods'),
  'documents': t('side-menu.stock-manager.documents'),
  'document': t('side-menu.stock-manager.document'),
  'warehouses': t('side-menu.stock-manager.warehouses'),
  'ev-charger': t('side-menu.ev-charger.base'),
  'guest-chargings': t('side-menu.ev-charger.guest-chargings'),
  'charging-sessions': t('side-menu.ev-charger.charging-sessions'),
});

export const EmpBreadcrumb = () => {
  const { t } = useTranslation('core');

  const pathNames = useLocation().pathname.split('/').filter((x) => x);
  const breadcrumbInfo = [{ name: 'home', to: '/' }];

  for (let i = 0; i < pathNames.length; i++) {
    const pathName = pathNames[i];

    // TODO - GUID check
    if (parseInt(pathName)) {
      continue;
    }

    breadcrumbInfo.push({
      name: pathName,
      to: `/${pathNames.slice(0, i + 1).join('/')}`
    });
  }

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
      {breadcrumbInfo.map((part, index) => {
        const last = index === breadcrumbInfo.length - 1;

        return (
          <Link
            key={part.to}
            to={part.to}
            color={last ? 'textPrimary' : 'inherit'}
            component={RouterLink}
          >
            {breadcrumbMap(t)[part.name]}
          </Link>
        )
      })}
    </Breadcrumbs>
  );
};
