import { TableView } from '../../../shared/components';
import { EmpTableV2 } from '../../../shared/components/table/EmpTableV2';
import { useDocumentList } from './useDocumentList';

export const DocumentList = () => {
  const { tableProps } = useDocumentList();

  return (
    <TableView width="xl">
      <EmpTableV2 {...tableProps} />
    </TableView>
  );
};
