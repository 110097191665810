import { TableView } from '../../../shared/components';
import { EmpTableV2 } from '../../../shared/components/table/EmpTableV2';
import { useShopList } from './useShopList';

export const ShopList = () => {
  const { tableProps } = useShopList();

  return (
    <TableView width="lg">
      <EmpTableV2 {...tableProps} />
    </TableView>
  );
};
