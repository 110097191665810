import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { Editor, EmpTextField, FormItem } from '../../shared/components';
import { Roles } from './Roles';
import { UserActionsMenu } from './UserActionsMenu';
import { useUserViewer } from './useUserViewer';

export const UserViewer = () => {
  const { t } = useTranslation('websiteUser');
  const { user } = useUserViewer();

  return (
    <Editor
      width="md"
      headerProps={{
        title: t('viewer.title'),
        backLinkTo: '/website-user',
        actions: user && <UserActionsMenu user={user} disabledActions={['datasheet']} />
      }}
    >
      <Grid container spacing={2} columns={16}>
        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              label: t('viewer.fields.id'),
              value: user?.id || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              label: t('viewer.fields.name'),
              value: user?.name || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              label: t('viewer.fields.username'),
              value: user?.username || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              label: t('viewer.fields.email'),
              value: user?.email || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 15, 0]} sm={[2, 14, 0]}>
          <Typography variant="h5">
            {t('viewer.fields.modules.title')}
          </Typography>
        </FormItem>
        <FormItem xs={[1, 14, 1]} sm={[0, 16, 0]}>
          <ModuleViewer modules={user?.modules || []} />
        </FormItem>
      </Grid>
    </Editor>
  );
};

const ModuleViewer = ({ modules }) => {
  const { t } = useTranslation('websiteUser');

  if (!modules?.length) {
    return (
      <Grid container spacing={2} columns={16}>
        <FormItem xs={[0, 16, 0]} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography>
            {t('viewer.fields.modules.empty')}
          </Typography>
        </FormItem>
      </Grid>
    );
  }

  return modules.map((module, index) => (
    <Grid key={index} container columns={16} spacing={2}>
      <ModuleViewerItem module={module}/>
    </Grid>
  ));
};

const ModuleViewerItem = ({ module }) => {
  const { t } = useTranslation('websiteUser');

  const role = Roles[module.abbreviation]
    .find(role => role.id === module.role);

  return (
    <>
      <FormItem xs={[0, 16, 0]} md={[2, 6, 0]}>
        <EmpTextField
          textFieldProps={{
            disabled: true,
            label: t('viewer.fields.modules.fields.name'),
            value: module?.name || '-'
          }}
        />
      </FormItem>

      <FormItem xs={[0, 16, 0]} md={[0, 6, 2]}>
        <EmpTextField
          textFieldProps={{
            disabled: true,
            label: t('viewer.fields.modules.fields.role'),
            value: role?.name || '-'
          }}
        />
      </FormItem>
    </>
  );
};
