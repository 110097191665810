import { useState } from 'react';
import { FilterList as FilterListIcon } from '@mui/icons-material'
import { EmpModal, FilterActions } from '../..';

export const FilterModal = ({ open, onClose, title, maxWidth, filters, children }) => {
  const {
    state,
    onFiltersChange,
    onFiltersReset
  } = filters;

  const [filtersInternal, setFiltersInternal] = useState(state);

  const onFilterChange = (name, value) => {
    setFiltersInternal(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const onReset = () => {
    setFiltersInternal(null);
    onFiltersReset(null);
    onClose(250);
  };

  const onFilter = () => {
    onFiltersChange(filtersInternal);
    onClose(250);
  };

  const Form = children;

  return (
    <EmpModal
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={maxWidth}
      titleIcon={<FilterListIcon />}
      localization={{
        title
      }}
      actions={(
        <FilterActions
          onClose={onClose}
          onReset={onReset}
          onFilter={onFilter}
        />
      )}
    >
      <Form
        filters={filtersInternal}
        onFilterChange={onFilterChange}
      />
    </EmpModal>
  );
};
