import i18next from 'i18next';

export const EVChargerFormatters = {
  energy: (value) => {
    const formatter = new Intl.NumberFormat(i18next.language);
    return `${formatter.format(Math.round(value * 100) / 100)} kWh`;
  },

  cost: (value) => {
    const formatter = new Intl.NumberFormat(i18next.language);
    return `${formatter.format(Math.round(value))} ${i18next.t('evCharger:currency')}`;
  }
};
