import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain as DomainIcon } from '@mui/icons-material';
import { EuroExchangeRateModal } from './EuroExchangeRateModal';
import { CurrencyActionsMenu } from './CurrencyActionsMenu';
import { CurrencyFilterForm } from './CurrencyFilterForm';
import { CurrencyService } from './CurrencyService';
import { useTableV2 } from '../../../shared/components/table/hooks/useTableV2';

const columnsFactory = (t) => [{
  header: t('currency.list.columns.id'),
  field: 'id',
  width: '20%'
}, {
  header: t('currency.list.columns.name'),
  field: 'name',
  width: '30%'
}, {
  header: t('currency.list.columns.short-name'),
  field: 'shortName',
  width: '25%',
  align: 'center'
}, {
  header: t('currency.list.columns.symbol'),
  field: 'symbol',
  width: '15%',
  align: 'center'
}, {
  width: '10%',
  render: (row, { refresh }) => (
    <CurrencyActionsMenu
      currency={row}
      onDelete={refresh}
    />
  )
}];

export const useCurrencyList = () => {
  const { t } = useTranslation('stockManager');

  const columns = useMemo(() => columnsFactory(t), [t]);

  const tableProps = useTableV2({
    columns,
    title: t('currency.list.title'),
    icon: <DomainIcon />,
    dataSource: CurrencyService.getCurrenciesPaginated,
    components: {
      toolbar: {
        createButtonProps: {
          to: '/stock-manager/currency/editor',
          children: t('currency.actions.create.label')
        },
        filterModalProps: {
          maxWidth: 'sm',
          title: t('currency.list.filter.title'),
          children: CurrencyFilterForm
        },
        other: (
          <EuroExchangeRateModal />
        )
      }
    }
  });

  return {
    tableProps
  };
};
