import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

export const EmpAutocomplete = ({ controllerProps, dropdownProps }) => {
  const { onChange, ...rest } = dropdownProps;

  const prevValue = useRef(null);

  const handleChange = (event, value, formOnChange) => {
    event.target.value = value;
    event.target.prevValue = prevValue.current;
    prevValue.current = value;

    onChange?.(event);
    formOnChange?.(value);
  };

  return (
    <Controller
      {...controllerProps}
      render={({ field, fieldState }) => (
        <AutocompleteInternal
          field={{
            ...field,
            onChange: (event, value) => handleChange(event, value, field.onChange)
          }}
          fieldState={fieldState}
          dropdownProps={rest}
        />
      )}
    />
  );
};

const AutocompleteInternal = ({ field, fieldState, dropdownProps }) => {
  const { t } = useTranslation('core');

  const {
    label,
    loading,
    options
  } = dropdownProps;

  let {
    value,
    onChange,
    ...restFieldProps
  } = field;

  const [open, setOpen] = useState(false);

  const isOptionEqualToValue = (option, value) => {
    return option.value === value;
  };

  const handleChange = (event, value) => {
    onChange(event, value?.value || '');
  };

  if (options?.length > 0) {
    value = options.find(option => option.value === value);
  }

  const localization = {
    clearText: t('general.autocomplete.clear'),
    closeText: t('general.autocomplete.close'),
    loadingText: t('general.autocomplete.loading'),
    noOptionsText: t('general.autocomplete.no-options'),
    openText: t('general.autocomplete.open'),
  }

  return (
    <Autocomplete
      {...restFieldProps}
      value={value || ''}
      onChange={handleChange}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={isOptionEqualToValue}
      options={options}
      loading={loading}
      {...localization}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={fieldState.invalid}
          helperText={fieldState.error?.message || ' '}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};
