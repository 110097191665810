import { saleActions } from '../saleActions';
import { useSaleContext } from '../context/SaleContext';

export const useQuantitySelector = () => {
  const { quantity, product, dispatch } = useSaleContext();

  const handleQuantityChanged = (newValue) => {
    if (!product?.id) {
      return;
    }

    // The ',' char cannot be the first one
    if (newValue === ',' && quantity.raw.length === 0) {
      return;
    }

    // Not allow a second 0
    if (newValue === 0 && quantity.raw.length === 1 && quantity.raw[0] === '0') {
      return;
    }

    const values = [...quantity.raw];

    if (newValue === 'delete') {
      if (values.length > 0){
        values.splice(values.length - 1, 1)
      }
    }
    else if (newValue === ',') {
      if (!values.includes('.') && values[values.length - 1] !== '.') {
        values.push('.');
      }
    }
    else if (newValue > 0 && newValue <= 9 && quantity.raw.length === 1 && quantity.raw[0] === 0) {
      values[0] = newValue;
    }
    else {
      values.push(newValue);
    }

    let stringified = '';

    values.forEach(value => {
      stringified += value.toString();
    });

    let parseable = stringified;

    if (stringified.endsWith('.')) {
      parseable += '0';
    }

    stringified = stringified.replace('.', ',');

    dispatch(saleActions.setQuantity({
      raw: values,
      stringified: stringified,
      parsed: parseFloat(parseable || '0')
    }));
  };

  const addToBasket = () => {
    dispatch(saleActions.addToBasket());
  }

  const hasProduct = () => {
    return !!product;
  };

  const stockExcedeed = () => {
    return quantity.parsed > product.currentAmount;
  };

  const totalPrice = () => {
    return (quantity.parsed * product.price).toFixed(2);
  };

  return {
    product,
    hasProduct,
    quantity: quantity.parsed,
    quantityStringified: quantity.stringified,
    handleQuantityChanged,
    totalPrice,
    stockExcedeed,
    addToBasket
  };
};
