import React, { useState, useMemo } from 'react';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import { DateTime } from 'luxon';
import { Receipt as ReceiptIcon } from '@mui/icons-material';
import { Description as DescriptionIcon } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';

import { useTableV2 } from '../../../shared/components/table/hooks/useTableV2';
import { EmpLinkIconButton, EmpLoadingButton } from '../../../shared/components';
import { priceFormatter } from '../utils/PriceFormatter';
import { InvoiceFilterForm } from './InvoiceFilterForm';
import { InvoiceService } from './InvoiceService';
import { fileDownloadHelper } from '../../../shared';

const columnsFactory = (t) => [{
  header: t('invoice.list.columns.date'),
  field: 'date',
  width: '15%',
  align: 'right',
  render: ({ date }) => DateTime.fromISO(date).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
}, {
  header: t('invoice.list.columns.shop'),
  field: 'shopName',
  width: '10%',
  render: ({ shopName }) => shopName || '-'
}, {
  header: t('invoice.list.columns.seller'),
  field: 'sellerName',
  width: '17.5%',
  render: ({ employeeName }) => employeeName || '-'
}, {
  header: t('invoice.list.columns.customer'),
  field: 'customerName',
  width: '17.5%',
  render: ({ customerName }) => customerName || '-'
}, {
  header: t('invoice.list.columns.payment-mode'),
  field: 'paymentMode',
  width: '5%',
  render: ({ paymentMode }) => t(`sale.payment-panel.methods.${paymentMode.toLowerCase()}`)
}, {
  header: t('invoice.list.columns.customer-discount'),
  field: 'discountPercentage',
  width: '5%',
  align: 'right',
  render: ({ discountPercentage }) => <PercentageCell value={discountPercentage} />
}, {
  header: t('invoice.list.columns.extra-discount'),
  field: 'extraDiscountPercentage',
  width: '5%',
  align: 'right',
  render: ({ extraDiscountPercentage }) => <PercentageCell value={extraDiscountPercentage} />
}, {
  header: t('invoice.list.columns.total-price'),
  field: 'totalDiscountedPrice',
  width: '10%',
  align: 'right',
  render: (row) => <TotalPriceCell row={row} />
}, {
  header: t('invoice.list.columns.received-points'),
  field: 'receivedPoints',
  width: '5%',
  align: 'right',
  render: ({ receivedPoints }) => receivedPoints || '-'
}, {
  header: t('invoice.list.columns.redeemed-points'),
  field: 'redeemedPoints',
  width: '5%',
  align: 'right',
  render: ({ redeemedPoints }) => redeemedPoints || '-'
}, {
  width: '5%',
  align: 'right',
  sortable: false,
  render: (row) => (
    <EmpLinkIconButton to={`/stock-manager/invoice/editor/${row.id}`}>
      <DescriptionIcon />
    </EmpLinkIconButton>
  )
}];

const TotalPriceCell = ({ row }) => {
  return `${priceFormatter(row.totalDiscountedAmount)} ${row.currencyShortName}`;
};

const PercentageCell = ({ value }) => {
  if (value === null || value === undefined) return '-';
  return `${value}%`;
};

export const useInvoiceList = () => {
  const { t } = useTranslation('stockManager');

  const [exportLoading, setExportLoading] = useState(false);
  const columns = useMemo(() => columnsFactory(t), [t]);

  const handleExportClick = () => {
    setExportLoading(true);

    InvoiceService.export(tableProps.filters.state)
      .then(response => {
        fileDownloadHelper.downloadFile(response);
      })
      .catch(error => {
        // TODO - proper notification
        alert(error);
      })
      .finally(() => {
        setExportLoading(false);
      });
  };

  const tableProps = useTableV2({
    columns,
    title: t('invoice.list.title'),
    icon: <ReceiptIcon />,
    dataSource: InvoiceService.getInvoicesPaginated,
    components: {
      toolbar: {
        filterModalProps: {
          maxWidth: 'lg',
          title: t('invoice.list.filter.title'),
          children: InvoiceFilterForm
        },
        other: (
          <EmpLoadingButton
            variant="text"
            sx={{ borderRadius: '10px', mr: 2 }}
            loading={exportLoading}
            startIcon={<DownloadIcon />}
            onClick={handleExportClick}
          >
            {t('invoice.list.actions.export.title')}
          </EmpLoadingButton>
        )
      }
    }
  });

  return {
    tableProps
  };
};
