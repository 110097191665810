import { ServiceBase } from '../../../shared';

const BASE_URL = '/stock-manager/sale';

export const SaleService = {
  async getShops() {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/shops`))
      .json();
  },

  async getProductCategories(shopId) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/${shopId}/products`))
      .json();
  },

  async getCustomerByCardNumber(cardNumber, shopId) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/customer?type=card&value=${cardNumber}&shopId=${shopId || 0}`))
      .json();
  },

  async getCustomerByName(customerName, shopId) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/customer?type=name&value=${customerName}&shopId=${shopId || 0}`))
      .json();
  },

  async createSale(request) {
    return await ServiceBase
      .postAsync('/stock-manager/sale', request);
  }
};
