import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import i18next from 'i18next';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useApiNotification } from '../../shared/hooks/useApiNotification';
import { useCurrentTenant } from '../../shared/hooks/useCurrentTenant';
import { UserService } from './UserService';

const createSchema = yup.object().shape({
  name:
    yup.string().required().trim().max(100),

  username:
    yup.string().required().trim().max(30),

  email:
    yup.string().required().trim().max(100),

  password:
    yup.string().required().trim().max(50),

  confirmPassword:
    yup.string().required().trim().max(50)
      .oneOf([yup.ref('password')], i18next.t('validation:password.must-match')),

  modules: yup.array(yup.object().shape({
    id:
      yup.number().integer().required(),
  
    role:
      yup.string().required().trim().max(100)
  }))
});

const updateSchema = yup.object().shape({
  name:
    yup.string().required().trim().max(100),

  username:
    yup.string().required().trim().max(30),

  email:
    yup.string().required().trim().max(100),

  modules: yup.array(yup.object().shape({
    id:
      yup.number().integer().required(),

    role:
      yup.string().required().trim().max(100)
  }))
});

export const useUserEditor = () => {
  const { userId } = useParams();
  const { tenant } = useCurrentTenant();

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(!userId ? createSchema : updateSchema)
  });

  const {
    showUpdateError,
    showUpdateSuccess,
    showCreateError,
    showCreateSuccess
  } = useApiNotification({
    successNavigationUrl: '/website-user',
  });

  const { handleSubmit, setValue } = form;

  useEffect(() => {
    if (userId) {
      UserService.getById(userId)
        .then(user => {
          Object.entries(user)
            .forEach(([key, value]) => setValue(key, value));
        });
    }
  }, [userId, setValue]);

  const handleSubmitInternal = async (data) => {
    if (userId) {
      await updateUser(data);
    }
    else {
      await createUser(data);
    }
  };

  const createUser = (user) => {
    return UserService.createUser(user)
      .then(() => {
        showCreateSuccess();
      })
      .catch(error => {
        showCreateError(error);
      });
  };

  const updateUser = (user) => {
    return UserService.updateUser(user)
      .then(() => {
        showUpdateSuccess();
      })
      .catch(error => {
        showUpdateError(error);
      });
  };

  return {
    userId,
    form,
    tenant,
    handleSubmit: handleSubmit(handleSubmitInternal)
  };
};
