import { EmpMaskedTextField } from '../../../shared/components';

export const EanField = (props) => {
  return (
    <EmpMaskedTextField
      {...props}
      maskProps={{
        ...props.maskProps,
        mask: '9 999999 999999'
      }}
    />
  );
};
