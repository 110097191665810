import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers';

import './App.css';
import './App.Translations';
import './App.Validations';
import { EmpRoutes } from './App.Routes';

import { AuthProvider } from './core/authentication/AuthProvider';
import { BreakpointProvider } from './shared/contexts/BreakpointProvider';
import { ModuleProvider } from './shared/hooks/useModules';
import { NotificationProvider, PopupProvider } from './shared/components';
import { TenantThemeProvider } from './shared/hooks/useTenantTheme';

const App = () => {
  const router = createBrowserRouter([{
    element: <Providers />,
    children: EmpRoutes
  }]);

  return <RouterProvider router={router} />;
};

const Providers = () => {
  return (
    <NotificationProvider>
      <AuthProvider>
        <ModuleProvider>
          <TenantThemeProvider>
            <BreakpointProvider>
              <PopupProvider>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <Outlet />
                </LocalizationProvider>
              </PopupProvider>
            </BreakpointProvider>
          </TenantThemeProvider>
        </ModuleProvider>
      </AuthProvider>
    </NotificationProvider>
  );
};

export default App;
