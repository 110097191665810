import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useEmpAuth } from '../AuthProvider';
import { EmpLoadingButton } from '../../../shared/components';

const styles = {
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    mt: 2,
    borderRadius: '20px'
  }
};

export const LoginScreen = () => {
  const { t } = useTranslation('core');
  const { signin } = useEmpAuth();

  const [loading, setLoading] = useState(false);

  const handleLogin = () => {
    setLoading(true);

    signin()
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box sx={styles.root}>
      <Typography variant="h4" align="center">
        {t('login.title')}
      </Typography>

      <EmpLoadingButton
        size="large"
        color="info"
        sx={styles.button}
        startIcon={<LockOpenIcon/>}
        loading={loading}
        onClick={handleLogin}
      >
        {t('login.button')}
      </EmpLoadingButton>
    </Box>
  );
};
