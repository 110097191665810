import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InvoiceService } from './InvoiceService';

export const useInvoiceViewer = () => {
  const { invoiceId } = useParams();
  const [invoice, setInvocie] = useState(null);

  useEffect(() => {
    InvoiceService.getInvoiceById(invoiceId)
      .then(invoice => {
        setInvocie(invoice);
      })
  }, [invoiceId]);

  return {
    invoice
  };
};
