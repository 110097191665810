import { fileDownloadHelper, ServiceBase } from '../../../shared';

const BASE_URL = '/stock-manager/invoices';

export const InvoiceService = {
  async getInvoicesPaginated(parameters) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}?${ServiceBase.makeQueryString(parameters)}`))
      .json();
  },

  async getInvoiceById(invoiceId) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/${invoiceId}`))
      .json();
  },

  async export(filters) {
    let url = `${BASE_URL}/export`;
    const serializedFilters = ServiceBase.makeQueryString({ filters });

    if (serializedFilters) {
      url += `?${serializedFilters}`;
    }

    const response = await ServiceBase.getAsync(url);
    return await fileDownloadHelper.parseFileResponse(response, 'invoices.xlsx');
  }
};
