import { Box,Container,Paper } from '@mui/material';
import { EmpBreadcrumb } from '../EmpBreadcrumb';
import { useCurrentBreakpoint } from '../../contexts/BreakpointProvider';

const styles = {
  root: {
    padding: (theme) => theme.spacing(2, 0)
  },
  breadcrumbRoot: {
    marginTop: 1,
    marginBottom: 2
  }
};

export const TableView = (props) => {
  const {
    hideBreadcrumb = false,
    width = 'xl',
    beforeTable = null,
    children
  } = props;

  const { isDesktop } = useCurrentBreakpoint();

  return (
    <Container maxWidth={width} sx={styles.root}>
      {!hideBreadcrumb && isDesktop() && (
        <Box sx={styles.breadcrumbRoot}>
          <EmpBreadcrumb />
        </Box>
      )}
      {beforeTable}
      <Paper>
        {children}
      </Paper>
    </Container>
  );
};
