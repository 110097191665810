import { EmpTextField, FormItem } from '../../../shared/components';

export const AddressEditor = (props) => {
  const {
    form,
    zipProps = {},
    cityProps = {},
    addressProps = {}
  } = props;

  return (
    <>
      <FormItem xs={[1, 14, 1]} sm={[2, 2, 0]}>
        <EmpTextField
          controllerProps={form && {
            name: 'zip',
            control: form.control
          }}
          textFieldProps={zipProps}
        />
      </FormItem>

      <FormItem xs={[1, 14, 1]} sm={[0, 4, 0]}>
        <EmpTextField
          controllerProps={form && {
            name: 'city',
            control: form.control
          }}
          textFieldProps={cityProps}
        />
      </FormItem>

      <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
        <EmpTextField
          controllerProps={form && {
            name: 'address',
            control: form.control
          }}
          textFieldProps={addressProps}
        />
      </FormItem>
    </>
  );
};
