import { NoLayout, SideMenuLayout, PrivateRoute, ContactUsPage, UserProfilePage, NotFoundPage } from './core';
import { LoginScreen } from './core/authentication';
import { SignInCallback } from './core/authentication/components/SignInCallback';
import { SignOutCallback } from './core/authentication/components/SignOutCallback';
import { DashboardPage } from './modules/dashboard';
import { EvChargerRoutes } from './modules/ev-charger/Routes';
import { StockManagerRoutes } from './modules/stock-manager/Routes';
import { WebsiteUserRoutes } from './modules/website-user/Routes';

export const EmpRoutes = [
  {
    element: <SideMenuLayout />,
    children: [
      EvChargerRoutes(),
      StockManagerRoutes(),
      WebsiteUserRoutes(),
      {
        path: '',
        element: (
          <PrivateRoute
            component={DashboardPage}
            roles={['all']}
          />
        )
      },
      {
        path: '/dashboard',
        element: (
          <PrivateRoute
            component={DashboardPage}
            roles={['all']}
          />
        )
      },
      {
        path: '/user-profile',
        element: (
          <PrivateRoute
            component={UserProfilePage}
            roles={['all']}
          />
        )
      },
      {
        path: '/contact-us',
        element: (
          <PrivateRoute
            component={ContactUsPage}
            roles={['all']}
          />
        )
      },
      {
        path: '*',
        element: (
          <PrivateRoute
            component={NotFoundPage}
            roles={['all']}
          />
        )
      }
    ]
  },
  {
    element: <NoLayout />,
    children: [
      {
        path: '/login',
        element: <LoginScreen />
      },
      {
        path: '/signin-callback',
        element: <SignInCallback />
      },
      {
        path: '/signout-callback',
        element: <SignOutCallback />
      }
    ]
  }
];
