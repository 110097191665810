import { useTranslation } from 'react-i18next';
import { FilterItem, FilterForm } from '../../../shared/components/';

const defaultValues = {
  id: {
    operator: 'equals',
    value: ''
  },
  user: {
    operator: 'contains',
    value: ''
  },
  shop: {
    operator: 'contains',
    value: ''
  }
};

export const EmployeeFilterForm = ({ filters, onFilterChange }) => {
  const { t } = useTranslation('stockManager');

  filters = {
    ...defaultValues,
    ...filters
  };

  const handleOperatorChange = ({ target: { name, value } }) => {
    onFilterChange(name, { ...filters[name], operator: value });
  };

  const handleValueChange = ({ target: { name, value } }) => {
    onFilterChange(name, { ...filters[name], value: value });
  };

  return (
    <FilterForm>
      {/* ID */}
      <FilterItem
        type="number"
        name="id"
        label={t('employee.list.filter.fields.id')}
        filter={filters.id}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [2, 5, 0] }}
        valueContainerProps={{ xs: [0, 10, 2] }}
      />

      {/* User */}
      <FilterItem
        type="string"
        name="user"
        label={t('employee.list.filter.fields.user')}
        filter={filters.user}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [2, 5, 0] }}
        valueContainerProps={{ xs: [0, 10, 2] }}
      />

      {/* Shop */}
      <FilterItem
        type="string"
        name="shop"
        label={t('employee.list.filter.fields.shop')}
        filter={filters.shop}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [2, 5, 0] }}
        valueContainerProps={{ xs: [0, 10, 2] }}
      />
    </FilterForm>
  );
};
