import { ActionsMenu } from '../../../shared/components';

export const ProductUnitActionsMenu = ({ productUnit, onDelete, disabledActions = [] }) => {
  const actions = {};

  if (!disabledActions.includes('datasheet')) {
    actions.datasheet = {
      to: `/stock-manager/product-unit/${productUnit.id}`
    };
  }

  if (!disabledActions.includes('editor')) {
    actions.editor = {
      to: `/stock-manager/product-unit/editor/${productUnit.id}`
    };
  }

  if (!disabledActions.includes('delete')) {
    actions.delete = {
      url: `/stock-manager/product-units/${productUnit.id}`,
      successNavigationUrl: '/stock-manager/product-unit',
      callback: onDelete
    };
  }

  return <ActionsMenu actions={actions} />;
};
