import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ProductUnitService } from './ProductUnitService';

export const useProductUnitViewer = () => {
  const { productUnitId } = useParams();
  const [productUnit, setProductUnit] = useState(null);

  useEffect(() => {
    ProductUnitService.getProductUnitById(productUnitId)
      .then(productUnit => {
        setProductUnit(productUnit);
      });
  }, [productUnitId]);

  return {
    productUnit
  };
};
