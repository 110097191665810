import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNotification } from '../components';

export const useApiNotification = (props = {}) => {
  const {
    translation = {},
    successNavigationUrl
  } = props;

  const {
    namespace = 'core',
    fragment = 'general.api-notification'
  } = translation;

  const navigate = useNavigate();

  const { t } = useTranslation(['core', namespace]);
  const { error, success } = useNotification();

  //**************************
  // Success popups
  //**************************

  const showSuccess = useCallback((type) => {
    const config = {};

    if (successNavigationUrl) {
      config.onClose = () => navigate(successNavigationUrl);
    }

    success(
      t(`${namespace}:${fragment}.${type}.success.title`),
      t(`${namespace}:${fragment}.${type}.success.content`),
      config
    );
  }, [t, navigate, success, namespace, fragment, successNavigationUrl]);

  const showCreateSuccess = useCallback(() => {
    showSuccess('create');
  }, [showSuccess]);

  const showUpdateSuccess = useCallback(() => {
    showSuccess('update');
  }, [showSuccess]);

  const showRemoveSuccess = useCallback(() => {
    showSuccess('delete');
  }, [showSuccess]);

  //**************************
  // Error popups
  //**************************

  const showError = useCallback((type, errorObject) => {
    let errorTitle = t(`${namespace}:${fragment}.${type}.error.title`);
    let errorMessage = t(`${namespace}:${fragment}.${type}.error.content`);

    if (errorObject?.errors?.length > 0) {
      errorMessage = errorObject.errors[0].message;
    }

    error(errorTitle, errorMessage);
  }, [t, error, namespace, fragment]);

  const showReadError = useCallback((errorObject) => {
    showError('read', errorObject)
  }, [showError]);

  const showListError = useCallback((errorObject) => {
    showError('list', errorObject)
  }, [showError]);

  const showCreateError = useCallback((errorObject) => {
    showError('create', errorObject);
  }, [showError]);

  const showUpdateError = useCallback((errorObject) => {
    showError('update', errorObject);
  }, [showError]);

  const showRemoveError = useCallback((errorObject) => {
    showError('delete', errorObject);
  }, [showError]);

  const showApiError = useCallback((title, exception) => {
    let errorTitle = title;
    let errorMessage = null;

    if (exception?.errors?.length > 0) {
      if (title) {
        errorMessage = exception.errors[0].message;
      }
      else {
        errorTitle = exception.errors[0].message;
      }
    }

    error(errorTitle, errorMessage);
  }, [error]);

  //**************************
  // Result popups
  //**************************

  const showResult = useCallback((type, result) => {
    const { completed, data, error } = result;

    if (completed) {
      if (error) {
        showError(type, error);
      }
      else if (data) {
        showSuccess(type)
      }
    }
  }, [showSuccess, showError]);

  return {
    showListError,
    showReadError,
    showSuccess,
    showCreateSuccess,
    showCreateError,
    showUpdateSuccess,
    showUpdateError,
    showRemoveSuccess,
    showRemoveError,
    showResult,

    showApiError
  };
};
