import { useMemo } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { Typography } from '@mui/material';
import { Store as StoreIcon } from '@mui/icons-material';
import { StockChangesFilterForm } from './StockChangesFilterForm';
import { StockService } from './StockService';
import { useTableV2 } from '../../../shared/components/table/hooks/useTableV2';

const columnsFactory = (t) => [{
  header: t('stock-movement.list.columns.date'),
  sortable: false,
  align: 'right',
  width: '15%',
  render: ({ date }) => (
    DateTime.fromISO(date)
      .setLocale(i18n.language)
      .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
  )
}, {
  header: t('stock-movement.list.columns.product'),
  field: 'productName',
  sortable: false,
  width: '15%'
}, {
  header: t('stock-movement.list.columns.change-amount'),
  width: '5%',
  align: 'right',
  sortable: false,
  render: ({ changeAmount }) => (
    <Typography sx={{ color: changeAmount > 0 ? '#4caf50' : '#f44336' }}>
      {changeAmount > 0 ? `+${changeAmount}` : changeAmount}
    </Typography>
  )
}, {
  header: t('stock-movement.list.columns.sku'),
  field: 'sku',
  width: '15%',
  sortable: false,
  render: ({ productSKU }) => productSKU || '-'
}, {
  header: t('stock-movement.list.columns.warehouse'),
  field: 'warehouseName',
  sortable: false,
  width: '15%'
}, {
  header: t('stock-movement.list.columns.change-type'),
  field: 'stockChangeTypeName',
  width: '15%',
  sortable: false,
  render: ({ stockChangeType }) => stockChangeType?.name || '-'
}, {
  header: t('stock-movement.list.columns.user'),
  field: 'userUsername',
  sortable: false,
  width: '10%'
}];

export const useStockChangesList = () => {
  const { t } = useTranslation('stockManager');

  const columns = useMemo(() => columnsFactory(t), [t]);

  const tableProps = useTableV2({
    columns,
    title: t('stock-movement.list.title'),
    icon: <StoreIcon />,
    dataSource: StockService.getStockMovementsPaginated,
    components: {
      toolbar: {
        filterModalProps: {
          maxWidth: 'sm',
          title: t('stock-movement.list.filter.title'),
          children: StockChangesFilterForm
        }
      }
    }
  });

  return {
    tableProps
  };
};
