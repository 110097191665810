import { ActionsMenu } from '../../../shared/components';

export const DocumentActionsMenu = ({ document, disabledActions = [] }) => {
  const actions = {};

  if (!disabledActions.includes('datasheet')) {
    actions.datasheet = {
      to: `/stock-manager/documents/${document.id}`
    };
  }

  return <ActionsMenu actions={actions} />;
};
