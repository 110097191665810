import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fileDownloadHelper } from '../../../shared';
import { DocumentService } from './DocumentService';

export const useDocumentViewer = () => {
  const { documentId } = useParams();

  const [document, setDocument] = useState(null);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      const document = await DocumentService.getDocumentById(documentId);
      setDocument(document);

      const attachments = await DocumentService.getAttachments(document.id);
      setAttachments(attachments);
    };

    if (documentId) {
      loadData();
    }
  }, [documentId]);

  const handleOpen = (attachmentId) => {
    DocumentService.downloadAttachment(attachmentId)
      .then(file => {
        fileDownloadHelper.downloadFile(file, true);
      });
  };

  const handleDownload = (attachmentId) => {
    DocumentService.downloadAttachment(attachmentId)
      .then(file => {
        fileDownloadHelper.downloadFile(file, false);
      });
  };

  return {
    document,
    attachments,
    handleOpen,
    handleDownload
  };
};
