import { ServiceBase } from '../../shared';

const BASE_URL = '/core/users';

export const UserService = {
  async getUsers(parameters) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}?${ServiceBase.makeQueryString(parameters)}`))
      .json();
  },

  async getById(userId) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/${userId}`))
      .json();
  },

  async createUser(user) {
    return await (await ServiceBase
      .postAsync(`${BASE_URL}`, user))
      .json();
  },

  async updateUser(user) {
    return await (await ServiceBase
      .putAsync(`${BASE_URL}/${user.id}`, user))
      .json();
  },

  async changePassword(request) {
    return await ServiceBase
      .postAsync(`${BASE_URL}/change-password`, request);
  }
};
