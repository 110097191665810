import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useApiNotification } from '../../../shared';
import { ManufacturerService } from './ManufacturerService';

const schema = yup.object().shape({
  name:
    yup.string().required().trim().max(64),

  shortName:
    yup.string().trim().max(8)
});

export const useManufacturerEditor = () => {
  const { manufacturerId } = useParams();

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const {
    showUpdateError,
    showUpdateSuccess,
    showCreateError,
    showCreateSuccess
  } = useApiNotification({
    successNavigationUrl: '/stock-manager/manufacturer',
    translation: {
      namespace: 'stockManager',
      fragment: 'manufacturer.actions'
    }
  });

  const { handleSubmit, setValue } = form;

  useEffect(() => {
    if (manufacturerId) {
      ManufacturerService.getManufacturerById(manufacturerId)
        .then(manufacturer => {
          Object.entries(manufacturer)
            .forEach(([key, value]) => setValue(key, value));
        });
    }
  }, [setValue, manufacturerId]);

  const handleSubmitInternal = async (data) => {
    if (manufacturerId) {
      await updateManufacturer(data);
    }
    else {
      await createManufacturer(data);
    }
  };

  const createManufacturer = (manufacturer) => {
    return ManufacturerService.createManufacturer(manufacturer)
      .then(() => {
        showCreateSuccess();
      })
      .catch(error => {
        showCreateError(error);
      });
  };

  const updateManufacturer = (manufacturer) => {
    return ManufacturerService.updateManufacturer(manufacturer)
      .then(() => {
        showUpdateSuccess();
      })
      .catch(error => {
        showUpdateError(error);
      });
  };

  return {
    manufacturerId,
    form,
    handleSubmit: handleSubmit(handleSubmitInternal)
  };
};
