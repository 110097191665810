import { useTranslation } from 'react-i18next';

import { Box, Grid, Divider } from '@mui/material';
import { Payment as PaymentIcon } from '@mui/icons-material';

import { PaymentMethodRadioGroup } from './PaymentMethodRadioGroup';
import { MixedPaymentMethod } from './MixedPaymentMethod';
import { TotalPayable } from './TotalPayable';
import { Discount } from './Discount';
import { SalePanel } from '../SalePanel';

export const PaymentPanel = () => {
  const { t } = useTranslation('stockManager');

  return (
    <SalePanel
      title={t('sale.payment-panel.title')}
      icon={<PaymentIcon />}
      height="auto"
    >
      <Box sx={{ p:0 }}>
        <Grid container>
          <Discount />
          <TotalPayable />
        </Grid>

        <Divider sx={{ my: 3 }} />

        <PaymentMethodRadioGroup />
        <MixedPaymentMethod />
      </Box>
    </SalePanel>
  );
};
