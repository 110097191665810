import { ServiceBase } from '../../../shared';

const BASE_URL = '/stock-manager/product-units';

export const ProductUnitService = {
  async getProductUnitsPaginated(parameters) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}?${ServiceBase.makeQueryString(parameters)}`))
      .json();
  },

  async getProductUnitById(productUnitId) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/${productUnitId}`))
      .json();
  },

  async createProductUnit(productUnit) {
    return await (await ServiceBase
      .postAsync(BASE_URL, productUnit))
      .json();
  },

  async updateProductUnit(productUnit) {
    return await (await ServiceBase
      .putAsync(`${BASE_URL}/${productUnit.id}`, productUnit))
      .json();
  }
};
