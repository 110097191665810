import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Grid, IconButton, Typography } from '@mui/material';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { DateTime } from 'luxon';
import { useDocumentViewer } from './useDocumentViewer';
import { Editor, EmpTextField, FormItem } from '../../../shared/components';
import { formatters } from '../utils/Formatters';

export const DocumentViewer = () => {
  const { t } = useTranslation('stockManager');

  const {
    document,
    attachments,
    handleOpen,
    handleDownload
  } = useDocumentViewer();

  return (
    <Editor
      width="md"
      headerProps={{
        title: t('document.viewer.title'),
        backLinkTo: '/stock-manager/documents'
      }}
    >
      <Grid container columns={16} spacing={2}>
        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: document?.id || '-',
              label: t('document.viewer.fields.id')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: document?.documentType?.typeName || '-',
              label: t('document.viewer.fields.document-type')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: document?.serialNo || '-',
              label: t('document.viewer.fields.document-serial')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: document?.documentType?.stockChangeType?.name || '-',
              label: t('document.viewer.fields.stock-change-type')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: document?.warehouse?.name || '-',
              label: t('document.viewer.fields.warehouse')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: document?.supplierName || '-',
              label: t('document.viewer.fields.supplier')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: document?.remarks || '-',
              label: t('document.viewer.fields.remarks')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: document?.createdBy || '-',
              label: t('document.viewer.fields.created-by')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 8]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: (document?.createdAt && formatters.date(document.createdAt, DateTime.DATETIME_SHORT_WITH_SECONDS)) || '-',
              label: t('document.viewer.fields.created-at')
            }}
          />
        </FormItem>

        {/* Products */}
        <FormItem xs={[2, 12, 2]}>
          <Typography variant="h5">
            {t('document.viewer.fields.products.title')}
          </Typography>
        </FormItem>

        <ProductList stockChanges={document?.stockChanges} />

        {/* Documents */}
        <FormItem xs={[2, 12, 2]}>
          <Typography variant="h5">
            {t('document.viewer.fields.documents.title')}
          </Typography>
        </FormItem>

        <AttachmentList
          attachments={attachments}
          handleOpen={handleOpen}
          handleDownload={handleDownload}
        />
      </Grid>
    </Editor>
  );
};

const ProductList = ({ stockChanges }) => {
  const { t } = useTranslation('stockManager');

  if (!stockChanges?.length) {
    return (
      <Grid container columns={16} spacing={2} sx={{ pt: 2 }}>
        <FormItem xs={[0, 16, 0]} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography>
            {t('document.viewer.fields.products.empty')}
          </Typography>
        </FormItem>
      </Grid>
    );
  }

  return stockChanges.map((stockChange, index) => (
    <React.Fragment key={index}>
      <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
        <EmpTextField
          textFieldProps={{
            disabled: true,
            value: stockChange?.productName || '-',
            label: t('document.viewer.fields.products.fields.product')
          }}
        />
      </FormItem>

      <FormItem xs={[0, 1, 0]} sx={{ alignSelf: 'center' }}>
        <Box alignSelf="center" mx={1}>
          <Typography
            fontSize={26}
            fontWeight="bold"
            color={stockChange?.isIncreasingStock ? '#1b5e20' : '#b71c1c'}
          >
            {!stockChange?.isIncreasingStock ? '-' : '+'}
          </Typography>
        </Box>
      </FormItem>

      <FormItem xs={[1, 14, 1]} sm={[0, 5, 2]}>
        <EmpTextField
          textFieldProps={{
            disabled: true,
            value: (stockChange?.changeAmount < 0 ? stockChange.changeAmount * -1 : stockChange.changeAmount) || '-',
            label: t('document.viewer.fields.products.fields.amount')
          }}
        />
      </FormItem>
    </React.Fragment>
  ));
};

const AttachmentList = ({ attachments, handleOpen, handleDownload }) => {
  const { t } = useTranslation('stockManager');

  if (!attachments?.length) {
    return (
      <Grid container columns={16} spacing={2} sx={{ pt: 2 }}>
        <FormItem xs={[0, 16, 0]} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography>
            {t('document.viewer.fields.documents.empty')}
          </Typography>
        </FormItem>
      </Grid>
    );
  }

  return attachments.map((attachment, index) => (
    <FormItem key={index} xs={[index % 2 === 0 ? 2 : 0, 6, index % 2 === 0 ? 0 : 2]}>
      <Chip
        sx={{width: '100%', justifyContent: 'space-between'}}
        variant="outlined"
        label={attachment.filePath}
        onDelete={() => handleDownload(attachment.id)}
        deleteIcon={
          <div>
            <IconButton size="small" onClick={() => handleOpen(attachment.id)}>
              <OpenInBrowserIcon fontSize="small" />
            </IconButton>
            <IconButton sx={{ mr: 1 }} size="small" onClick={() => handleDownload(attachment.id)}>
              <CloudDownloadIcon fontSize="small" />
            </IconButton>
          </div>
        }
      />
    </FormItem>
  ));
};
