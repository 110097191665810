import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { Editor, FormItem, EmpTextField } from '../../../shared/components';
import { useProductCategoryViewer } from './useProductCategoryViewer';
import { ProductCategoryActionsMenu } from './ProductCategoryActionsMenu';

export const ProductCategoryViewer = () => {
  const { t } = useTranslation('stockManager');
  const { productCategory } = useProductCategoryViewer();

  return (
    <Editor
      width="md"
      headerProps={{
        title: t('product-category.viewer.title'),
        backLinkTo: '/stock-manager/product-category',
        actions: productCategory && (
          <ProductCategoryActionsMenu
            productCategory={productCategory}
            disabledActions={['datasheet']}
          />
        )
      }}
    >
      <Grid container columns={16} spacing={2}>
        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: productCategory?.id || '',
              label: t('product-category.viewer.fields.id')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: productCategory?.name || '',
              label: t('product-category.viewer.fields.name')
            }}
          />
        </FormItem>
      </Grid>
    </Editor>
  );
};
