import { useTranslation } from 'react-i18next';
import { Editor, EmpTextField, Form, FormItem } from '../../../shared/components';
import { useProductUnitEditor } from './useProductUnitEditor';

export const ProductUnitEditor = () => {
  const { t } = useTranslation('stockManager');

  const {
    productUnitId,
    form,
    handleSubmit
  } = useProductUnitEditor();

  return (
    <Editor
      width="sm"
      headerProps={{
        title: t('product-unit.editor.title'),
        backLinkTo: '/stock-manager/product-unit'
      }}
      submitProps={{
        loading: form.formState.isSubmitting,
        onSubmit: handleSubmit,
        children: t(`product-unit.actions.${ productUnitId ? 'update' : 'create' }.label`)
      }}
    >
      <Form onSubmit={handleSubmit} columns={16} spacing={2}>
        <FormItem xs={[1, 14, 1]} sm={[2, 8, 6]}>
          <EmpTextField
            controllerProps={{
              name: 'name',
              control: form.control
            }}
            textFieldProps={{
              label: t('product-unit.editor.fields.name')
            }}
          />
        </FormItem>
      </Form>
    </Editor>
  );
};
