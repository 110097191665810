import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { Editor, FormItem, EmpTextField } from '../../../shared/components';
import { useCurrencyViewer } from './useCurrencyViewer';
import { CurrencyActionsMenu } from './CurrencyActionsMenu';

export const CurrencyViewer = () => {
  const { t } = useTranslation('stockManager');
  const { currency } = useCurrencyViewer();

  return (
    <Editor
      width="md"
      headerProps={{
        title: t('currency.viewer.title'),
        backLinkTo: '/stock-manager/currency',
        actions: currency && (
          <CurrencyActionsMenu
            currency={currency}
            disabledActions={['datasheet']}
          />
        )
      }}
    >
      <Grid container columns={16} spacing={2}>
        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: currency?.id || '',
              label: t('currency.viewer.fields.id')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: currency?.name || '',
              label: t('currency.viewer.fields.name')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: currency?.shortName || '',
              label: t('currency.viewer.fields.short-name')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              value: currency?.symbol || '',
              label: t('currency.viewer.fields.symbol')
            }}
          />
        </FormItem>
      </Grid>
    </Editor>
  );
};
