import { useEffect } from 'react';
import { useParams } from 'react-router';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useApiNotification } from '../../../shared/hooks/useApiNotification';
import { WarehouseService } from './WarehouseService';

const schema = yup.object().shape({
  name:
    yup.string().required().trim().max(64),

  address:
    yup.string().nullable().trim().max(64).emptyStringToNull()
});

export const useWarehouseEditor = () => {
  const { warehouseId } = useParams();

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const {
    showCreateError,
    showCreateSuccess,
    showUpdateError,
    showUpdateSuccess
  } = useApiNotification({
    successNavigationUrl: '/stock-manager/warehouses',
    translation: {
      namespace: 'stockManager',
      fragment: 'warehouse.actions'
    }
  });

  const { handleSubmit, reset } = form;

  useEffect(() => {
    if (warehouseId) {
      WarehouseService.getWarehouseById(warehouseId)
        .then(warehouse => {
          reset({ ...warehouse });
        });
    }
  }, [warehouseId, reset]);

  const handleSubmitInternal = async (data) => {
    if (warehouseId) {
      await updateWarehouse(data);
    }
    else {
      await createWarehouse(data);
    }
  };

  const createWarehouse = (warehouse) => {
    return WarehouseService.createWarehouse(warehouse)
      .then(() => {
        showCreateSuccess();
      })
      .catch(error => {
        showCreateError(error);
      });
  };

  const updateWarehouse = (warehouse) => {
    return WarehouseService.updateWarehouse(warehouse)
      .then(() => {
        showUpdateSuccess();
      })
      .catch(error => {
        showUpdateError(error);
      });
  };

  return {
    warehouseId,
    form,
    handleSubmit: handleSubmit(handleSubmitInternal)
  };
};
