import { ServiceBase } from '../../../shared';

const BASE_URL = '/stock-manager/manufacturers';

export const ManufacturerService = {
  async getManufacturersPaginated(parameters) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}?${ServiceBase.makeQueryString(parameters)}`))
      .json();
  },

  async getManufacturerById(manufacturerId) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/${manufacturerId}`))
      .json();
  },

  async createManufacturer(manufacturer) {
    return await (await ServiceBase
      .postAsync(BASE_URL, manufacturer))
      .json();
  },

  async updateManufacturer(manufacturer) {
    return await (await ServiceBase
      .putAsync(`${BASE_URL}/${manufacturer.id}`, manufacturer))
      .json();
  }
};
