import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Chip, Grid, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';
import { ActionsMenu, Editor, EmpLoadingButton, EmpModal, EmpPasswordField, EmpTextField, Form, FormItem, useNotification } from '../../shared/components';
import { useCurrentUser } from '../../shared/hooks/useCurrentUser';
import { UserService } from '../../modules/website-user/UserService';
import { PasswordStrength } from '../../modules/website-user/PasswordStrength';
import { validators } from '../../shared/validators/validators';
import { FormSection } from '../../shared/components/form/FormItem';

export const UserProfilePage = () => {
  const { t } = useTranslation('core');
  const { user, roles, token, hasAnyRole } = useCurrentUser();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Editor
      width="md"
      headerProps={{
        title: t('user-profile.title'),
        actions: <UserProfileActionsMenu onClick={() => setOpen(true)} />
      }}
    >
      <Form spacing={2} columns={16}>
        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              label: t('user-profile.fields.name'),
              value: `${(user?.profile?.family_name || '-')} ${(user?.profile?.given_name || '-')}`
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              label: t('user-profile.fields.username'),
              value: user?.profile?.preferred_username || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 8]}>
          <EmpTextField
            textFieldProps={{
              disabled: true,
              label: t('user-profile.fields.email'),
              value: token?.email || '-'
            }}
          />
        </FormItem>

        <FormSection title={t('user-profile.fields.roles')} xs={[1, 14, 1]} sm={[2, 12, 2]}>
          <Box sx={{ mt:2 }}>
            {
              (roles || []).map(role => (
                <Chip label={role} sx={{ mr: 1, mb: 1 }} />
              ))
            }
          </Box>
        </FormSection>

        {hasAnyRole('emp-admin') && (
          <FormSection title={t('user-profile.fields.scope')} xs={[1, 14, 1]} sm={[2, 12, 2]}>
            <Box sx={{ mt:2 }}>
              {
                (token?.scope || []).map(role => (
                  <Chip label={role} sx={{ mr: 1, mb: 1 }} />
                ))
              }
            </Box>
          </FormSection>
        )}
      </Form>
      <ChangePassword open={open} onClose={handleClose} />
    </Editor>
  );
};

const UserProfileActionsMenu = ({ onClick }) => {
  const { t } = useTranslation('core');

  const other = (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <PasswordIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>
        {t('user-profile.change-password.title')}
      </ListItemText>
    </MenuItem>
  );

  return <ActionsMenu actions={{}} other={other} />;
};

export const ChangePassword = ({ open, onClose }) => {
  const { t } = useTranslation(['core']);
  const { success } = useNotification();

  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    password: '',
    confirmPassword: ''
  });

  const [validation, setValidation] = useState({
    strength: null,
    confirmPassword: null
  });

  const onPasswordChange = ({ target }) => {
    const { value } = target;

    setState(prev => ({
      ...prev,
      password: value
    }));

    let confirmPassword = null;
    const strength = validators.password(value);

    if (value !== state.confirmPassword) {
      confirmPassword = t('validation:password.must-match');
    }

    setValidation(({
      strength,
      confirmPassword
    }));
  };

  const onConfirmPasswordChange = ({ target }) => {
    const { value } = target;

    setState(prev => ({
      ...prev,
      confirmPassword: value
    }));

    let confirmPassword = null;

    if (state.password !== value) {
      confirmPassword = t('validation:password.must-match');
    }

    setValidation(prev => ({
      ...prev,
      confirmPassword
    }));
  };

  const handleSave = () => {
    setLoading(true);

    UserService.changePassword(state)
      .then(() => {
        setTimeout(() => onClose(), 300);
        success(t('user-profile.change-password.actions.save.success'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <EmpModal
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={'xs'}
      titleIcon={<PasswordIcon />}
      localization={{
        title: t('user-profile.change-password.title')
      }}
      actions={
        <>
          <EmpLoadingButton
            loading={loading}
            onClick={handleSave}
            disabled={!state.password || !state.confirmPassword || !validation.strength.isValid || validation.confirmPassword}
          >
            {t('user-profile.change-password.actions.save.label')}
          </EmpLoadingButton>
          <Button color="error" onClick={onClose}>
            {t('user-profile.change-password.actions.close')}
          </Button>
        </>
      }
    >
      <Grid container columns={16}>
        <FormItem xs={[1, 14, 1]}>
          <PasswordStrength validationResult={validation.strength} />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[1, 10, 5]}>
          <EmpPasswordField
            textFieldProps={{
              label: t('user-profile.change-password.fields.new-password'),
              value: state.password,
              onChange: onPasswordChange
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[1, 10, 5]}>
          <EmpPasswordField
            textFieldProps={{
              label: t('user-profile.change-password.fields.confirm-password'),
              value: state.confirmPassword,
              onChange: onConfirmPasswordChange,
              error: (state.confirmPassword && validation.confirmPassword) ? true : false,
              helperText: state.confirmPassword ? validation.confirmPassword : ' '
            }}
          />
        </FormItem>
      </Grid>
    </EmpModal>
  );
};
