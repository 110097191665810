import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import coreEn from './assets/translations/core.en.json';
import coreHu from './assets/translations/core.hu.json';

import validationEn from './assets/translations/validation.en.json';
import validationHu from './assets/translations/validation.hu.json';

import evChargerEn from './modules/ev-charger/translation.en.json';
import evChargerHu from './modules/ev-charger/translation.hu.json';

import maintenanceEn from './modules/maintenance/translation.en.json';
import maintenanceHu from './modules/maintenance/translation.hu.json';

import websiteUserEn from './modules/website-user/translation.en.json';
import websiteUserHu from './modules/website-user/translation.hu.json';

import stockManagerEn from './modules/stock-manager/translation.en.json';
import stockManagerHu from './modules/stock-manager/translation.hu.json';

import mqttEn from './modules/mqtt/translation.en.json';
import mqttHu from './modules/mqtt/translation.hu.json';

const language = localStorage.getItem('language') || 'en';

const en = {
  core: coreEn,
  validation: validationEn,
  evCharger: evChargerEn,
  maintenance: maintenanceEn,
  websiteUser: websiteUserEn,
  stockManager: stockManagerEn,
  mqtt: mqttEn
};

const hu = {
  core: coreHu,
  validation: validationHu,
  evCharger: evChargerHu,
  maintenance: maintenanceHu,
  websiteUser: websiteUserHu,
  stockManager: stockManagerHu,
  mqtt: mqttHu
};

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: en,
      hu: hu
    },

    lng: language,
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false
    }
  });
