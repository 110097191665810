import { useWebsiteUserMenu } from './Menu';
import { WebsiteUserRoutes } from './Routes';

const WebsiteUserModule = {
  name: 'website-user',
  abbreviation: 'core',
  menu: useWebsiteUserMenu,
  baseRoute: '/website-user',
  routes: WebsiteUserRoutes
};

export default WebsiteUserModule;
