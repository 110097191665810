import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { PAYMENT_METHODS } from '../../saleReducer';
import { usePayment } from '../../hooks/usePayment';
import { useCurrentUser } from '../../../../../shared/hooks/useCurrentUser';

const styles = {
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

export const PaymentMethodRadioGroup = () => {
  const { t } = useTranslation('stockManager');
  const { hasAnyRole } = useCurrentUser();

  const {
    method,
    isGiveaway,
    changeMethod
  } = usePayment();

  return (
    <Box sx={styles.center}>
      <Box>
        <FormControl component="fieldset">
          <RadioGroup
            row
            value={method}
            onChange={({ target }) => changeMethod(target.value)}
            sx={{ justifyContent: 'center' }}
          >
            <FormControlLabel
              label={t('sale.payment-panel.methods.cash')}
              disabled={isGiveaway}
              value={PAYMENT_METHODS.CASH}
              control={<Radio />}
            />
            <FormControlLabel
              label={t('sale.payment-panel.methods.card')}
              disabled={isGiveaway}
              value={PAYMENT_METHODS.CARD}
              control={<Radio />}
            />
            <FormControlLabel
              label={t('sale.payment-panel.methods.transfer')}
              disabled={isGiveaway}
              value={PAYMENT_METHODS.TRANSFER}
              control={<Radio />}
            />
            <FormControlLabel
              label={t('sale.payment-panel.methods.mixed')}
              disabled={isGiveaway}
              value={PAYMENT_METHODS.MIXED}
              control={<Radio />}
            />
            {hasAnyRole('stm-admin') && (
              <FormControlLabel
                label={t('sale.payment-panel.methods.giveaway')}
                value={PAYMENT_METHODS.GIVEAWAY}
                disabled={isGiveaway}
                control={<Radio />}
              />
            )}
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};
