import { useEffect } from 'react';
import { useEmpAuth } from '../AuthProvider';
import { LoadingScreen } from './LoadingScreen';

export const SignInCallback = () => {
  const { signinCallback } = useEmpAuth();

  useEffect(() => {
    signinCallback();
  }, [signinCallback]);

  return <LoadingScreen />;
};
