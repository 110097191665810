import { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DateTime } from 'luxon';
import { EmpDropdown, EmpTextField, FullPageView } from '../../../shared/components';
import { useApiNotification } from '../../../shared';
import { useQueryParam } from '../../../shared/hooks/useQueryParam';
import { useCurrentUser } from '../../../shared/hooks/useCurrentUser';
import { PurchasesPanel } from './PurchasesPanel';
import { SummaryPanel } from './SummaryPanel';
import { DailyIncomeService } from './DailyIncomeService';
import { formatters } from '../utils/Formatters';

export const DailyIncome = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { showApiError } = useApiNotification();

  const {
    value: shopId,
    setValue: setShopId
  } = useQueryParam({ name: 'shopId' });

  const {
    value: interval,
    setValue: setInterval
  } = useQueryParam({ name: 'interval' });

  const {
    dailyIncome,
    dailyIncomeLoading,
    shops
  } = state;

  useEffect(() => {
    dispatch({ type: 'SHOPS_LOADING' });

    DailyIncomeService.getShops()
      .then(shops => {
        dispatch({ type: 'SHOPS_SUCCESS', payload: shops });
      })
      .catch(error => {
        showApiError(null, error);
        dispatch({ type: 'SHOPS_ERROR' });
      });
  }, [showApiError]);

  useEffect(() => {
    if (!interval) {
      setInterval('daily');
    }

    if (shops?.length && !shopId) {
      setShopId(shops[0].id);
    }
  }, [interval, shops, shopId, setShopId]);

  useEffect(() => {
    if (shopId && interval) {
      dispatch({ type: 'DAILY_INCOME_LOADING' });

      DailyIncomeService.getDailyIncome(shopId, interval)
        .then(dailyIncome => {
          dispatch({ type: 'DAILY_INCOME_SUCCESS', payload: dailyIncome });
        })
        .catch(error => {
          showApiError(null, error);
          dispatch({ type: 'DAILY_INCOME_ERROR' });
        });
    }
  }, [shopId, interval, showApiError]);

  return (
    <FullPageView>
      <Grid container spacing={2} sx={{ paddingTop: { xs: 2, md: 0 } }}>
        <Grid item xs={10} md={6} lg={3} xsOffset={1} mdOffset={5} lgOffset={6}>
          <Box sx={{display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
            <Box sx={{flexBasis: '55%'}}>
              <ShopSelector state={state} shopId={shopId} setShopId={setShopId} />
            </Box>
            <Box sx={{flexBasis: '45%'}}>
              <IntervalSelector interval={interval} setInterval={setInterval} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={5} lg={3} mdOffset={1} lgOffset={2}>
          <SummaryPanel
            loading={dailyIncomeLoading}
            dailyIncome={dailyIncome}
            interval={interval}
          />
        </Grid>

        <Grid item xs={12} md={5} lg={4} alignSelf='center'>
          <PurchasesPanel
            loading={dailyIncomeLoading}
            purchases={dailyIncome?.purchases || []}
            interval={interval}
          />
        </Grid>

      </Grid>
    </FullPageView>
  );
};

const ShopSelector = ({ state, shopId, setShopId }) => {
  const { t } = useTranslation('stockManager');
  const { hasAnyRole } = useCurrentUser();

  const {
    dailyIncome,
    shops,
    shopsLoading
  } = state;

  return (
    <Box display="flex" justifyContent="flex-end">
      {hasAnyRole('stm-admin') ? (
        <EmpDropdown
          dropdownProps={{
            sx: { width: '100%' },
            label: t('daily-income.fields.shops'),
            loading: shopsLoading,
            options: shops.map(shop => ({
              value: shop.id,
              label: shop.name
            })),
            value: shopId,
            onChange: ({ target }) => setShopId(target.value)
          }}
        />
      ) : (
        <EmpTextField
          textFieldProps={{
            disabled: true,
            sx: { width: '100%' },
            label: t('daily-income.fields.shop'),
            value: dailyIncome?.shop?.name
          }}
        />
      )}
    </Box>
  );
};

const IntervalSelector = ({ interval, setInterval }) => {
  const { t } = useTranslation('stockManager');
  const { hasAnyRole } = useCurrentUser();

  return (
    <Box display="flex" justifyContent="flex-end">
      {hasAnyRole('stm-admin') ? (
        <EmpDropdown
          dropdownProps={{
            sx: { width: { xs: '100%', md: '75%' } },
            label: t('daily-income.fields.period'),
            options: [
              { value: 'daily', label: t('daily-income.periods.daily') },
              { value: 'weekly', label: t('daily-income.periods.weekly') },
              { value: 'monthly', label: t('daily-income.periods.monthly') }
            ],
            value: interval || 'daily',
            onChange: ({ target }) => setInterval(target.value)
          }}
        />
      ) : (
        <EmpTextField
          textFieldProps={{
            disabled: true,
            sx: { width: { xs: '100%', md: '75%' } },
            label: t('daily-income.fields.period'),
            value: t(`daily-income.periods.${interval || 'daily'}`)
          }}
        />
      )}
    </Box>
  );
};

const initialState = {
  dailyIncome: null,
  dailyIncomeLoading: false,
  shops: [],
  shopsLoading: false
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'DAILY_INCOME_LOADING':
      return {
        ...state,
        dailyIncome: null,
        dailyIncomeLoading: true
      };

    case 'DAILY_INCOME_SUCCESS':
      return {
        ...state,
        dailyIncome: payload,
        dailyIncomeLoading: false
      };

    case 'DAILY_INCOME_ERROR':
      return {
        ...state,
        dailyIncome: null,
        dailyIncomeLoading: false
      };

    case 'SHOPS_LOADING':
      return {
        ...state,
        shopsLoading: true
      };

    case 'SHOPS_SUCCESS':
      return {
        ...state,
        shops: payload,
        shopsLoading: false
      };

    case 'SHOPS_ERROR':
      return {
        ...state,
        shops: [],
        shopsLoading: false
      };

    default:
      return state;
  }
};

export const intervalRenderer = (interval) => {
  const today = new Date();

  if (interval === 'daily') {
    return formatters.date(today, DateTime.DATE_SHORT);
  }
  else if (interval === 'weekly') {
    let dayOfWeek = today.getDay();

    if (dayOfWeek === 0) {
      dayOfWeek = 7;
    }

    const weekStartDay = new Date();
    weekStartDay.setDate(today.getDate() - dayOfWeek + 1);

    const weekEndDay = new Date();
    weekEndDay.setDate(today.getDate() + (7 - dayOfWeek));

    return `${formatters.date(weekStartDay, DateTime.DATE_SHORT)} - ${formatters.date(weekEndDay, DateTime.DATE_SHORT)}`;
  }
  else if (interval === 'monthly') {
    return formatters.yearAndMonth(today);
  }
};
