import { ServiceBase } from '../../../shared';

const BASE_URL = '/stock-manager/product-categories';

export const ProductCategoryService = {
  async getProductCategoriesPaginated(parameters) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}?${ServiceBase.makeQueryString(parameters)}`))
      .json();
  },

  async getProductCategoryById(productCategoryId) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/${productCategoryId}`))
      .json();
  },

  async createProductCategory(productCategory) {
    return await (await ServiceBase
      .postAsync(BASE_URL, productCategory))
      .json();
  },

  async updateProductCategory(productCategory) {
    return await (await ServiceBase
      .putAsync(`${BASE_URL}/${productCategory.id}`, productCategory))
      .json();
  }
};
