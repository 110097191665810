import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useCurrentTenant } from './useCurrentTenant';

const defaultTheme = {
  palette: {
    // Dark blue
    primary: {
      main: '#3f51b5',
      light: '#9fa8da',
      dark: '#283593'
    },
    // Medium orange
    secondary: {
      main: '#ffbd45',
      light: '#ffe34b',
      dark: '#f38034'
    },
    sale: {
      textHover: '#ffffff',
      disabled: '#e8eaf6'
    }
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
        color: 'textPrimary'
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        fullWidth: true
      }
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      }
    }
  }
};

export const TenantThemeProvider = ({ children }) => {
  const { tenant } = useCurrentTenant();

  const defaultThemeCopy = JSON.parse(JSON.stringify(defaultTheme));
  const defaultPalette = defaultThemeCopy.palette;

  if (tenant?.additionalData?.theme?.palette) {
    const tenantThemePalette = tenant.additionalData.theme.palette;

    defaultThemeCopy.palette = {
      ...defaultPalette,
      ...tenantThemePalette
    };
  }

  return (
    <ThemeProvider theme={createTheme(defaultThemeCopy)}>
      {children}
    </ThemeProvider>
  );
};
