import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { WarehouseService } from './WarehouseService';

export const useWarehouseViewer = () => {
  const { warehouseId } = useParams();
  const [warehouse, setWarehouse] = useState(null);

  useEffect(() => {
    WarehouseService.getWarehouseById(warehouseId)
      .then(warehouse => {
        setWarehouse(warehouse);
      });
  }, [warehouseId]);

  return {
    warehouse
  };
};
