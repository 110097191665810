import { Box } from '@mui/material';
import { EmpDropdown } from '../../../shared/components';
import { useEmpAuth } from '../AuthProvider';

export const TenantSelector = () => {
  const { tenants, tenant, setTenant } = useEmpAuth();

  const handleTenantChanged = ({ target }) => {
    const tenant = tenants.find(x => x.externalId === target.value);
    setTenant(tenant);
  };

  return (
    <Box sx={{ mr: 2, width: '350px' }}>
      <EmpDropdown
        dropdownProps={{
          options: tenants.map(tenant => ({
            value: tenant.externalId,
            label: tenant.name
          })),
          value: tenant?.externalId || '',
          onChange: handleTenantChanged
        }}
      />
    </Box>
  );
};
