import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomerService } from './CustomerService';

export const useCustomerViewer = () => {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    if (customerId) {
      CustomerService.getCustomerById(customerId)
        .then(customer => {
          setCustomer(customer);
        });
    }
  }, [customerId]);

  return {
    customer
  };
};
