import { Box, TableCell, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useEmpTableUtils } from '../EmpTableUtils';

export const EmpTableHeaderRow = ({ columns, sorting, hasActions }) => {
  const {
    state: {
      column: sortColumn,
      direction
    },
    onSortingChange: setColumn
  } = (sorting || {});

  const { getHeader, getWidth } = useEmpTableUtils();

  return (
    <TableRow>
      {columns.map((column, index) => (
        <TableCell key={index} sx={{ width: getWidth(column) }} align={column.align || 'left'}>
          {(column?.sortable !== false) ? (
            <TableSortLabel
              active={sortColumn === column.field}
              direction={sortColumn === column.field ? direction : 'asc'}
              onClick={() => setColumn(column.field)}
            >
              {getHeader(column)}
              {sortColumn === column.field ? (
                <Box component="span" sx={visuallyHidden}>
                  {direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          ) : (
            getHeader(column)
          )}
        </TableCell>
      ))}
      {(hasActions) && (
        <TableCell />
      )}
    </TableRow>
  );
};
