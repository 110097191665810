import { User } from 'oidc-client-ts';
import { IDENTITY_CONFIG } from './AuthConstants';

const getUser = () => {
  const user = localStorage.getItem(
    `oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`
  );

  if (!user) {
    return null;
  }

  return User.fromStorageString(user);
};

const getTenant = () => {
  const tenant = localStorage.getItem('tenant');

  if (!tenant) {
    return null;
  }

  return JSON.parse(tenant);
};

export const AuthHelper = {
  getUser,
  getTenant
};
