import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { Button, Grid, InputAdornment } from '@mui/material';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import { EmpLoadingButton, EmpModal, EmpNumberField, EmpTextField } from '../../../shared/components';
import { ChargingSessionService } from '../ChargingSessionService';
import { useApiNotification } from '../../../shared';
import { CoreFormatters } from '../../../core';

export const PriceEditorModal = () => {
  const { t } = useTranslation('evCharger');
  const { showApiError } = useApiNotification();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [price, setPrice] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);

  const handleSave = () => {
    setLoading(true);

    ChargingSessionService.setPrice(price)
      .finally(() => {
        setLoading(false);
        setOpen(false);
      });
  };

  useEffect(() => {
    if (open) {
      ChargingSessionService.getLatestPriceInfo()
        .then((priceInfo) => {
          if (priceInfo) {
            setPrice(priceInfo.value);
            setUpdatedAt(new Date(priceInfo.updatedAt));
          }
        })
        .catch((exception) => {
          showApiError(null, exception);
        });
    }
  }, [open, showApiError]);

  return (
    <>
      <Button
        variant="text"
        sx={{ borderRadius: '10px', ml: 2 }}
        startIcon={<PriceChangeIcon />}
        onClick={() => setOpen(true)}
      >
        {t('guest-charging.price-info-modal.title-short')}
      </Button>
      <EmpModal
        open={open}
        fullWidth
        maxWidth="xs"
        titleIcon={<PriceChangeIcon />}
        localization={{
          title: t('guest-charging.price-info-modal.title')
        }}
        onClose={() => setOpen(false)}
        actions={
          <>
            <EmpLoadingButton loading={loading} onClick={handleSave}>
              {t('guest-charging.price-info-modal.actions.save')}
            </EmpLoadingButton>
            <Button color="error" onClick={() => setOpen(false)}>
              {t('guest-charging.price-info-modal.actions.close')}
            </Button>
          </>
        }
      >
        <Grid container spacing={4}>
          <Grid item xs={5}>
            <EmpNumberField
              numberFieldProps={{
                label: t('guest-charging.price-info-modal.fields.cost'),
                value: (price === undefined || price === null) ? '' : price,
                onChange: ({ target }) => {
                  if (target?.value || target.value === '0') {
                    setPrice(parseInt(target.value))
                  }
                },
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      {`${t('currency')}/kWh`}
                    </InputAdornment>
                  )
                }
              }}
            />
          </Grid>
          <Grid item xs={7}>
            <EmpTextField
              textFieldProps={{
                label: t('guest-charging.price-info-modal.fields.last-update'),
                value: updatedAt ? CoreFormatters.date(updatedAt, DateTime.DATETIME_SHORT_WITH_SECONDS) : '-',
                disabled: true
              }}
            />
          </Grid>
        </Grid>
      </EmpModal>
    </>
  );
};
