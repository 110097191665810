import { ServiceBase } from '../../../shared';

const BASE_URL = '/stock-manager/products';

export const ProductService = {
  async getProductsPaginated(parameters) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}?${ServiceBase.makeQueryString(parameters)}`))
      .json();
  },

  async getProductById(productId) {
    return await (await ServiceBase
      .getAsync(`${BASE_URL}/${productId}`))
      .json();
  },

  async createProduct(product) {
    return await (await ServiceBase
      .postAsync(BASE_URL, product))
      .json();
  },

  async updateProduct(product) {
    return await (await ServiceBase
      .putAsync(`${BASE_URL}/${product.id}`, product))
      .json();
  }
};
