import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { Editor, FormItem, EmpTextField } from '../../../shared/components';
import { EanField, HistoryForm } from '../shared';
import { ProductActionsMenu } from './ProductActionsMenu';
import { ProductPriceViewer } from './ProductPriceViewer';
import { useProductViewer } from './useProductViewer';

export const ProductViewer = () => {
  const { t } = useTranslation('stockManager');
  const { product } = useProductViewer();

  return (
    <Editor
      width="lg"
      headerProps={{
        title: t('product.viewer.title'),
        backLinkTo: '/stock-manager/product',
        actions: product && <ProductActionsMenu product={product} disabledActions={['datasheet']} />
      }}
    >
      <Grid container spacing={2} columns={16}>
        <FormItem xs={[1, 14, 1]} sm={[2, 6, 8]}>
          <EmpTextField
            textFieldProps={{
              label: t('product.viewer.fields.id'),
              disabled: true,
              value: product?.id || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              label: t('product.viewer.fields.name'),
              disabled: true,
              value: product?.name || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              label: t('product.viewer.fields.sku'),
              disabled: true,
              value: product?.sku || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EanField
            textFieldProps={{
              label: t('product.viewer.fields.ean'),
            }}
            maskProps={{
              disabled: true,
              value: product?.ean || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              label: t('product.viewer.fields.manufacturer'),
              disabled: true,
              value: product?.manufacturer?.name || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              label: t('product.viewer.fields.product-category'),
              disabled: true,
              value: product?.productCategory?.name || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              label: t('product.viewer.fields.product-unit'),
              disabled: true,
              value: product?.productUnit?.name || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            textFieldProps={{
              label: t('product.viewer.fields.quantity-notification-limit'),
              disabled: true,
              value: product?.quantityNotificationLimit === 0 ? '0' : product?.quantityNotificationLimit || '-'
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            textFieldProps={{
              label: t('product.viewer.fields.remarks'),
              disabled: true,
              value: product?.remarks || '-'
            }}
          />
        </FormItem>

        <PricesForm prices={product?.prices} />

        <FormItem xs={[1, 14, 1]} sm={[2, 12, 2]}>
          <HistoryForm historyObject={product} />
        </FormItem>
      </Grid>
    </Editor>
  );
};

const PricesForm = ({ prices }) => {
  const { t } = useTranslation('stockManager');

  return (
    <>
      <FormItem xs={[1, 15, 0]} sm={[2, 14, 0]}>
        <Typography variant="h5">
          {t('product.viewer.sections.pricing')}
        </Typography>
      </FormItem>
      <FormItem xs={[1, 14, 1]} sm={[2, 12, 2]}>
        <ProductPriceViewer prices={prices} />
      </FormItem>
    </>
  );
};
