import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { EmpDatePicker, EmpDropdown, EmpNumberField, EmpTextField, FormItem } from '../..';
import { OperatorDropdown } from './OperatorDropdown';

const FilterItemInternal = (props) => {
  const {
    type,
    nullable,
    listOptions = [],
    listLoading = false,
    name,
    label,
    filter,
    onOperatorChange,
    onValueChange,
    operatorContainerProps,
    valueContainerProps
  } = props;

  const handleDateChange = (value) => {
    onValueChange({ target: { name, value } });
  };

  let control = null;

  if (type === 'list') {
    control = (
      <EmpDropdown
        dropdownProps={{
          label: label,
          name: name,
          loading: listLoading,
          options: listOptions,
          value: filter?.value || '',
          onChange: onValueChange
        }}
      />
    );
  }
  else if (filter?.operator === 'range') {
    control = (
      <RangeField
        name={name}
        type={type}
        filter={filter}
        onValueChange={onValueChange}
      />
    );
  }
  else if (type === 'date') {
    control = (
      <EmpDatePicker
        datePickerProps={{
          label: label,
          name: name,
          value: filter?.value || '',
          onChange: handleDateChange
        }}
      />
    );
  }
  else if (type === 'number') {
    control = (
      <EmpNumberField
        numberFieldProps={{
          label: label,
          name: name,
          value: filter?.value || '',
          onChange: onValueChange
        }}
      />
    );
  }
  else {
    control = (
      <EmpTextField
        textFieldProps={{
          label: label,
          name: name,
          value: filter?.value || '',
          onChange: onValueChange
        }}
      />
    );
  }

  return (
    <>
      {operatorContainerProps && (
        <FormItem {...operatorContainerProps}>
          <OperatorDropdown
            type={type}
            name={name}
            nullable={nullable}
            value={filter?.operator || ''}
            onChange={onOperatorChange}
          />
        </FormItem>
      )}
      <FormItem {...valueContainerProps}>
        {control}
      </FormItem>
    </>
  );
};

// TODO - nincs használatban mert az aszinkron API hívás utáni
// állapot frissítés így nem érvényesül
export const FilterItem = React.memo(FilterItemInternal, (prev, next) => {
  return false;

  // if (prev.filter?.operator !== next.filter?.operator) {
  //   return false;
  // }

  // if (prev.filter?.value !== next.filter?.value) {
  //   return false;
  // }

  // return true;
});

const RangeField = ({ name, type, filter, onValueChange }) => {
  const { t } = useTranslation('core');

  const handleChange = (value, type) => {
    onValueChange(getRangeFilter(value, type));
  }

  const parseRangeFilter = (value, part) => {
    if (!value) {
      return '';
    }

    const parts = value.split('<>');
    return part === 'min' ? (parts[0] || '') : (parts[1] || '');
  };

  const getRangeFilter = (newValue, part) => {
    let min = null;
    let max = null;

    if (filter?.value) {
      const split = filter?.value.split('<>');
      min = split[0];
      max = split[1];
    }

    let value = null;

    if (part === 'min') {
      value = `${newValue}<>${max || ''}`;
    }
    else {
      value = `${min || ''}<>${newValue}`;
    }

    return { target: { name, value } };
  };

  let controlMin = null;
  let controlMax = null;

  if (type === 'number') {
    controlMin = (
      <EmpNumberField
        numberFieldProps={{
          label: t('filter.operators.range.min'),
          name: name,
          value: parseRangeFilter(filter?.value, 'min'),
          onChange: ({ target }) => handleChange(target.value, 'min')
        }}
      />
    );
    controlMax = (
      <EmpNumberField
        numberFieldProps={{
          label: t('filter.operators.range.max'),
          name: name,
          value: parseRangeFilter(filter?.value, 'max'),
          onChange: ({ target }) => handleChange(target.value, 'max')
        }}
      />
    );
  }
  else if (type === 'date') {
    controlMin = (
      <EmpDatePicker
        datePickerProps={{
          label: t('filter.operators.range.min'),
          name: name,
          value: parseRangeFilter(filter?.value, 'min'),
          onChange: (event) => handleChange(event, 'min')
        }}
      />
    );
    controlMax = (
      <EmpDatePicker
        datePickerProps={{
          label: t('filter.operators.range.max'),
          name: name,
          value: parseRangeFilter(filter?.value, 'max'),
          onChange: (event) => handleChange(event, 'max')
        }}
      />
    );
  }
  else {
    controlMin = (
      <EmpTextField
        textFieldProps={{
          label: t('filter.operators.range.min'),
          name: name,
          value: parseRangeFilter(filter?.value, 'min'),
          onChange: ({ target }) => handleChange(target.value, 'min')
        }}
      />
    );
    controlMax = (
      <EmpTextField
        textFieldProps={{
          label: t('filter.operators.range.max'),
          name: name,
          value: parseRangeFilter(filter?.value, 'max'),
          onChange: ({ target }) => handleChange(target.value, 'max')
        }}
      />
    );
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {controlMin}
      <Box sx={{ px: 0.5 }} />
      {controlMax}
    </Box>
  );
};
