import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { useTableV2 } from '../../shared/components/table/hooks/useTableV2';
import { UserActionsMenu } from './UserActionsMenu';
import { UserService } from './UserService';

const columnsFactory = (t) => [{
  header: t('list.columns.id'),
  field: 'id',
  sortable: false,
  width: '15%'
}, {
  header: t('list.columns.name'),
  field: 'name',
  sortable: false,
  width: '25%',
  render: ({ name }) => name || '-'
}, {
  header: t('list.columns.username'),
  field: 'username',
  sortable: false,
  width: '25%',
  render: ({ username }) => username || '-'
}, {
  header: t('list.columns.email'),
  field: 'email',
  sortable: false,
  width: '30%',
  render: ({ email }) => email || '-'
}, {
  width: '5%',
  render: (row, { refresh }) => (
    <UserActionsMenu
      user={row}
      onDelete={refresh}
    />
  )
}];

export const useUserList = () => {
  const { t } = useTranslation('websiteUser');
  const columns = useMemo(() => columnsFactory(t), [t]);

  const tableProps = useTableV2({
    columns,
    title: t('list.title'),
    icon: <SupervisedUserCircleIcon />,
    dataSource: UserService.getUsers,
    components: {
      toolbar: {
        createButtonProps: {
          to: '/website-user/editor',
          children: t('actions.create.label')
        }
      }
    }
  });

  return {
    tableProps
  };
};
