import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Editor, EmpDropdown, EmpNumberField, EmpTextField, Form, FormItem } from '../../../shared/components';
import { EanField } from '../shared';
import { ProductPriceEditor } from './ProductPriceEditor';
import { useProductEditor } from './useProductEditor';

export const ProductEditor = () => {
  const { t } = useTranslation('stockManager');

  const {
    productId,
    form,
    manufacturerDropdownProps,
    productCategoryDropdownProps,
    productUnitDropdownProps,
    shopDropdownProps,
    resetEditor,
    handleSubmit
  } = useProductEditor();

  return (
    <Editor
      width="lg"
      headerProps={{
        title: t('product.editor.title'),
        backLinkTo: '/stock-manager/product'
      }}
      resetProps={{
        onReset: resetEditor
      }}
      submitProps={{
        loading: form.formState.isSubmitting,
        onSubmit: handleSubmit,
        children: t(`product.actions.${ productId ? 'update' : 'create' }.label`)
      }}
    >
      <Form onSubmit={handleSubmit} spacing={2} columns={16}>
        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpTextField
            controllerProps={{
              name: 'name',
              control: form.control
            }}
            textFieldProps={{
              label: t('product.editor.fields.name')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            controllerProps={{
              name: 'sku',
              control: form.control
            }}
            textFieldProps={{
              label: t('product.editor.fields.sku')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EanField
            controllerProps={{
              name: 'ean',
              control: form.control
            }}
            textFieldProps={{
              label: t('product.editor.fields.ean')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpDropdown
            controllerProps={{
              name: 'manufacturerId',
              control: form.control
            }}
            dropdownProps={{
              label: t('product.editor.fields.manufacturer'),
              ...manufacturerDropdownProps
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpDropdown
            controllerProps={{
              name: 'productCategoryId',
              control: form.control
            }}
            dropdownProps={{
              label: t('product.editor.fields.product-category'),
              ...productCategoryDropdownProps
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpDropdown
            controllerProps={{
              name: 'productUnitId',
              control: form.control
            }}
            dropdownProps={{
              label: t('product.editor.fields.product-unit'),
              ...productUnitDropdownProps
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[2, 6, 0]}>
          <EmpNumberField
            controllerProps={{
              name: 'quantityNotificationLimit',
              control: form.control
            }}
            numberFieldProps={{
              label: t('product.editor.fields.quantity-notification-limit')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 14, 1]} sm={[0, 6, 2]}>
          <EmpTextField
            controllerProps={{
              name: 'remarks',
              control: form.control
            }}
            textFieldProps={{
              label: t('product.editor.fields.remarks')
            }}
          />
        </FormItem>

        <FormItem xs={[1, 15, 0]} sm={[2, 14, 0]}>
          <Typography variant="h5" sx={{ mb: 0 }}>
            {t('product.viewer.sections.pricing')}
          </Typography>
        </FormItem>
        <FormItem xs={[1, 14, 1]} sm={[2, 12, 2]}>
          <ProductPriceEditor
            form={form}
            shopDropdownProps={shopDropdownProps}
          />
        </FormItem>
      </Form>
    </Editor>
  );
};
