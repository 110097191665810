import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

export const NoLayout = ({ children }) => {
  return (
    <Box>
      <Outlet />
    </Box>
  );
};
